import React, { Fragment, useState } from 'react';
import { FaUser, FaBirthdayCake } from 'react-icons/fa';
import { RiBillFill } from 'react-icons/ri';
import { MdDeliveryDining } from 'react-icons/md';
import { useSelector } from 'react-redux';

const ProgressBar = ({ tab, updateTab }) => {
  const { customerDeliveryInfo, cartItems, customerInfo } = useSelector(
    (state) => state.callcenter
  );
  return (
    <Fragment>
      <div className="container p-4 sm:mx-auto sm:px-8">
        <div className="sm:py-4">
          <div className="flex items-center">
            <div
              className={`flex items-center ${
                tab === 1 ? 'text-white' : 'text-gray-500'
              }  relative`}
            >
              <div
                className={`rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2 hover:cursor-pointer ${
                  tab === 1 && 'bg-gray-500 hover:cursor-default'
                } border-gray-500`}
              >
                <FaBirthdayCake
                  size={20}
                  className="mx-auto"
                  onClick={() => {
                    updateTab(1);
                  }}
                />
              </div>
              {/* <div className="absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium uppercase text-gray-500">
                Order
              </div> */}
            </div>
            <div
              className={`flex-auto border-t-2 transition duration-500 ease-in-out ${
                (tab === 2 || tab === 3 || tab === 4) && 'border-gray-500'
              }`}
            ></div>
            <div
              className={`flex items-center ${tab === 2 ? 'text-white' : 'text-gray-500'} relative`}
            >
              <div
                className={`rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2 hover:cursor-pointer ${
                  tab === 2 && 'bg-gray-500 hover:cursor-default'
                } ${(tab === 2 || tab === 3 || tab === 4) && 'border-gray-500'}`}
              >
                <FaUser
                  size={20}
                  className="mx-auto"
                  onClick={() => (cartItems.length > 0 ? updateTab(2) : null)}
                />
              </div>
              {/* <div className="absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium uppercase text-gray-500">
                Customer
              </div> */}
            </div>
            <div
              className={`flex-auto border-t-2 transition duration-500 ease-in-out ${
                (tab === 3 || tab === 4) && 'border-gray-500'
              }`}
            ></div>
            <div
              className={`flex items-center ${tab === 3 ? 'text-white' : 'text-gray-500'} relative`}
            >
              <div
                className={`rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2 hover:cursor-pointer ${
                  tab === 3 && 'bg-gray-500 hover:cursor-default'
                } ${(tab === 3 || tab === 4) && 'border-gray-500'}`}
              >
                <MdDeliveryDining
                  size={24}
                  className="mx-auto"
                  onClick={() =>
                    cartItems.length > 0 && Object.keys(customerInfo).length > 0
                      ? updateTab(3)
                      : null
                  }
                />
              </div>
              {/* <div className="absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium uppercase text-gray-500">
                Delivery
              </div> */}
            </div>
            <div
              className={`flex-auto border-t-2 transition duration-500 ease-in-out ${
                tab === 4 && 'border-gray-500 hover:cursor-default'
              }`}
            ></div>
            <div
              className={`flex items-center ${tab === 4 ? 'text-white' : 'text-gray-500'} relative`}
            >
              <div
                className={`rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2 hover:cursor-pointer ${
                  tab === 4 && 'bg-gray-500 border-gray-500 hover:cursor-default'
                } `}
              >
                <RiBillFill
                  size={20}
                  className="mx-auto"
                  onClick={() =>
                    cartItems.length > 0 &&
                    Object.keys(customerInfo).length > 0 &&
                    Object.keys(customerDeliveryInfo.deliveryAddress).length > 0
                      ? updateTab(4)
                      : null
                  }
                />
              </div>
              {/* <div className="absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium uppercase text-gray-500">
                Summary
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ProgressBar;
