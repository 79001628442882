import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { onUserLogin, onErrorClear } from '../../actions/login.action';
import Loader from '../Loader/Loader';
import { IconContext } from 'react-icons';
import ResponseShow from '../ResponseShow/ResponseShow';
import { emailPattern } from '../../helpers/Patterns';
import { BsXCircleFill } from 'react-icons/bs';
import { MdClose } from 'react-icons/md';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useToasts } from 'react-toast-notifications';
import * as helpers from '../../helpers/config';
import CryptoJS from 'crypto-js';
import dic from 'i18next';
import { reactAppSecretScan } from '../../constants';

function LoginModal(props) {
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const { loading, statuscode, error } = useSelector((state) => state.login);
  const loggeddetails = helpers.LoggedUserDetails();
  const [getPasswordStatus, setPasswordStatus] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const onSubmits = (data) => {
    let userEmail = data.email.toLowerCase();
    let encryptPass = CryptoJS.AES.encrypt(data.password, reactAppSecretScan);
    let encryptEmail = CryptoJS.AES.encrypt(userEmail, reactAppSecretScan);
    const logindata = {
      emailId: encryptEmail.toString(),
      password: encryptPass.toString(),
    };
    dispatch(onUserLogin(logindata));
    reset();
  };
  useEffect(() => {
    if (statuscode === 200) {
      addToast('Welcome, ' + loggeddetails.firstName + ' ' + loggeddetails.lastName, {
        appearance: 'success',
      });
      props.toggleModal();
    }
  }, [statuscode]);
  const updateEyeStatus = (status) => {
    setPasswordStatus(status);
    var x = document.getElementById('password');
    if (x.type === 'password') {
      x.type = 'text';
    } else {
      x.type = 'password';
    }
  };

  const onclose = () => {
    reset();
    dispatch(onErrorClear());
    props.toggleModal();
  };
  const onchangetab = (item) => {
    props.TabStatusAction(item);
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="w-full">
            <div className="relative flex flex-col min-w-0 break-words w-full shadow-lg rounded-lg bg-slate-200 border-0">
              <div className="close-icon">
                {/* <BsXCircleFill
                  onClick={onclose}
                  className="text-xl text-slate-400 cursor-pointer absolute right-2 top-2"
                /> */}
                <button
                  type="button"
                  className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-300 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                  onClick={onclose}
                >
                  <MdClose className="w-5 h-5" />
                </button>
              </div>
              <div className="flex-auto px-4 lg:px-10 pb-5 pt-0">
                <div className="text-slate-600 pt-5  pb-1   lg:pt-10 lg:pb-3 text-center font-semibold">
                  <p className="text-lg">{dic.t('Margaux-Sign In')}</p>
                </div>
                <form className="pt-1 px-1" onSubmit={handleSubmit(onSubmits)}>
                  <ResponseShow success={statuscode} error={error} />
                  <div className="relative w-full mb-3">
                    {/* <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
                      Email
                    </label> */}
                    <input
                      type="email"
                      maxLength={50}
                      className="border-0 px-3 py-3 placeholder:text-slate-600 placeholder:tracking-widest text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder={dic.t('Margaux-Email')}
                      {...register('email', {
                        required: 'Email is Required',
                        pattern: {
                          value: emailPattern,
                          message: 'Please enter a valid email',
                        },
                      })}
                      autoComplete="off"
                    />
                    {errors.email && errors.email.message && (
                      <span className="text-red-400 text-sm">{errors.email.message}</span>
                    )}
                  </div>
                  <div className="relative w-full mb-3">
                    {/* <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
                      Password
                    </label> */}
                    <input
                      type="password"
                      id="password"
                      maxLength={20}
                      className="border-0 px-3 py-3 placeholder:text-slate-600 placeholder:tracking-widest text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder={dic.t('Margaux-Password')}
                      {...register('password', {
                        required: 'Password is Required',
                      })}
                      autoComplete="off"
                    />
                    <span className="absolute cursor-pointer top-[12px] right-[9px]">
                      {getPasswordStatus === false ? (
                        <IconContext.Provider
                          value={{ className: 'text-slate-600 cursor-pointer' }}
                        >
                          <FaEye size={19} onClick={() => updateEyeStatus(true)} />
                        </IconContext.Provider>
                      ) : (
                        <IconContext.Provider
                          value={{ className: 'text-slate-600 cursor-pointer' }}
                        >
                          <FaEyeSlash size={20} onClick={() => updateEyeStatus(false)} />
                        </IconContext.Provider>
                      )}
                    </span>
                    {errors.password && errors.password.message && (
                      <span className="text-red-400 text-sm">{errors.password.message}</span>
                    )}
                  </div>
                  <div className="flex justify-between">
                    <label className="inline-flex items-center cursor-pointer">
                      <span className="">
                        <span
                          className="text-sm font-semibold text-slate-600"
                          onClick={() => onchangetab(3)}
                        >
                          {dic.t('Margaux-Forgot Password')}
                        </span>
                      </span>
                    </label>
                    <span
                      className="text-sm text-slate-600 font-semibold cursor-pointer"
                      onClick={() => onchangetab(2)}
                    >
                      {dic.t('Margaux-Create Account')}
                    </span>
                  </div>
                  <div className="text-center mt-4">
                    <button
                      type="submit"
                      className="bg-slate-800  text-white active:bg-slate-600 text-sm font-medium tracking-widest uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                    >
                      {dic.t('Margaux-Sign In')}
                    </button>
                  </div>
                  <ResponseShow />
                  <div className="font-bold text-center mt-4">
                    <div className="text-center">
                      <span
                        id="continueguest"
                        className="text-sm text-slate-600 cursor-pointer underline underline-offset-4"
                        onClick={() => onchangetab(4)}
                      >
                        Continue as Guest
                      </span>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default LoginModal;
