import axios from 'axios';
import { AdminMicroRoutes } from '../../constants';
import * as helpers from '../../helpers/config';

export const UPDATE_ORDER_SUCCESS = 'UPDATE_ORDER_SUCCESS';
export const UPDATE_ORDER_LOADING = 'UPDATE_ORDER_LOADING';
export const UPDATE_ORDER_FAILURE = 'UPDATE_ORDER_FAILURE';
export const ORDER_STATUS_REVERT = 'ORDER_STATUS_REVERT';

export const onupdateorder = (data) => async (dispatch) => {
  const loggeddetails = helpers.LoggedUserDetails();
  dispatch({ type: UPDATE_ORDER_LOADING, payload: true });
  await axios
    .post(AdminMicroRoutes.updateorder, data, {
      headers: {
        token: loggeddetails?.token,
      },
    })
    .then((response) => {
      if (response.status === 200) {
        dispatch({ type: UPDATE_ORDER_LOADING, payload: false });
        dispatch({
          type: UPDATE_ORDER_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({ type: UPDATE_ORDER_LOADING, payload: false });
        dispatch({
          type: UPDATE_ORDER_FAILURE,
          payload: response.data?.message,
        });
      }
    })
    .catch((error) => {
      dispatch({ type: UPDATE_ORDER_LOADING, payload: false });
      dispatch({
        type: UPDATE_ORDER_FAILURE,
        payload: error?.response?.data?.message,
      });
    });
};

export const revertOrderStatus = (data) => async (dispatch) => {
  dispatch({ type: ORDER_STATUS_REVERT });
};
