import React, { Fragment } from 'react';
import CustomerForm from './CustomerForm';
import CustomerHistroy from './CustomerHistroy';

const CustomerScreen = ({ codes }) => {
  return (
    <Fragment>
      <div className="grid grid-cols-1 p-4 sm:p-0 lg:grid-cols-2 gap-4">
        <CustomerForm codes={codes} />
        <CustomerHistroy />
      </div>
    </Fragment>
  );
};

export default CustomerScreen;
