import React, { Fragment } from 'react';
import CakeForm from './CakeForm';
import ProductCart from './ProductCart';

const OrderScreen = ({
  cakeList,
  RegularList,
  IndiviualList,
  SignatureList,
  MacroonsList,
  Types,
  signatureName,
  macaroonName,
}) => {
  const FlavourOptions = [];
  const AddonList = [];
  const FinalcakeList = RegularList;
  FinalcakeList &&
    FinalcakeList.map((item) => {
      let cakename = item?.fields?.['Pastry Name']?.fields?.Title?.value;
      FlavourOptions.push({
        label: cakename,
        value: {
          services: item?.fields?.Services,
          addons: item?.fields?.Addons,
          cakeImages: item?.fields?.['Pastry Images'],
        },
      });
    });
  return (
    <Fragment>
      <div className="grid grid-cols-1 p-4 sm:p-0 lg:grid-cols-12 gap-2">
        <div className="col-span-6">
          <CakeForm
            FlavourOptions={FlavourOptions}
            RegularList={RegularList}
            IndiviualList={IndiviualList}
            SignatureList={SignatureList}
            MacroonsList={MacroonsList}
            Types={Types}
            AddonList={AddonList}
            signatureName={signatureName}
            macaroonName={macaroonName}
          />
        </div>
        <div className="col-span-6">
          <ProductCart />
        </div>
      </div>
    </Fragment>
  );
};

export default OrderScreen;
