import React, { Fragment, useEffect, useState } from 'react';
import { TbShoppingCartX } from 'react-icons/tb';
import { useDispatch, useSelector } from 'react-redux';
import { BiPlusCircle, BiMinusCircle } from 'react-icons/bi';
import { IconContext } from 'react-icons';
import { removeCenterCart, setCenterCart } from '../../../actions/admin/CallCenter.action';
import dic from 'i18next';
import { useToasts } from 'react-toast-notifications';
import ViewSignatureDetail from '../ViewSignatureDetail';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const ProductCart = () => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [TotalPrice, setTotalPrice] = useState(0);
  // let TotalPrice = 0;
  const { cartItems } = useSelector((state) => state.callcenter);
  const [finalcartItems, setfinalcartItems] = useState(cartItems);
  const [modalStatus, setModalStatus] = useState(false);
  const [getItem, setItems] = useState([]);
  useEffect(() => {
    setfinalcartItems(cartItems);
  }, [cartItems]);
  useEffect(() => {
    let Price = 0;
    finalcartItems.map((item) => (Price = Price + item.totalPrice));
    setTotalPrice(Price);
  }, [finalcartItems]);
  const addmore = (cakedata) => {
    dispatch(setCenterCart(cakedata));
  };
  const sendtoastmessage = () => {
    addToast('Maximum quantity we are allowed in 50', {
      appearance: 'error',
    });
  };
  const removeitem = (cakedata) => {
    dispatch(removeCenterCart(cakedata));
  };
  const ViewSignatureModal = (item) => {
    setItems(item);
    setModalStatus(!modalStatus);
  };

  return (
    <Fragment>
      {finalcartItems && finalcartItems.length > 0 ? (
        <div className="flex flex-col justify-center">
          <div className="container flex flex-col w-full items-center overflow-y-auto sm:h-72 scrollbar-thin scrollbar-thumb-gray-700 scrollbar-thumb-full scrollbar-track-rounded-full scrollbar-track-gray-300 bg-white border-2 border-gray-200 shadow">
            <ul className="flex flex-col w-full divide divide-y">
              {finalcartItems.map((item, index) => {
                return (
                  <li className="flex flex-row" key={index}>
                    <div className="select-none cursor-pointer flex flex-1 items-center py-2 px-4">
                      <div className="flex flex-col justify-center items-center mr-2">
                        <LazyLoadImage
                          alt="cake"
                          src={item.cakeImage}
                          className="mx-auto object-cover rounded h-16 w-20"
                        />
                      </div>
                      <div className="flex-1 pl-1 mr-10">
                        <div className="text-sm sm:font-medium">{item.cakeFlavour}</div>
                        {item.cakeBerries !== 0 && (
                          <div className="text-gray-600 text-sm">
                            {` Berries : (${item.cakeBerries})`}
                          </div>
                        )}
                        <div className="text-gray-600 text-sm">{item.cakeServings}</div>
                        {item.cakeMessage !== '' && (
                          <div className="text-gray-600 text-sm">✉️ {item.cakeMessage}</div>
                        )}
                        {item.signatureBox.length > 0 ? (
                          <>
                            <span
                              className="border-b text-xs text-black"
                              onClick={() => ViewSignatureModal(item.signatureBox)}
                            >
                              View Items
                            </span>
                          </>
                        ) : (
                          <></>
                        )}
                        {item.macaroonBox.length > 0 ? (
                          <>
                            <span
                              className="border-b text-xs text-black"
                              onClick={() => ViewSignatureModal(item.macaroonBox)}
                            >
                              View Items
                            </span>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                        <div className="col-span-1">
                          <div className="grid grid-cols-3 addtocartbox bg-gray-700 px-2 py-1 rounded-md">
                            <div
                              className="flex relative top-[0.2em] cursor-pointer"
                              onClick={() => removeitem(item)}
                            >
                              <IconContext.Provider value={{ className: 'text-white' }}>
                                <BiMinusCircle size={20} />
                              </IconContext.Provider>
                            </div>
                            <div className="flex text-white px-1 justify-center text-lg">
                              {item.cakeQuantity}
                            </div>
                            <div
                              className="flex relative top-1	cursor-pointer"
                              onClick={
                                item.cakeQuantity < 50
                                  ? () => addmore(item)
                                  : () => sendtoastmessage()
                              }
                            >
                              <IconContext.Provider value={{ className: 'text-white' }}>
                                <BiPlusCircle size={20} />
                              </IconContext.Provider>
                            </div>
                          </div>
                        </div>
                        <div className="col-span-1">
                          <div className="py-auto text-center">
                            <h2 className="font-bold tracking-widest text-gray-700">
                              {dic.t('Margaux-AED')} {item.totalPrice}
                            </h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="w-full text-right font-bold tracking-widest text-gray-700 my-auto flex justify-between px-4 h-10 border-2 border-gray-200 bg-gray-200 shadow mt-2">
            <div className="my-auto">{dic.t('Margaux-Total')}</div>
            <div className="my-auto">
              {dic.t('Margaux-AED')} {TotalPrice}
            </div>
          </div>
        </div>
      ) : (
        <div className="relative overflow-x-auto py-auto shadow p-4 border-2 broder-gray-400 h-72">
          <h2 className="text-2xl font-medium text-center">
            {dic.t('Margaux-There is nothing here')}
          </h2>
          <p className="mt-4 text-sm text-gray-500 text-center">
            {dic.t('Margaux-Add New Pastry')}
          </p>
          <h2 className="text-2xl font-medium text-center mt-4">🛒</h2>
        </div>
      )}
      <ViewSignatureDetail
        modalStatus={modalStatus}
        getItem={getItem}
        ViewSignatureModal={ViewSignatureModal}
      />
    </Fragment>
  );
};

export default ProductCart;
