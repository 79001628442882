import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import {
  onUserForgotPassword,
  onClearForgotPasswordState,
} from '../../actions/forgotpassword.action';
import Loader from '../Loader/Loader';
import ResponseShow from '../ResponseShow/ResponseShow';
import { emailPattern } from '../../helpers/Patterns';
import { BsXCircleFill } from 'react-icons/bs';
import { MdClose } from 'react-icons/md';
import dic from 'i18next';

function ForgotModal(props) {
  const dispatch = useDispatch();
  const { loading, statuscode, error } = useSelector((state) => state.forgotpassword);
  const status = useSelector((state) => state.forgotpassword.statuscode);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const onSubmits = (data) => {
    dispatch(onClearForgotPasswordState());
    const forgotpassdata = {
      userEmailId: data.email,
      hrefUrl: 'en/user-details/reset-password',
    };
    dispatch(onUserForgotPassword(forgotpassdata));
    reset();
  };
  const onclose = () => {
    dispatch(onClearForgotPasswordState());
    reset();
    props.toggleModal();
  };
  const onchangetab = (item) => {
    props.TabStatusAction(item);
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="w-full">
            <div className="relative flex flex-col min-w-0 break-words w-full shadow-lg rounded-lg bg-slate-200 border-0">
              <div className="close-icon">
                {/* <BsXCircleFill
                  onClick={onclose}
                  className="text-xl text-slate-400 cursor-pointer absolute right-2 top-2"
                /> */}
                <button
                  type="button"
                  className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-300 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                  onClick={onclose}
                >
                  <MdClose className="w-5 h-5" />
                </button>
              </div>
              <div className="flex-auto px-4 lg:px-10 pb-5 pt-0">
                <div className="text-slate-600 pt-5  pb-1 lg:pt-10 lg:pb-3 text-center font-bold">
                  <p className="text-lg">{dic.t('Margaux-Forgot Password')}</p>
                </div>
                <form className="pt-1 px-1" onSubmit={handleSubmit(onSubmits)}>
                  <ResponseShow success={statuscode} error={error} />
                  <div className="relative w-full mb-3">
                    {/* <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
                      {dic.t('Margaux-Email')}
                    </label> */}
                    <input
                      type="email"
                      className="border-0 px-3 py-3 placeholder:text-slate-600 placeholder:tracking-widest text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder={dic.t('Margaux-Email')}
                      {...register('email', {
                        required: 'Email is Required',
                        pattern: {
                          value: emailPattern,
                          message: 'Please enter a valid email',
                        },
                      })}
                      autoComplete="off"
                    />
                    {errors.email && errors.email.message && (
                      <span className="text-red-400 text-sm">{errors.email.message}</span>
                    )}
                  </div>

                  <div className="text-center mt-6">
                    <button
                      type="submit"
                      className="bg-slate-800 tracking-widest text-white active:bg-slate-600 text-sm  uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                    >
                      {dic.t('Margaux-Send Request')}
                    </button>
                  </div>
                  <ResponseShow />
                  <div className="flex flex-wrap justify-between font-bold mt-4 px-1">
                    <span
                      className="text-sm text-slate-600 cursor-pointer underline underline-offset-4"
                      onClick={() => onchangetab(1)}
                    >
                      {dic.t('Margaux-Already Have an Account')}
                    </span>
                    <span
                      className="text-sm text-slate-600 cursor-pointer underline underline-offset-4"
                      onClick={() => onchangetab(2)}
                    >
                      {dic.t('Margaux-Create Account')}
                    </span>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default ForgotModal;
