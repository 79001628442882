import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';
import { onUserRegistration, onClearRegistrationState } from '../../actions/registration.action';
import Loader from '../Loader/Loader';
import ResponseShow from '../ResponseShow/ResponseShow';
import {
  CountryCodeStyles,
  emailPattern,
  lettersspace,
  passwordPattern,
  phonepattern,
} from '../../helpers/Patterns';
import { BsXCircleFill } from 'react-icons/bs';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';
import { IconContext } from 'react-icons';
import dic from 'i18next';

function RegisterModal(props) {
  let countrycodeoptions = [];
  props &&
    props.codes.map((item) => {
      let phonemaxlength = item?.fields?.['Phone Number Maximum Length']?.value;
      let phoneminlength = item?.fields?.['Phone Number Minimum Length']?.value;
      let name = item?.fields?.Title?.value;
      let code = item?.fields?.['Country Code']?.value;
      countrycodeoptions.push({
        label: code + ' - ' + name,
        value: {
          name: name,
          code: code,
          max: phonemaxlength,
          min: phoneminlength,
        },
      });
    });
  const dispatch = useDispatch();
  const loader = useSelector((state) => state.registration.loading);
  const successmessage = useSelector((state) => state.registration.statuscode);
  const errormessage = useSelector((state) => state.registration.error);
  const [getPasswordStatus, setPasswordStatus] = useState(false);
  const [getPasswordStatusSec, setPasswordStatusSec] = useState(false);
  const [phonemin, setPhoneMin] = useState();
  const [phonemax, setPhoneMax] = useState();
  const [getContact, setContact] = useState('');
  const handleCheckNumber = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    setContact(value);
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    control,
    setValue,
  } = useForm();
  const onSubmits = (data) => {
    dispatch(onClearRegistrationState());
    let userEmail = data.email.toLowerCase();
    const regdata = {
      password: data.password,
      userFirstName: data.firstname,
      userLastName: data.lastname,
      userEmail: userEmail,
      phoneNumber: data.code.value.code + data.phone,
      requestType: 'END_USER',
      consent: data?.consent,
      marketingConsent: data?.marketingconsent,
    };
    dispatch(onUserRegistration(regdata));
    reset();
  };
  const updateEyeStatus = (status) => {
    setPasswordStatus(status);
    var x = document.getElementById('user_password');
    if (x.type === 'password') {
      x.type = 'text';
    } else {
      x.type = 'password';
    }
  };
  const updateEyeStatusSecond = (status) => {
    setPasswordStatusSec(status);
    var x = document.getElementById('user_confirm_password');
    if (x.type === 'password') {
      x.type = 'text';
    } else {
      x.type = 'password';
    }
  };
  const onclose = () => {
    dispatch(onClearRegistrationState());
    reset();
    props.toggleModal();
  };
  const onchangetab = (item) => {
    props.TabStatusAction(item);
  };
  const onCountryCodeChange = (e) => {
    setPhoneMin(e.value.min);
    setPhoneMax(e.value.max);
  };
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <>
          <div className="w-full">
            <div className="relative flex flex-col min-w-0 break-words w-full shadow-lg rounded-lg bg-slate-200 border-0">
              <div className="close-icon">
                <button
                  type="button"
                  className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-300 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                  onClick={onclose}
                >
                  <MdClose className="w-5 h-5" />
                </button>
              </div>
              <div className="flex-auto px-4 lg:px-6 pb-3 pt-0">
                <div className="text-slate-600 pt-5  pb-1 lg:pt-10 lg:pb-3 text-center font-bold">
                  <p className="text-lg">{dic.t('Margaux-Sign Up')}</p>
                </div>
                <form className="pt-3 px-1" onSubmit={handleSubmit(onSubmits)}>
                  <ResponseShow success={successmessage} error={errormessage} />
                  <div className="grid grid-cols-1 sm:grid-cols-2">
                    <div className="relative w-full mb-3 px-1">
                      <input
                        type="text"
                        name="user_name"
                        maxLength={20}
                        className="border-0 px-3 py-3 placeholder:text-slate-600 placeholder:tracking-widest text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder={dic.t('Margaux-First Name')}
                        {...register('firstname', {
                          required: 'First Name is Required',
                          pattern: {
                            value: lettersspace,
                            message: 'Please enter a valid first name',
                          },
                        })}
                        autoComplete="off"
                      />
                      {errors.firstname && errors.firstname.message && (
                        <span className=" mt-2 text-red-400 relative text-sm">
                          {errors.firstname.message}
                        </span>
                      )}
                    </div>
                    <div className="relative w-full mb-3 px-1">
                      <input
                        type="text"
                        className="border-0 px-3 py-3 placeholder:text-slate-600 placeholder:tracking-widest text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder={dic.t('Margaux-Last Name')}
                        maxLength={10}
                        {...register('lastname', {
                          required: 'Last Name is Required',
                          pattern: {
                            value: lettersspace,
                            message: 'Please enter a valid last name',
                          },
                        })}
                        autoComplete="off"
                      />
                      {errors.lastname && errors.lastname.message && (
                        <span className=" mt-2 text-red-400 relative text-sm">
                          {errors.lastname.message}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="grid grid-cols-1 sm:grid-cols-2">
                    <div className="relative w-full mb-3 px-1">
                      <Controller
                        name="code"
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <Select
                              options={countrycodeoptions}
                              value={value}
                              placeholder={`${'Country Code'}`}
                              styles={CountryCodeStyles}
                              onChange={(e) => {
                                onCountryCodeChange(e);
                                onChange(e);
                                setValue('phone', null);
                              }}
                            />
                          );
                        }}
                      />
                      {errors.code && errors.code.type === 'required' && (
                        <span className="text-red-400 text-sm">Country Code required</span>
                      )}
                    </div>
                    <div className="relative w-full mb-3 px-1">
                      <input
                        type="text"
                        className="border-0 px-3 py-3 placeholder:text-slate-600 placeholder:tracking-widest text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        maxLength={14}
                        placeholder={dic.t('Margaux-Phone Number')}
                        {...register('phone', {
                          required: 'Phone is Required',
                          maxLength: phonemax,
                          minLength: phonemin,
                        })}
                        value={getContact}
                        onChange={handleCheckNumber}
                        autoComplete="off"
                      />
                      {errors.phone && errors.phone.message && (
                        <span className="text-red-400 text-sm">{errors.phone.message}</span>
                      )}
                      {errors.phone && errors.phone.type === 'maxLength' && (
                        <span className="text-red-400 text-sm">Maximum Length Exceed</span>
                      )}
                      {errors.phone && errors.phone.type === 'minLength' && (
                        <span className="text-red-400 text-sm">Minimum Length Not Reached</span>
                      )}
                    </div>
                  </div>
                  <div className="relative w-full mb-3 px-1">
                    {/* <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
                        {dic.t('Margaux-Email')}
                      </label> */}
                    <input
                      type="text"
                      maxLength={50}
                      className="border-0 px-3 py-3 placeholder:text-slate-600 placeholder:tracking-widest text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder={dic.t('Margaux-Email')}
                      {...register('email', {
                        required: 'Email is Required',
                        pattern: {
                          value: emailPattern,
                          message: 'Please enter a valid email',
                        },
                      })}
                      autoComplete="off"
                    />
                    {errors.email && errors.email.message && (
                      <span className="text-red-400 text-sm">{errors.email.message}</span>
                    )}
                  </div>
                  <div className="grid grid-cols-1 sm:grid-cols-2">
                    <div className="relative w-full mb-3 px-1">
                      {/* <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
                        {dic.t('Margaux-Password')}
                      </label> */}
                      <input
                        type="password"
                        className="border-0 px-3 py-3 placeholder:text-slate-600 placeholder:tracking-widest text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder={dic.t('Margaux-Password')}
                        name="user_password"
                        id="user_password"
                        maxLength={15}
                        {...register('password', {
                          required: 'Password is Required',
                          pattern: {
                            value: passwordPattern,
                            message: 'Please enter a valid password',
                          },
                        })}
                        autoComplete="off"
                      />
                      <span className="absolute cursor-pointer top-[12px] right-[9px]">
                        {getPasswordStatus === false ? (
                          <IconContext.Provider
                            value={{ className: 'text-slate-600 cursor-pointer' }}
                          >
                            <FaEye size={19} onClick={() => updateEyeStatus(true)} />
                          </IconContext.Provider>
                        ) : (
                          <IconContext.Provider
                            value={{ className: 'text-slate-600 cursor-pointer' }}
                          >
                            <FaEyeSlash size={20} onClick={() => updateEyeStatus(false)} />
                          </IconContext.Provider>
                        )}
                      </span>
                      {errors.password && errors.password.message && (
                        <span className=" mt-2 text-red-400 relative text-sm">
                          {errors.password.message}
                        </span>
                      )}
                    </div>
                    <div className="relative w-full mb-3 px-1">
                      {/* <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
                        {dic.t('Margaux-Confirm Password')}
                      </label> */}
                      <input
                        type="password"
                        name="user_confirm_password"
                        id="user_confirm_password"
                        maxLength={15}
                        className="border-0 px-3 py-3 placeholder:text-slate-600 placeholder:tracking-widest text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder={dic.t('Margaux-Confirm Password')}
                        {...register('confirmpassword', {
                          required: 'Confim Password is Required',
                          validate: () => {
                            if (watch('password') !== watch('confirmpassword')) {
                              return 'Your passwords do no match';
                            }
                          },
                        })}
                        autoComplete="off"
                      />
                      <span className="absolute cursor-pointer top-[12px] right-[9px]">
                        {getPasswordStatusSec === false ? (
                          <IconContext.Provider
                            value={{ className: 'text-slate-600 cursor-pointer' }}
                          >
                            <FaEye size={19} onClick={() => updateEyeStatusSecond(true)} />
                          </IconContext.Provider>
                        ) : (
                          <IconContext.Provider
                            value={{ className: 'text-slate-600 cursor-pointer' }}
                          >
                            <FaEyeSlash size={20} onClick={() => updateEyeStatusSecond(false)} />
                          </IconContext.Provider>
                        )}
                      </span>
                      {errors.confirmpassword && errors.confirmpassword.message && (
                        <span className="mt-2 text-red-400 relative text-sm">
                          {errors.confirmpassword.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <p className="text-[0.8rem] px-3 sm:px-2">
                    Use 8 or more characters with a mix of letters, numbers & symbols (Example :
                    JohnDoe@1995)
                  </p>
                  <strong className="text-[0.8rem] px-3 sm:px-2">
                    Note : Account creation is available for UAE residents
                  </strong>
                  <div className="grid grid-cols-1 sm:grid-cols-2">
                    <div className="flex px-2 mt-2">
                      <div className="flex items-center h-5">
                        <input
                          id="consent-checkbox"
                          type="checkbox"
                          className="w-4 h-4 text-slate-600 bg-gray-100 rounded border-gray-300"
                          {...register('consent', {
                            required: 'Acceptance of Data privacy policy is mandatory to Register ',
                          })}
                        />
                      </div>
                      <div className="ml-2 text-sm">
                        <label htmlFor="consent-checkbox">
                          <span>
                            <div className="relative z-20 flex flex-col text-gray-900 cursor-pointer  group">
                              <p className="text-xs">
                                <span
                                  className={`border-b underline underline-offset-2  ${
                                    errors.consent ? 'text-red-600' : ''
                                  }`}
                                >
                                  I Accept Data Privacy
                                </span>
                              </p>
                              <div className="absolute bottom-0 flex flex-col items-center hidden mb-6 group-hover:flex">
                                <div className="relative z-10 p-2 text-xs leading-none text-black whitespace-no-wrap rounded-lg bg-white shadow-lg">
                                  <p className="py-1 px-1">
                                    I consent to Margaux Pastry retaining my personal data for
                                    future orders including any data about dietary preferences
                                  </p>
                                </div>
                                <div className="w-3 h-3 -mt-2 rotate-45 bg-white"></div>
                              </div>
                            </div>
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className="flex px-2 mt-2">
                      <div className="flex items-center h-5">
                        <input
                          id="marketingconsent"
                          type="checkbox"
                          className="w-4 h-4 text-slate-600 bg-gray-100 rounded border-gray-300"
                          {...register('marketingconsent')}
                        />
                      </div>
                      <div className="ml-2 text-sm">
                        <label
                          htmlFor="marketingconsent"
                          className="font-medium text-gray-900 tracking-widest"
                        >
                          <span>
                            <div className="relative z-20 flex flex-col text-gray-900 cursor-pointer  group">
                              <p className="text-xs">
                                <span className="border-b underline underline-offset-2">
                                  I Accept Marketing Policy
                                </span>
                              </p>
                              <div className="absolute bottom-0 flex flex-col items-center hidden mb-6 group-hover:flex">
                                <div className="relative z-10 p-2 text-xs leading-none text-black whitespace-no-wrap rounded-lg bg-white shadow-lg">
                                  <p className="py-1 px-1">
                                    I would like to receive tailored offers from Margaux Pastry you
                                    can withdraw your consent at any time by logging into your
                                    account
                                  </p>
                                </div>
                                <div className="w-3 h-3 -mt-2 rotate-45 bg-white"></div>
                              </div>
                            </div>
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div>
                    {errors.consent && errors.consent.message && (
                      <span className="mt-2 text-red-400 px-3 sm:px-2 relative text-xs">
                        {errors.consent.message}
                      </span>
                    )}
                  </div>

                  <div className="text-center mt-6">
                    <button
                      type="submit"
                      className="bg-slate-800 uppercase tracking-widest text-white active:bg-slate-600 text-sm font-normal px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                    >
                      {dic.t('Margaux-Create Account')}
                    </button>
                  </div>
                  <ResponseShow />
                  <div className="flex flex-wrap pb-4 font-bold">
                    <div className="w-full text-center">
                      <span
                        className="text-sm underline underline-offset-4 text-slate-600 cursor-pointer"
                        onClick={() => onchangetab(1)}
                      >
                        {dic.t('Margaux-Already Have an Account')}
                      </span>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default RegisterModal;
