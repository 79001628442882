import {
  ADMIN_ORDER_WITHOUTNI,
  LOADING_ADMIN_ORDER_WITHOUTNI,
} from '../../actions/admin/AdminOrder.action';

const initailState = {
  loading: false,
  statusCode: '',
};

export const saveAdminOrder = (state = initailState, action) => {
  switch (action.type) {
    case LOADING_ADMIN_ORDER_WITHOUTNI:
      return {
        ...state,
        loading: action.payload,
      };

    default:
      return state;
  }
};
