import React, { useState, Fragment } from 'react';
import { MdClose } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { useForm, Controller } from 'react-hook-form';
import {
  CallCenterCountryCodeStyles,
  CountryCodeStyles,
  emailPattern,
  lettersspace,
  passwordPattern,
  phonepattern,
} from '../../../helpers/Patterns';
import { colourStyles } from '../../../helpers/Patterns';
import { GiCook } from 'react-icons/gi';
import { RiCustomerService2Fill, RiAdminFill } from 'react-icons/ri';
import { IconContext } from 'react-icons/lib';
import { onuserhistory } from '../../../actions/admin/UserHistory.action';
import { setCustomerInfo } from '../../../actions/admin/CallCenter.action';
import dic from 'i18next';

const CustomerForm = (props) => {
  let countrycodeoptions = [];
  props &&
    props.codes.map((item) => {
      let phonemaxlength = item?.fields?.['Phone Number Maximum Length']?.value;
      let phoneminlength = item?.fields?.['Phone Number Minimum Length']?.value;
      let name = item?.fields?.Title?.value;
      let code = item?.fields?.['Country Code']?.value;
      countrycodeoptions.push({
        label: code + ' - ' + name,
        value: {
          name: name,
          code: code,
          max: phonemaxlength,
          min: phoneminlength,
        },
      });
    });
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm();
  const { customerInfo } = useSelector((state) => state.callcenter);
  console.log('customerInfo', customerInfo);
  const [getContact, setContact] = useState('');
  const [phonemin, setPhoneMin] = useState();
  const [phonemax, setPhoneMax] = useState();
  const handleCheckNumber = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    setContact(value);
  };
  const onSubmits = (data) => {
    const custdata = {
      customerPhone: data.phone,
      customerCountryCode: data.code,
      CustomerPhoneNumber: data.code.value.code + data.phone,
      CustomerEmail: data.email,
      CustomerFirstName: data.firstname,
      CustomerLastName: data.lastname,
      StatusofGuest: data.Status,
    };
    const payload = {
      searchKey: 'phoneNumber',
      searchValue: data.code.value.code + data.phone,
    };
    dispatch(onuserhistory(payload));
    dispatch(setCustomerInfo(custdata));
  };
  const onCountryCodeChange = (e) => {
    setPhoneMin(e.value.min);
    setPhoneMax(e.value.max);
  };
  let selectCountryvalue = [];
  if (customerInfo?.customerCountryCode?.label !== undefined) {
    selectCountryvalue = {
      label:
        customerInfo && customerInfo.customerCountryCode && customerInfo.customerCountryCode.label,
      value: {
        name:
          customerInfo &&
          customerInfo.customerCountryCode &&
          customerInfo.customerCountryCode.value &&
          customerInfo.customerCountryCode.value.name,
        code:
          customerInfo &&
          customerInfo.customerCountryCode &&
          customerInfo.customerCountryCode.value &&
          customerInfo.customerCountryCode.value.code,
        max:
          customerInfo &&
          customerInfo.customerCountryCode &&
          customerInfo.customerCountryCode.value &&
          customerInfo.customerCountryCode.value.max,
        min:
          customerInfo &&
          customerInfo.customerCountryCode &&
          customerInfo.customerCountryCode.value &&
          customerInfo.customerCountryCode.value.min,
      },
    };
  } else {
    selectCountryvalue = {
      label: '+971 - United Arab Emirates',
      value: {
        name: '+971',
        code: '10',
        max: '7',
        min: 'United Arab Emirates',
      },
    };
  }

  return (
    <Fragment>
      <div className="rounded shadow-md border-2 border-gray-300 p-4 w-full">
        <form className="grid grid-cols-12 gap-2" onSubmit={handleSubmit(onSubmits)}>
          <div className="w-full mb-3 col-span-6">
            <input
              type="text"
              name="user_name"
              maxLength={20}
              className="border border-gray-300 p-2 tracking-wider placeholder-gray-500 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              placeholder={dic.t('Margaux-First Name')}
              defaultValue={customerInfo && customerInfo.CustomerFirstName}
              {...register('firstname', {
                required: 'First Name is required',
                pattern: {
                  value: lettersspace,
                  message: 'Please enter a valid customer name',
                },
              })}
              autoComplete="off"
            />
            {errors.firstname && errors.firstname.message && (
              <span className=" mt-4 text-xs text-red-400">{errors.firstname.message}</span>
            )}
          </div>
          <div className="w-full mb-3 col-span-6">
            <input
              type="text"
              name="user_name"
              maxLength={20}
              className="border border-gray-300 p-2 tracking-wider placeholder-gray-500 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              defaultValue={customerInfo && customerInfo.CustomerLastName}
              placeholder={dic.t('Margaux-Last Name')}
              {...register('lastname', {
                required: 'Last Name is required',
                pattern: {
                  value: lettersspace,
                  message: 'Please enter a valid customer name',
                },
              })}
              autoComplete="off"
            />
            {errors.lastname && errors.lastname.message && (
              <span className=" mt-4 text-xs text-red-400">{errors.lastname.message}</span>
            )}
          </div>
          <div className="w-full mb-3 col-span-6">
            <Controller
              name="code"
              control={control}
              defaultValue={selectCountryvalue}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => {
                return (
                  <Select
                    placeholder={`${'Country Code'}`}
                    options={countrycodeoptions}
                    defaultValue={selectCountryvalue}
                    styles={CallCenterCountryCodeStyles}
                    onChange={(e) => {
                      onCountryCodeChange(e);
                      onChange(e);
                      setValue('phone', null);
                    }}
                  />
                );
              }}
            />
            {errors.code && errors.code.type === 'required' && (
              <span className="text-red-400 text-sm">Country Code required</span>
            )}
          </div>
          <div className="w-full mb-3 col-span-6">
            <input
              type="text"
              className="border border-gray-300 p-2 tracking-wider placeholder-gray-500 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              defaultValue={customerInfo.customerPhone}
              placeholder={dic.t('Margaux-Phone Number')}
              {...register('phone', {
                required: 'Phone is Required',
                maxLength: phonemax,
                minLength: phonemin,
              })}
              maxLength={15}
              onChange={handleCheckNumber}
              autoComplete="off"
            />
            {errors.phone && errors.phone.message && (
              <span className="text-xs mt-4 text-red-400">{errors.phone.message}</span>
            )}
            {errors.phone && errors.phone.type === 'maxLength' && (
              <span className="text-red-400 text-sm">Maximum Length Exceed</span>
            )}
            {errors.phone && errors.phone.type === 'minLength' && (
              <span className="text-red-400 text-sm">Minimum Length Not Reached</span>
            )}
          </div>
          <div className="w-full mb-3 col-span-6">
            <input
              type="text"
              className="border border-gray-300 p-2 tracking-wider placeholder-gray-500 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              placeholder={dic.t('Margaux-Email')}
              defaultValue={customerInfo && customerInfo.CustomerEmail}
              {...register('email', {
                required: 'Email is required',
                pattern: {
                  value: emailPattern,
                  message: 'Please enter a valid email',
                },
              })}
              autoComplete="off"
            />
            {errors.email && errors.email.message && (
              <span className="mt-4 text-xs text-red-400">{errors.email.message}</span>
            )}
          </div>
          <div className="w-full mb-3 col-span-6">
            <input
              type="text"
              className="border border-gray-300 p-2 tracking-wider placeholder-gray-500 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              placeholder={dic.t('Margaux-Status of Guest')}
              maxLength={30}
              defaultValue={customerInfo && customerInfo.StatusofGuest}
              {...register('Status', {
                pattern: {
                  value: lettersspace,
                  message: 'Please enter a valid text',
                },
              })}
              autoComplete="off"
            />
            {errors.Status && errors.Status.message && (
              <span className=" mt-4 text-xs text-red-400">{errors.Status.message}</span>
            )}
          </div>
          <div className="col-span-12 text-right">
            <button
              type="submit"
              className="text-white my-2 bg-gray-700 tracking-wider hover:bg-gray-500 font-medium rounded-lg text-md w-full px-5 py-2 text-center"
            >
              {dic.t('Margaux-Submit')}
            </button>
          </div>
        </form>
      </div>
    </Fragment>
  );
};

export default CustomerForm;
