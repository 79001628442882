import axios from 'axios';
import { AdminMicroRoutes } from '../../constants';

export const DASHBOARD_SUCCESS = 'DASHBOARD_SUCCESS';
export const DASHBOARD_FAILURE = 'DASHBOARD_FAILURE';
export const DASHBOARD_LOADING = 'DASHBOARD_LOADING';

export const getDadshboard = (data) => async (dispatch) => {
  dispatch({ type: DASHBOARD_LOADING, payload: true });
  const islogin = typeof Storage !== 'undefined' && localStorage.getItem('islogin');
  let tokenid = '';
  if (islogin === 'true') {
    tokenid = localStorage.getItem('tokenId');
    await axios
      .post(AdminMicroRoutes.getdashboard, data, { headers: { token: `${tokenid}` } })
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: DASHBOARD_SUCCESS,
            payload: response.data.dashBoardDetails,
          });
        } else {
          dispatch({
            type: DASHBOARD_FAILURE,
            payload: response.data.status,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: DASHBOARD_LOADING, payload: false });
        console.log('error', error);
      });
  }
};
