import axios from 'axios';
import { AdminMicroRoutes } from '../../constants';

export const FILTER_ORDER_SUCCESS = 'FILTER_ORDER_SUCCESS';
export const FILTER_ORDER_FAILURE = 'FILTER_ORDER_FAILURE';
export const FILTER_ORDER_LOADING = 'FILTER_ORDER_LOADING';

export const filterOrders = (data) => async (dispatch) => {
  dispatch({ type: FILTER_ORDER_LOADING, payload: true });
  const islogin = typeof Storage !== 'undefined' && localStorage.getItem('islogin');
  let tokenid = '';
  if (islogin === 'true') {
    tokenid = localStorage.getItem('tokenId');
    await axios
      .post(AdminMicroRoutes.filterorders, data, { headers: { token: `${tokenid}` } })
      .then((response) => {
        if (response?.data?.errorStatus) {
          console.log('response', response);
          dispatch({
            type: FILTER_ORDER_FAILURE,
            payload: response.data,
          });
        } else {
          dispatch({
            type: FILTER_ORDER_SUCCESS,
            payload: response.data.order,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FILTER_ORDER_FAILURE, payload: error.message });
        console.log('error', error);
      });
  }
};
