import React from 'react';
import { Fragment } from 'react';
import dic from 'i18next';

const MargauxStore = (props) => {
  const storephoto = props?.fields?.Image?.value?.src;
  const Description = props?.fields?.Description?.value;
  const Title = props?.fields?.Title?.value;
  return (
    <Fragment>
      <section className="bg-black">
        <div className="max-w-5xl px-4 py-8 mx-auto">
          <div className="striking-title items-center grid grid-cols-12 text-white">
            <hr className="col-span-3 md:col-span-4" />
            <h1 className="col-span-6 md:col-span-4 text-center text-4xl">
              {dic.t('Margaux-Store')}
            </h1>
            <hr className="col-span-3 md:col-span-4" />
          </div>
          <div className="mt-12">
            <div className="group relative block bg-black">
              <img
                alt="Developer"
                src={storephoto}
                className="absolute inset-0 h-full w-full object-cover opacity-75 transition-opacity"
              />

              <div className="relative p-8">
                {/* <p className="text-sm font-medium uppercase tracking-widest text-pink-500">
                Developer
              </p> */}

                <p className="text-2xl font-bold text-white">{Title}</p>

                <div className="mt-64">
                  <div className="translate-y-8 transform opacity-0 transition-all group-hover:translate-y-0 group-hover:opacity-100">
                    <p className="text-sm text-white">{Description}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default MargauxStore;
