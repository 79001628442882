import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';

const OfferList = (props) => (
  <div>
    <p></p>
  </div>
);

export default OfferList;
