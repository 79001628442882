import React, { Fragment, useEffect, useState } from 'react';
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from 'react-pro-sidebar';
import { FaTachometerAlt, FaGem, FaList, FaGithub, FaRegLaughWink, FaHeart } from 'react-icons/fa';
import { MdDashboard } from 'react-icons/md';
import { RiShoppingBag3Fill, RiCustomerService2Fill } from 'react-icons/ri';
import { MdHeadphones } from 'react-icons/md';
import { GiCook } from 'react-icons/gi';
import { FaUsers } from 'react-icons/fa';
import { FiArrowRightCircle, FiArrowLeftCircle } from 'react-icons/fi';
import { IconContext } from 'react-icons';
import { useMediaQuery } from 'react-responsive';
import 'react-pro-sidebar/dist/css/styles.css';
import * as helpers from '../../helpers/config';
import { ADMIN_PERMISSION, ADMIN_TABS, DEFALUT_PERMISSION } from '../../constants';
const LeftMenu = ({ leftmenu, selectOption, updateOptions }) => {
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 });
  const [menuCollapse, setMenuCollapse] = useState(!isDesktopOrLaptop);
  const [current, setCurrentpage] = useState('');
  useEffect(() => {
    setMenuCollapse(!isDesktopOrLaptop);
    setCurrentpage(window.location.pathname);
  }, [isDesktopOrLaptop]);

  var links = [];
  var adminPermission = '';
  const loggeddetails = helpers.LoggedUserDetails();
  adminPermission = loggeddetails?.accessDetails?.permissionsList?.permission;
  // admin permission logged in checkin
  // if (adminPermission === undefined) {
  //   window.location.href = '/en/home';
  // }
  const usertype = loggeddetails?.accessDetails?.role;
  // if (usertype === 'END_USER') {
  //   window.location.href = '/en/home';
  // }
  const kitchenDisplay = adminPermission.includes(ADMIN_PERMISSION.kitchenDisplay);
  const orders = adminPermission.includes(ADMIN_PERMISSION.orders);
  const dashboard = adminPermission.includes(ADMIN_PERMISSION.dashboard);
  const employess = adminPermission.includes(ADMIN_PERMISSION.employess);
  const callcenter = adminPermission.includes(ADMIN_PERMISSION.callcenter);

  var navigationLinks = leftmenu;
  if (dashboard) {
    links.push(
      ...navigationLinks.filter((nav) => {
        return nav?.fields?.['Page Title']?.value === ADMIN_TABS.DASHBOARD;
      })
    );
  }
  if (orders) {
    links.push(
      ...navigationLinks.filter((nav) => {
        return nav?.fields?.['Page Title']?.value === ADMIN_TABS.ORDERS;
      })
    );
  }
  if (kitchenDisplay) {
    links.push(
      ...navigationLinks.filter((nav) => {
        return nav?.fields?.['Page Title']?.value === ADMIN_TABS.KITCHEN_DISPLAY;
      })
    );
  }
  if (callcenter) {
    links.push(
      ...navigationLinks.filter((nav) => {
        return nav?.fields?.['Page Title']?.value === ADMIN_TABS.CALL_CENTER;
      })
    );
  }
  if (employess) {
    links.push(
      ...navigationLinks.filter((nav) => {
        return nav?.fields?.['Page Title']?.value === ADMIN_TABS.EMPLOYEES;
      })
    );
  }
  navigationLinks = links;
  return (
    <Fragment>
      <ProSidebar
        collapsed={menuCollapse}
        width={'208px'}
        collapsedWidth={'60px'}
        className={'text-white pt-12 h-screen z-0'}
      >
        <SidebarHeader className="py-2">
          {isDesktopOrLaptop ? (
            <div
              className={`relative ${menuCollapse ? 'left-7' : 'left-44'} cursor-pointer`}
              onClick={() => {
                setMenuCollapse(!menuCollapse);
              }}
            >
              {menuCollapse ? (
                <IconContext.Provider value={{ className: 'text-2xl' }}>
                  <FiArrowRightCircle />
                </IconContext.Provider>
              ) : (
                <IconContext.Provider value={{ className: 'text-2xl' }}>
                  <FiArrowLeftCircle />
                </IconContext.Provider>
              )}
            </div>
          ) : (
            <></>
          )}
        </SidebarHeader>
        <SidebarContent className="boder-t-1 border-t-inherit">
          <Menu className="">
            {navigationLinks &&
              navigationLinks.map((menu, index) => {
                const pagetitle = menu?.fields?.['Page Title']?.value;
                const pagelink = menu?.fields?.['Direct CTA Link']?.value?.href;
                return (
                  <MenuItem
                    className={`${current === pagelink && 'bg-gray-100 text-gray-900'}`}
                    key={index}
                    active={current === pagelink ? true : false}
                    icon={
                      <IconContext.Provider
                        value={{
                          className: `text-2xl hover:cursor-pointer ${
                            current === pagelink && 'text-gray-900'
                          }`,
                        }}
                      >
                        {pagetitle === 'Dashboard' ? (
                          <MdDashboard />
                        ) : pagetitle === 'Orders' ? (
                          <RiShoppingBag3Fill />
                        ) : pagetitle === 'Call Center' ? (
                          <RiCustomerService2Fill />
                        ) : pagetitle === 'Kitchen Display' ? (
                          <GiCook />
                        ) : pagetitle === 'Employees' ? (
                          <FaUsers />
                        ) : (
                          <></>
                        )}
                      </IconContext.Provider>
                    }
                  >
                    <a href={pagelink}>
                      <span className={`${current === pagelink && 'text-gray-900 font-semibold'}`}>
                        {pagetitle}
                      </span>
                    </a>
                  </MenuItem>
                );
              })}
          </Menu>
        </SidebarContent>
      </ProSidebar>
    </Fragment>
  );
};

export default LeftMenu;
