export const ORDER_FUNCTION = 'ORDER_FUNCTION';
import axios from 'axios';
import { MicroRoutes } from '../../constants';
import * as helpers from '../../helpers/config';

export const onOrder = (data,deliverycharge,discount) => async (dispatch) => {
  const getOrderObject = {'data':data, 'deliverycharge' : deliverycharge , 'Discount' : discount };
  let encryptPayload = helpers.encryptePayload(getOrderObject);
  const islogin = localStorage.getItem('islogin');
  let tokenid = '';
  if (islogin === 'true') {
    tokenid = localStorage.getItem('tokenId');
  } else {
    await axios
      .post(MicroRoutes.guestUserToken)
      .then(async (response) => {
        tokenid = response.data.guestUserToken;
      })
      .catch((error) => {
        console.log('error while guest user token', error);
      });
  }
  await axios
    .post(MicroRoutes.ordercreate + '?token=' + tokenid, encryptPayload)
    .then(async (response) => {
      dispatch({
        type: ORDER_FUNCTION,
        loading: true,
        payload: {
          status: 200,
          OrderId: response.data.orderId,
          statusMessage: 'Order has been Created Successfully',
        },
      });
    })
    .catch((error) => {
      console.log('error while order', error);
    });
};
