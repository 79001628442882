export const letters = /^[A-Za-z]+$/;
export const lettersspace = /^[a-zA-Z_ ]*$/;
export const alphaNumeric = /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/;
export const passwordPattern =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,100}$/;
export const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const phonepattern = /^(\+\d{1,3}[- ]?)?\d{10}$/;
export const Numberpattern = /^\d*[.]?\d*$/;
export const colourStyles = {
  control: (styles, state) => ({
    ...styles,
    backgroundColor: 'transperant',
    color: '#ffffff',
    borderRadius: 10,
    minHeight: '30px',
    height: '30px',
    borderColor: '#abb8bd',
    '&:hover': { borderColor: '#abb8bd' },
    boxShadow: 'none',
  }),
  indicatorSeparator: (styles) => ({ display: 'none' }),
  dropdownIndicator: (base) => ({
    ...base,
    position: 'relative',
    top: '-4px',
    color: '#abb8bd',
  }),
  valueContainer: (defaultStyles) => {
    return {
      ...defaultStyles,
      position: 'relative',
      top: '-4px',
    };
  },
  input: (base) => ({
    ...base,
    color: 'black',
  }),
  singleValue: (base) => ({
    ...base,
    color: 'black',
  }),
  menuList: (defaultStyles) => {
    return {
      ...defaultStyles,
      '::-webkit-scrollbar': {
        width: '5px',
      },
      '::-webkit-scrollbar-track': {
        background: '#f1f1f1',
        boxShadow: 'inset 0 0 5px grey',
        borderRadius: '5px',
      },
      '::-webkit-scrollbar-thumb': {
        background: '#888',
        borderRadius: '5px',
      },
    };
  },
  menu: (provided, state) => {
    return {
      ...provided,
      backgroundColor: 'black',
      borderRadius: 5,
    };
  },
  option: (provided, state) => {
    return {
      ...provided,
      backgroundColor: state.isSelected ? 'gray' : 'black',
      color: state.isSelected ? 'white' : 'white',
    };
  },
};
export const filterStyles = {
  control: (styles, state) => ({
    ...styles,
    backgroundColor: 'transperant',
    color: '#ffffff',
    borderRadius: 10,
    minHeight: '30px',
    height: '30px',
    borderColor: 'transparent',
    '&:hover': { borderColor: 'transparent' },
    boxShadow: 'none',
  }),
  indicatorSeparator: (styles) => ({ display: 'none' }),
  dropdownIndicator: (base) => ({
    ...base,
    position: 'relative',
    top: '-4px',
    color: '#abb8bd',
  }),
  valueContainer: (defaultStyles) => {
    return {
      ...defaultStyles,
      position: 'relative',
      top: '-4px',
    };
  },
  input: (base) => ({
    ...base,
    color: 'black',
  }),
  singleValue: (base) => ({
    ...base,
    color: 'black',
  }),
  menuList: (defaultStyles) => {
    return {
      ...defaultStyles,
      '::-webkit-scrollbar': {
        width: '5px',
      },
      '::-webkit-scrollbar-track': {
        background: '#f1f1f1',
        boxShadow: 'inset 0 0 5px grey',
        borderRadius: '5px',
      },
      '::-webkit-scrollbar-thumb': {
        background: '#888',
        borderRadius: '5px',
      },
    };
  },
  menu: (provided, state) => {
    return {
      ...provided,
      backgroundColor: 'black',
      borderRadius: 5,
    };
  },
  option: (provided, state) => {
    return {
      ...provided,
      backgroundColor: state.isSelected ? 'gray' : 'black',
      color: state.isSelected ? 'white' : 'white',
    };
  },
};
export const CountryCodeStyles = {
  indicatorSeparator: (styles) => ({ display: 'none' }),
  valueContainer: (defaultStyles) => {
    return {
      ...defaultStyles,
      padding: 5,
      fontFamily: 'jumeirah-font2',
      color: 'text-slate-600',
    };
  },
  menu: (provided, state) => {
    return {
      ...provided,
      zIndex: 21,
      // position: 'relative',
    };
  },
};
export const CallCenterCountryCodeStyles = {
  indicatorSeparator: (styles) => ({ display: 'none' }),
  valueContainer: (defaultStyles) => {
    return {
      ...defaultStyles,
      padding: 3,
      fontFamily: 'jumeirah-font2',
      color: 'text-slate-600',
    };
  },
  menu: (provided, state) => {
    return {
      ...provided,
      zIndex: 21,
      // position: 'relative',
    };
  },
};
