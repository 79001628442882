export const SetAdminCartData = (data) => {
  sessionStorage.setItem('admincakecartdata', JSON.stringify(data));
};

export const GetAdminCartData = (data) => {
  const getdata = sessionStorage.getItem('admincakecartdata', JSON.parse(data));
  return getdata;
};

export const isAuthorize = () => {
  const loggedin = sessionStorage.getItem('admincakecartdata', JSON.parse(data));
};

export const getNextday = () => {
  let tomarrow = new Date();
  tomarrow = new Date(tomarrow.setDate(tomarrow.getDate() + 1));
  return tomarrow;
};

export const getNexttoNextday = () => {
  let tomarrow = new Date();
  tomarrow = new Date(tomarrow.setDate(tomarrow.getDate() + 2));
  return tomarrow;
};

export const getOrderPayload = (
  customerDeliveryInfo,
  cartItems,
  customerInfo,
  subTotalPrice,
  loggeddetails,
  TotalPayment
) => {
  let orderPayload = {};
  let usertype = loggeddetails?.accessDetails?.role;
  let userEmailId = loggeddetails?.userEmailId;
  let userId = loggeddetails?.userId;
  let items = [];
  var MyDateString;
  MyDateString =
    customerDeliveryInfo.dpdate.getFullYear() +
    '-' +
    ('0' + (customerDeliveryInfo.dpdate.getMonth() + 1)).slice(-2) +
    '-' +
    ('0' + customerDeliveryInfo.dpdate.getDate()).slice(-2);
  cartItems.map((item) => {
    items.push({
      cakeType: item.cakeType,
      addOns: '',
      itemName: item.cakeFlavour,
      itemDescription: item.cakeServings,
      itemMessage: item.cakeMessage,
      itemImageUrl: item.cakeImage,
      perItemPrice: Number(item.cakePerItemPrice),
      quantity: Number(item.cakeQuantity),
      totalPrice: Number(item.totalPrice),
      signatureItems: item.signatureBox,
      macaroonItems: item.macaroonBox,
    });
  });
  orderPayload = {
    cheque: 0,
    couponCode: '',
    currency: 'AED',
    customerName: customerInfo.CustomerFirstName,
    customerEmail: customerInfo.CustomerEmail,
    cutlery: '',
    deliveryAddress: {
      region: customerDeliveryInfo.deliveryAddress.region,
      makani: customerDeliveryInfo.deliveryAddress.makani,
      address: customerDeliveryInfo.deliveryAddress.address,
    },
    deliveryCharge: Number(customerDeliveryInfo.deliveryAddress.deliveryPrice),
    deliveryType: customerDeliveryInfo.deliverytype,
    discount: '',
    dpDate: MyDateString,
    dpTime: customerDeliveryInfo.dptime,
    history: '',
    items: items,
    outlet: '',
    paymentMode: 'ONLINE',
    paymentStatus: 'UNPAID',
    phoneNumber: customerInfo.CustomerPhoneNumber,
    reasonForCancellation: '',
    region: customerDeliveryInfo.deliveryAddress.region,
    subTotal: Number(subTotalPrice),
    totalPrice: Number(TotalPayment),
    transactionNumber: '',
    remarks: '',
    userDTO: {
      userId: Number(userId),
      userType: usertype,
      userEmail: userEmailId,
      savedAddress: [],
    },
    vat: '',
  };
  return orderPayload;
};

export const getInvoiceOrderPayload = (
  customerDeliveryInfo,
  cartItems,
  customerInfo,
  subTotalPrice,
  loggeddetails,
  TotalPayment,
  invoice_order_ref
) => {
  let orderPayload = {};
  let usertype = loggeddetails?.accessDetails?.role;
  let userEmailId = loggeddetails?.userEmailId;
  let userId = loggeddetails?.userId;
  let items = [];
  var MyDateString;
  MyDateString =
    customerDeliveryInfo.dpdate.getFullYear() +
    '-' +
    ('0' + (customerDeliveryInfo.dpdate.getMonth() + 1)).slice(-2) +
    '-' +
    ('0' + customerDeliveryInfo.dpdate.getDate()).slice(-2);
  cartItems.map((item) => {
    items.push({
      cakeType: item.cakeType,
      addOns: '',
      itemName: item.cakeFlavour,
      itemDescription: item.cakeServings,
      itemMessage: item.cakeMessage,
      itemImageUrl: item.cakeImage,
      perItemPrice: Number(item.cakePerItemPrice),
      quantity: Number(item.cakeQuantity),
      totalPrice: Number(item.totalPrice),
      signatureItems: item.signatureBox,
      macaroonItems: item.macaroonBox,
    });
  });
  orderPayload = {
    cheque: 0,
    couponCode: '',
    currency: 'AED',
    customerName: customerInfo.CustomerFirstName,
    customerEmail: customerInfo.CustomerEmail,
    cutlery: '',
    deliveryAddress: {
      region: customerDeliveryInfo.deliveryAddress.region,
      makani: customerDeliveryInfo.deliveryAddress.makani,
      address: customerDeliveryInfo.deliveryAddress.address,
    },
    deliveryCharge: Number(customerDeliveryInfo.deliveryAddress.deliveryPrice),
    deliveryType: customerDeliveryInfo.deliverytype,
    discount: '',
    dpDate: MyDateString,
    dpTime: customerDeliveryInfo.dptime,
    history: '',
    items: items,
    outlet: '',
    paymentMode: 'ONLINE',
    paymentStatus: 'UNPAID',
    phoneNumber: customerInfo.CustomerPhoneNumber,
    reasonForCancellation: '',
    region: customerDeliveryInfo.deliveryAddress.region,
    subTotal: Number(subTotalPrice),
    totalPrice: Number(TotalPayment),
    transactionNumber: invoice_order_ref,
    remarks: '',
    userDTO: {
      userId: Number(userId),
      userType: usertype,
      userEmail: userEmailId,
      savedAddress: [],
    },
    vat: '',
  };
  return orderPayload;
};

export const getPaymentPayload = (
  TotalPayment,
  customerInfo,
  customerDeliveryInfo,
  redirectUrl
) => {
  const PaymentPayload = {
    action: 'SALE',
    amount: {
      currencyCode: 'AED',
      value: TotalPayment * 100,
    },
    emailAddress: customerInfo.CustomerEmail,
    language: 'en',
    billingAddress: {
      firstName: customerInfo.CustomerFirstName,
      address1: customerDeliveryInfo.deliveryAddress.address,
    },
    merchantAttributes: {
      redirectUrl: redirectUrl,
      skipConfirmationPage: true,
    },
    merchantDefinedData: {
      deliveryCharge: customerDeliveryInfo.deliveryAddress.deliveryPrice,
    },
  };
  return PaymentPayload;
};

export const getInvoicePayload = (
  customerDeliveryInfo,
  cartItems,
  customerInfo,
  subTotalPrice,
  loggeddetails,
  TotalPayment,
  deliveryprice
) => {
  let invoicePayload = {};
  let usertype = loggeddetails?.accessDetails?.role;
  let userEmailId = loggeddetails?.userEmailId;
  let tomarrow = new Date();
  tomarrow = new Date(tomarrow.setDate(tomarrow.getDate() + 1));
  let TomarrowString =
    tomarrow.getFullYear() +
    '-' +
    ('0' + (tomarrow.getMonth() + 1)).slice(-2) +
    '-' +
    ('0' + tomarrow.getDate()).slice(-2);
  let userId = loggeddetails?.userId;
  let items = [];
  cartItems.map((item) => {
    items.push({
      description: item.cakeFlavour,
      totalPrice: {
        currencyCode: 'AED',
        value: Number(item.totalPrice * 100),
      },
      quantity: Number(item.cakeQuantity),
    });
  });
  items.push({
    description: 'Delivery Charge',
    totalPrice: {
      currencyCode: 'AED',
      value: Number(deliveryprice * 100),
    },
    quantity: '1',
  });
  invoicePayload = {
    firstName: customerInfo.CustomerFirstName,
    lastName: customerInfo.CustomerLastName,
    email: customerInfo.CustomerEmail,
    transactionType: 'SALE',
    emailSubject: 'Invoice From Margaux Pastry',
    invoiceExpiryDate: TomarrowString,
    items: items,
    total: {
      currencyCode: 'AED',
      value: Number(TotalPayment * 100),
    },
    message:
      'Thank you for shopping with Margaux Pastry. Please visit the link provided below to pay your bill.',
  };
  return invoicePayload;
};
