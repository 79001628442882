import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import dic from 'i18next';
import ViewSignatureDetail from '../ViewSignatureDetail';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const SummaryLeft = () => {
  const { customerDeliveryInfo, cartItems, customerInfo } = useSelector(
    (state) => state.callcenter
  );
  const [finalcartItems, setfinalcartItems] = useState(cartItems);
  const [TotalPrice, setTotalPrice] = useState(0);
  const [modalStatus, setModalStatus] = useState(false);
  const [getItem, setItems] = useState([]);

  const ViewSignatureModal = (item) => {
    setItems(item);
    setModalStatus(!modalStatus);
  };

  useEffect(() => {
    setfinalcartItems(cartItems);
  }, [cartItems]);
  useEffect(() => {
    let Price = 0;
    finalcartItems.map((item) => (Price = Price + item.totalPrice));
    setTotalPrice(Price);
  }, [finalcartItems]);
  return (
    <Fragment>
      <div className="col-span-12 lg:col-span-8">
        <div className="grid grid-cols-2 shadow-md p-2 border-2 border-gray-200 h-full">
          <div className="card-body col-span-2 sm:col-span-1">
            <p className="text-base font-bold pb-2 border-b-2 border-gray-400">
              {dic.t('Margaux-Customer Details')}
            </p>
            <div className="flex flex-col mb-2 pt-2 flex-wrap w-full">
              <div className="flex items-center flex-wrap">
                <p className="text-base font-bold mr-2">{dic.t('Margaux-Name')}: </p>
                <p className="text-base">
                  {customerInfo.CustomerFirstName + ' ' + customerInfo.CustomerLastName}
                </p>
              </div>
              <div className="flex items-center flex-wrap">
                <p className="text-base font-bold mr-2">{dic.t('Margaux-Mobile Number')}: </p>
                <p className="text-base">{customerInfo.CustomerPhoneNumber}</p>
              </div>
            </div>
            <div className="flex flex-col mb-2 flex-wrap w-full">
              <div className="flex items-center flex-wrap">
                <p className="text-base font-bold mr-2">{dic.t('Margaux-Email')}: </p>
                <p className="text-base">{customerInfo.CustomerEmail}</p>
              </div>
              <div className="flex items-center flex-wrap">
                <p className="text-base font-bold mr-2">{dic.t('Margaux-Status of Guest')}: </p>
                <p className="text-base">{customerInfo.StatusofGuest}</p>
              </div>
            </div>
          </div>
          <div className="card-body col-span-2 sm:col-span-1">
            <p className="text-base font-bold pb-2 border-b-2 border-gray-400">
              {customerDeliveryInfo.deliverytype === 'DELIVERY' &&
                dic.t('Margaux-Delivery Details')}
              {customerDeliveryInfo.deliverytype === 'PICKUP' && dic.t('Margaux-Pickup Details')}
            </p>
            {customerDeliveryInfo.deliverytype === 'DELIVERY' && (
              <div className="flex flex-col mb-2 pt-2 flex-wrap">
                {/* <div className="flex items-center flex-wrap">
                  <p className="text-base font-bold mr-2">{dic.t('Margaux-Makani No')}: </p>
                  <p className="text-base">{customerDeliveryInfo.deliveryAddress.makani}</p>
                </div> */}
                <div className="flex items-center flex-wrap">
                  <p className="text-base font-bold mr-2">{dic.t('Margaux-Region')}: </p>
                  <p className="text-base">{customerDeliveryInfo.deliveryAddress.region}</p>
                </div>
                <div className="flex items-center flex-wrap">
                  <p className="text-base font-bold mr-2">{dic.t('Margaux-Address')}: </p>
                  <p className="text-base">{customerDeliveryInfo.deliveryAddress.address}</p>
                </div>
              </div>
            )}
            <div className="flex flex-col pt-2 flex-wrap">
              {/* <div className="flex items-center flex-wrap">
                <p className="text-base font-bold mr-2">{dic.t('Margaux-Delivery Type')}: </p>
                <p className="text-base">{customerDeliveryInfo.deliverytype}</p>
              </div> */}
              <div className="flex items-center flex-wrap">
                <p className="text-base font-bold mr-2">
                  {customerDeliveryInfo.deliverytype === 'DELIVERY' &&
                    dic.t('Margaux-Delivery Date')}
                  {customerDeliveryInfo.deliverytype === 'PICKUP' && dic.t('Margaux-Pickup Date')}
                </p>
                <p className="text-base">
                  {customerDeliveryInfo.dpdate.getDate()}/
                  {customerDeliveryInfo.dpdate.getMonth() + 1}/
                  {customerDeliveryInfo.dpdate.getFullYear()}
                </p>
              </div>
              <div className="flex items-center flex-wrap">
                <p className="text-base font-bold mr-2">
                  {customerDeliveryInfo.deliverytype === 'DELIVERY' &&
                    dic.t('Margaux-Delivery Time')}
                  {customerDeliveryInfo.deliverytype === 'PICKUP' && dic.t('Margaux-Pickup Time')}
                </p>
                <p className="text-base">{customerDeliveryInfo.dptime}</p>
              </div>
            </div>
          </div>
          <ViewSignatureDetail
            modalStatus={modalStatus}
            getItem={getItem}
            ViewSignatureModal={ViewSignatureModal}
          />

          {finalcartItems && finalcartItems.length > 0 && (
            <div className="flex flex-col justify-center col-span-2">
              <div className="container flex flex-col w-full items-center overflow-y-auto scrollbar-thin scrollbar-thumb-gray-700 scrollbar-thumb-full scrollbar-track-rounded-full scrollbar-track-gray-300">
                <ul className="flex flex-col w-full divide divide-y">
                  {finalcartItems.map((item, index) => {
                    return (
                      <li className="flex flex-row" key={index}>
                        <div className="select-none cursor-pointer flex flex-1 items-center py-4">
                          <div className="flex flex-col justify-center items-center mr-2">
                            <a href="#" className="block relative">
                              <LazyLoadImage
                                alt="cake"
                                src={item.cakeImage}
                                className="mx-auto object-cover rounded h-16 w-20"
                              />
                            </a>
                          </div>
                          <div className="flex-1 pl-1 mr-10">
                            <div className="text-sm sm:font-medium">{item.cakeFlavour}</div>
                            {item.cakeBerries !== 0 && (
                              <div className="text-gray-600 text-sm">
                                {` Berries : (${item.cakeBerries})`}
                              </div>
                            )}
                            <div className="text-gray-600 text-sm">{item.cakeServings}</div>
                            {item.cakeMessage !== '' && (
                              <div className="text-gray-600 text-sm">✉️ {item.cakeMessage}</div>
                            )}
                            {item.signatureBox.length > 0 ? (
                              <>
                                <span
                                  className="border-b text-xs text-black"
                                  onClick={() => ViewSignatureModal(item.signatureBox)}
                                >
                                  View Items
                                </span>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                            <div className="flex text-gray-700 px-1 justify-center text-lg">
                              Qty {item.cakeQuantity}
                            </div>
                            <div className="col-span-1 py-auto text-center">
                              <h2 className="font-bold tracking-widest text-gray-700">
                                {dic.t('Margaux-AED')} {item.totalPrice}
                              </h2>
                            </div>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default SummaryLeft;
