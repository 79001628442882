import { ORDER_FUNCTION } from '../actions/order/order.action';
import { DELIVERY_CHARGE_FUNCTION } from '../actions/checkoutconfig.action';
const initailState = {
  order_loading: false,
  order_detail: false,
};

export const userOrderFunction = (state = initailState, action) => {
  switch (action.type) {
    case ORDER_FUNCTION:
      return {
        ...state,
        order_loading: action.loading,
        order_detail: action.payload,
      };
    default:
      return state;
  }
};

export const CostCharge = {
  ordercharge: false,
};

export const userDeliveryChargeCost = (state = initailState, action) => {
  switch (action.type) {
    case DELIVERY_CHARGE_FUNCTION:
      return {
        ...state,
        ordercharge: action.deliverycharge,
      };
    default:
      return state;
  }
};
