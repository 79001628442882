import { React, useState, useEffect, useRef } from 'react';
import {
  AiOutlineEye,
  AiOutlineSearch,
  AiFillCloseCircle,
  AiOutlineDownload,
  AiFillFileExcel,
} from 'react-icons/ai';
import { TbShoppingCartX } from 'react-icons/tb';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import Select from 'react-select';
import { IconContext } from 'react-icons';
import { MdOutlineDeliveryDining, MdOutlineDoneOutline, MdPhonelinkRing } from 'react-icons/md';
import { BsBasketFill } from 'react-icons/bs';
import { RiShoppingBasketLine } from 'react-icons/ri';
import { GiCampCookingPot } from 'react-icons/gi';
import { IoCloseCircle } from 'react-icons/io5';
import { HiOutlineCurrencyDollar } from 'react-icons/hi';
import { colourStyles } from '../../helpers/Patterns';
import { filterStyles } from '../../helpers/Patterns';
import KitchenModal from './Modal';
import ConfirmationModal from './ConfirmationModal';
import moment from 'moment';
import { GiEvilFork } from 'react-icons/gi';
import { getAllOrders } from '../../actions/admin/GetAllOrder.action';
import TableLoader from '../Admin/TableLoader';
import { filterOrders } from '../../actions/admin/FilterOrder.action';
import { removeError } from '../../actions/admin/RemoveError.action';
import { generateExcelReport } from '../../actions/report.action';
import * as helpers from '../../helpers/config';
import dic from 'i18next';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const Main = () => {
  const {
    handleSubmit,
    register,
    setValue,
    reset,
    formState: { errors },
    control,
  } = useForm();

  const registerOptions = {
    // ...
    role: { required: 'Role is required' },
  };
  // fetching orders from redux state
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.allorders);
  const orders = useSelector((state) => state.allorders.orders);
  const filterloading = useSelector((state) => state.filterorders.loading);
  const filteredorders = useSelector((state) => state.filterorders.orders);
  const loggeddetails = helpers.LoggedUserDetails();
  const error = useSelector((state) => state.filterorders.error);
  const usertype = loggeddetails?.accessDetails?.role;
  const userEmailId = loggeddetails?.userEmailId;
  const today = new Date();
  const todayDate = new Date();
  const [date, setDateCurrent] = useState(todayDate.setDate(todayDate.getDate()));

  // state & function for view & close view more modal
  const [showModal, setShowModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [confirmModalItem, setConfirmModalItem] = useState();
  const [confirmModalType, setConfirmModalType] = useState('');
  const closeModal = () => {
    setShowModal(false);
  };
  const closeConfirmModal = () => {
    setConfirmModal(false);
  };

  // react pagination states
  const [items, setItems] = useState(orders);
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [searchPlaceholder, setSearchPlaceholder] = useState(false);
  const itemsPerPage = 10;

  // filter data state
  const [selectData, setSelectData] = useState();
  const [formData, updateFormData] = useState({
    searchKey: '',
    searchValue: '',
  });
  const labelRef = useRef();
  const valueRef = useRef();

  // set intial items
  useEffect(() => {
    setItemOffset(0);
  }, [items]);

  // fetch all orders action call
  useEffect(() => {
    const userlistdata = {
      userEmailId: userEmailId,
      userType: usertype,
    };
    dispatch(getAllOrders(userlistdata));
  }, []);

  // updated each time the orders with new data
  useEffect(() => {
    setItems(orders);
  }, [orders]);

  // updated each time the orders with filterred data
  useEffect(() => {
    setItems(filteredorders);
  }, [filteredorders]);

  // update the page items
  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    console.log('items', items);
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, items]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    setItemOffset(newOffset);
  };

  const searchHandleDatePicker = (e) => {
    updateFormData({
      ...formData,
      ['searchValue']: e.getFullYear() + '-' + (e.getMonth() + 1) + '-' + e.getDate(),
    });
    dispatch(removeError());
    setErrorMessage('');
  };
  useEffect(() => {
    const searchData = () => {
      const currentdate = new Date();
      updateFormData({
        ...formData,
        ['searchValue']:
          currentdate.getFullYear() +
          '-' +
          (currentdate.getMonth() + 1) +
          '-' +
          currentdate.getDate(),
      });
    };
    searchData();
  }, []);

  useEffect(() => {
    setErrorMessage(error);
  }, [error]);

  // filter handle change
  const FilterhandleChange = (e) => {
    if ((e && e.value === 'orderDate') || (e && e.value === 'deliveryDate')) {
      setSearchPlaceholder(true);
    } else {
      setSearchPlaceholder(false);
    }
    e &&
      updateFormData({
        ...formData,
        ['searchKey']: e.value,
      });
    if (e.value === 'filterAll') {
      handleClear();
    }
    dispatch(removeError());
    setErrorMessage('');
  };
  const SearchhandleChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value.trim(),
    });
    dispatch(removeError());
    setErrorMessage('');
  };
  // handle search & clear search
  const onSubmits = () => {
    dispatch(filterOrders(formData));
  };

  const handleClear = () => {
    setItems(orders);
    valueRef.current.value = '';
    // labelRef.current.clearValue();
    dispatch(removeError());
    setErrorMessage('');
  };

  const prepareReportData = () => {
    const date = new Date();
    let filterTerm = formData.searchKey;
    let filterQuery = formData.searchValue;
    let excelData = {
      generatedBy: loggeddetails?.firstName + ' ' + loggeddetails?.lastName,
      generatedDate:
        moment(date).format('DD\xa0MMM\xa0YYYY').toUpperCase() +
        ' ' +
        date.getHours() +
        ':' +
        ('0' + date.getMinutes()).slice(-2),
      // searchByValue: filterTerm,
      // searchString: filterQuery.length > 0 ? filterQuery : '',
    };
    let reportName = `KITCHEN_REPORT_${moment(new Date()).format('DDMMMYYYY').toUpperCase()}`;
    dispatch(generateExcelReport(excelData, reportName, 'KITCHEN'));
  };
  const onorderstatuschange = (item, type) => {
    setConfirmModal(true);
    setConfirmModalType(type);
    setConfirmModalItem(item);
  };
  const options = [
    { label: 'Delivery Date', value: 'deliveryDate' },
    { label: 'Order Date', value: 'orderDate' },
    { label: 'Order ID', value: 'order_id' },
    { label: 'Order Status', value: 'orderStatus' },
    { label: 'Customer Name', value: 'orderByName' },
    { label: 'Phone Number', value: 'phoneNumber' },
    { label: 'Show All', value: 'filterAll' },
  ];
  return (
    <>
      <main className="w-[inherit] mt-12 p-4 sm:px-8  bg-white">
        <div className="container grid mx-auto">
          <div className="block md:flex justify-between mb-4">
            <form onSubmit={handleSubmit(onSubmits)}>
              <div className="flex">
                <Controller
                  name="role"
                  control={control}
                  rules={registerOptions.role}
                  render={({ field: { onChange } }) => {
                    return (
                      <Select
                        options={options}
                        isClearable={false}
                        placeholder={`${dic.t('Margaux-Filter By') + ' *'}`}
                        onChange={(e) => {
                          FilterhandleChange(e);
                          onChange(e);
                        }}
                        styles={filterStyles}
                        ref={labelRef}
                        className="w-18 md:w-36 flex-shrink-0 z-10 inline-flex items-center text-sm font-medium text-left text-gray-900 bg-gray-100 border border-gray-300 rounded-l-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100"
                      />
                    );
                  }}
                />
                <div className="relative w-60">
                  {searchPlaceholder === true ? (
                    <ReactDatePicker
                      selected={date}
                      value={date}
                      id="searchValue"
                      name="searchValue"
                      dateFormat="yyyy-MM-dd"
                      className="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-r-lg border-l-gray-50 border-l-2 border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                      onChange={(date) => {
                        setDateCurrent(date);
                        searchHandleDatePicker(date);
                      }}
                      placeholderText="YYYY-MM-DD"
                      maxDate={today.setDate(today.getDate() + 180)}
                      autoComplete="off"
                      ref={valueRef}
                    />
                  ) : (
                    <input
                      type="text"
                      className="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-r-lg border-l-gray-50 border-l-2 border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                      placeholder={dic.t('Margaux-Search')}
                      name="searchValue"
                      required
                      onChange={SearchhandleChange}
                      ref={valueRef}
                      maxLength={20}
                      autoComplete="off"
                    />
                  )}
                  <button
                    type="submit"
                    disabled={formData.searchKey === 'filterAll'}
                    className="absolute top-0 right-0 p-2.5 text-sm font-medium text-white bg-gray-700 rounded-r-lg border border-gray-700 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300"
                  >
                    <AiOutlineSearch size="20" />
                  </button>
                </div>
              </div>
            </form>

            {/* <AiFillFileExcel size="20" onClick={prepareReportData} /> */}
            <div className="mt-2 md:mt-0">
              <button
                type="submit"
                className="px-5 py-3 text-xs font-medium text-white bg-gray-600 rounded hover:bg-gray-800 focus:ring-2 focus:outline-none focus:ring-gray-300"
                onClick={prepareReportData}
              >
                {dic.t('Margaux-Download Report')}
              </button>
            </div>
          </div>
          {loading || filterloading ? (
            <>
              <TableLoader />
            </>
          ) : (
            <div className="w-full overflow-hidden rounded-lg shadow-md border-2 border-gray-300">
              <div className="w-full">
                <table className="w-full whitespace-no-wrap">
                  <thead className="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b bg-gray-50">
                    <tr>
                      <td className="px-4 py-3 text-center">{dic.t('Margaux-Order ID')}</td>
                      <td className="px-4 py-3 text-center">{dic.t('Margaux-Created By')}</td>
                      <td className="px-4 py-3 text-center">{dic.t('Margaux-Order Date')}</td>
                      <td className="px-4 py-3 text-center">{dic.t('Margaux-Delivery Date')}</td>
                      <td className="px-4 py-3 text-center">{dic.t('Margaux-Delivery Status')}</td>
                      <td className="px-1 py-3 text-center">Order Status</td>
                      <td className="px-1 py-3 text-center"></td>
                    </tr>
                  </thead>

                  <tbody className="bg-white divide-y text-gray-700">
                    {currentItems.length > 0 && errorMessage === '' ? (
                      currentItems.map((item, key) => {
                        return (
                          <tr key={key}>
                            <td className="px-4 py-3 text-center">
                              <span className="text-sm font-semibold">{item.orderId}</span>
                            </td>
                            <td className="px-4 py-3 text-center">
                              <p className="text-sm font-semibold">{item.customerName}</p>
                            </td>
                            <td className="px-4 py-3 text-center">
                              <span className="text-sm font-semibold">
                                {moment(item.orderDate).format('YYYY-MM-DD')}
                              </span>
                            </td>
                            <td className="px-4 py-3 text-center">
                              <span className="text-sm font-semibold">
                                {moment(item.dpDate).format('YYYY-MM-DD')}
                              </span>
                            </td>
                            {/* <td className="px-4 py-3 text-center">
                            <span className="text-sm font-semibold">
                              {moment(item.orderDate).format('YYYY-MM-DD')}
                            </span>
                          </td> */}
                            {/* <td className="px-4 py-3 text-center">
                            <span className="text-sm font-semibold">AED {item.totalPrice}</span>
                          </td> */}
                            {/* <td className="px-4 py-3 text-center">
                              <span className="font-semibold inline-flex py-1 px-1 uppercase rounded text-stone-600">
                                {item.orderStatus === 'COOKING' && (
                                  <GiCampCookingPot
                                    size="20"
                                    className="hover:cursor-pointer text-amber-500"
                                  />
                                )}
                                {item.orderStatus === 'DISPATCH' && (
                                  <MdOutlineDeliveryDining
                                    size="20"
                                    className="hover:cursor-pointer text-red-500"
                                  />
                                )}
                                {item.orderStatus === 'DELIVERED' && (
                                  <MdOutlineDoneOutline
                                    size="20"
                                    className="hover:cursor-pointer text-green-600"
                                  />
                                )}
                              </span>
                            </td> */}
                            <td className="px-4 py-3 text-center">
                              <span className="font-semibold inline-flex py-1 px-1 uppercase rounded text-stone-600">
                                <GiCampCookingPot
                                  size="20"
                                  className={` hover:cursor-pointer ${
                                    item.orderStatus === 'COOKING'
                                      ? 'text-amber-500'
                                      : 'text-gray-600'
                                  } `}
                                  onClick={
                                    item.orderStatus === 'DELIVERED' ||
                                    item.orderStatus === 'CANCELLED'
                                      ? null
                                      : () => {
                                          onorderstatuschange(item, 'COOKING');
                                        }
                                  }
                                />
                                <MdOutlineDeliveryDining
                                  size="25"
                                  className={` hover:cursor-pointer mx-4 ${
                                    item.orderStatus === 'DISPATCH'
                                      ? 'text-red-500'
                                      : 'text-gray-600'
                                  } `}
                                  onClick={
                                    item.orderStatus === 'DELIVERED' ||
                                    item.orderStatus === 'CANCELLED'
                                      ? null
                                      : () => {
                                          onorderstatuschange(item, 'DISPATCH');
                                        }
                                  }
                                />
                                <MdOutlineDoneOutline
                                  size="20"
                                  className={`hover:cursor-pointer ${
                                    item.orderStatus === 'DELIVERED'
                                      ? 'text-green-600'
                                      : 'text-gray-600'
                                  } `}
                                  onClick={
                                    item.orderStatus === 'DELIVERED' ||
                                    item.orderStatus === 'CANCELLED'
                                      ? null
                                      : () => {
                                          onorderstatuschange(item, 'DELIVERED');
                                        }
                                  }
                                />
                              </span>
                            </td>
                            <td className="px-4 py-3 text-center">
                              <span className="font-semibold inline-flex py-1 px-1 uppercase rounded text-stone-600">
                                {item.orderStatus === 'PLACED' && (
                                  <BsBasketFill size="20" className="text-gray-500" />
                                )}
                                {item.orderStatus === 'COOKING' && (
                                  <GiCampCookingPot size="20" className=" text-amber-500" />
                                )}
                                {item.orderStatus === 'DISPATCH' && (
                                  <MdOutlineDeliveryDining size="20" className=" text-rose-500" />
                                )}
                                {item.orderStatus === 'DELIVERED' && (
                                  <MdOutlineDoneOutline size="20" className=" text-green-600" />
                                )}
                                {item.orderStatus === 'CANCELLED' && (
                                  <IoCloseCircle size="20" className=" text-red-600" />
                                )}
                              </span>
                            </td>

                            {/* <td className="px-4 py-3 content-center text-center">
                              {(() => {
                                if (item.paymentMode === 'ONLINE') {
                                  return (
                                    <IconContext.Provider
                                      value={{ className: 'text-black-700 mx-auto' }}
                                    >
                                      <MdPhonelinkRing sixe={20} />
                                    </IconContext.Provider>
                                  );
                                } else {
                                  return (
                                    <IconContext.Provider
                                      value={{ className: 'text-green-700 mx-auto' }}
                                    >
                                      <HiOutlineCurrencyDollar sixe={20} />
                                    </IconContext.Provider>
                                  );
                                }
                              })()}
                            </td> */}
                            {/* <td className="px-4 py-3 content-center  text-center">
                            <div className="flex justify-center">
                              {item.deliverystatus === 'Delivery' && (
                                <BiCycling size="20" className="text-gray-600" />
                              )}
                              {item.deliverystatus === 'PickUp' && (
                                <RiShoppingBasketLine size="20" className="text-gray-600" />
                              )}
                            </div>
                          </td> */}
                            <td className="px-4 py-3 text-center">
                              <button
                                className="text-sm font-semibold px-6 outline-none focus:outline-none ease-linear transition-all duration-150"
                                type="button"
                                onClick={() => {
                                  setShowModal(true);
                                  setSelectData(item);
                                }}
                              >
                                <AiOutlineEye size="18" />
                              </button>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={5}>
                          <div className="flex items-center justify-center text-2xl py-3">
                            <p className="text-lg text-gray-900 text-center">No Order History</p>
                            <p className="text-lg text-gray-900 text-center px-4">
                              <TbShoppingCartX />
                            </p>
                          </div>
                        </td>
                      </tr>
                    )}
                    {showModal && <KitchenModal closeModal={closeModal} data={selectData} />}
                    {confirmModal && (
                      <ConfirmationModal
                        closeModal={closeConfirmModal}
                        confirmModalType={confirmModalType}
                        item={confirmModalItem}
                      />
                    )}
                  </tbody>
                </table>
              </div>
              <div className="px-4 py-3 border-t bg-gray-50 text-gray-500">
                <div className="flex flex-col justify-end text-xs sm:flex-row text-gray-600">
                  {/* <span className="flex items-center tracking-wide">
                  {flag && <span>Total: {flag.length}</span>}
                </span> */}
                  <nav aria-label="Table navigation">
                    {items && items.length > itemsPerPage && errorMessage === '' && (
                      <ReactPaginate
                        containerClassName={'inline-flex items-center'}
                        pageClassName={'page-item'}
                        pageLinkClassName={
                          'align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-3 py-1 rounded-md text-xs text-gray-600  focus:outline-none border border-transparent active:text-white active:bg-gray-100 hover:bg-gray-100 focus:bg-gray-600 focus:text-white'
                        }
                        breakLabel="..."
                        nextLabel={'Next'}
                        previousClassName={
                          'align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-3 py-1 rounded-md text-xs text-gray-600  focus:outline-none border border-transparent active:text-white active:bg-gray-100 hover:bg-gray-100 focus:bg-gray-600 focus:text-white'
                        }
                        nextClassName={
                          'align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-3 py-1 rounded-md text-xs text-gray-600  focus:outline-none border border-transparent active:text-white active:bg-gray-100 hover:bg-gray-100 focus:bg-gray-600 focus:text-white'
                        }
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        pageCount={pageCount}
                        previousLabel={'Previous'}
                        renderOnZeroPageCount={null}
                        activeClassName={
                          'text-gray-700 bg-white border border-gray-400 rounded-md hover:bg-gray-700 hover:text-white'
                        }
                      />
                    )}
                  </nav>
                </div>
              </div>
            </div>
          )}
        </div>
      </main>
    </>
  );
};
export default Main;
