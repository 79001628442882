import axios from 'axios';
import { AdminMicroRoutes } from '../../constants';
import * as helpers from '../../helpers/config';

export const GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS';
export const GET_ORDER_FAILURE = 'GET_ORDER_FAILURE';
export const GET_ORDER_LOADING = 'GET_ORDER_LOADING';
// test
export const getAllOrders = (data) => async (dispatch) => {
  const loggeddetails = helpers.LoggedUserDetails();
  dispatch({ type: GET_ORDER_LOADING, payload: true });
  await axios
    .post(AdminMicroRoutes.getallorders, data, {
      headers: {
        token: loggeddetails?.token,
      },
    })
    .then((response) => {
      if (response?.data?.errorStatus) {
        dispatch({
          type: GET_ORDER_FAILURE,
          payload: response.data.status,
          status: response.status,
          message: response.data.message,
        });
      } else {
        dispatch({
          type: GET_ORDER_SUCCESS,
          payload: response.data.orders,
          status: response.status,
        });
      }
    })
    .catch((error) => {
      // dispatch({ type: GET_ORDER_LOADING, payload: false });
      console.log('error', error);
    });
};
