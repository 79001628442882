import React, { Fragment, useEffect, useState } from 'react';
import { TbShoppingCartX } from 'react-icons/tb';
import { AiOutlineEye } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import { IconContext } from 'react-icons';
import moment from 'moment';
import OrderHistoryLoader from './OrderHistoryLoader';
import dic from 'i18next';
import CustomerHistoryModal from './Modal';

const CustomerHistroy = () => {
  const { loading, orders, errors } = useSelector((state) => state.orderhistory);
  //   const { loading, roles, error } = useSelector((state) => state.allroles);
  const [orderhistory, setorderhistory] = useState(orders);
  const [selectData, setSelectData] = useState();
  const [showModal, setShowModal] = useState(false);
  const closeModal = () => {
    setShowModal(false);
  };
  // updated each time the orders with new data
  useEffect(() => {
    setorderhistory(orders);
  }, [orders]);
  return (
    <Fragment>
      {loading ? (
        <OrderHistoryLoader />
      ) : (
        <>
          {orderhistory && orderhistory.length > 0 ? (
            <div className="relative overflow-x-auto overflow-y-auto h-64 scrollbar-rounded scrollbar-thin scrollbar-thumb-gray-700 scrollbar-track-gray-300 border-2 border-gray-300 shadow-md rounded">
              <table className="w-full text-sm text-left text-gray-500">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-center">
                      {dic.t('Margaux-Order ID')}
                    </th>
                    <th scope="col" className="px-6 py-3 text-center">
                      {dic.t('Margaux-Order Date')}
                    </th>
                    <th scope="col" className="px-6 py-3 text-center">
                      {dic.t('Margaux-Price')}({dic.t('Margaux-AED')})
                    </th>
                    <th scope="col" className="px-6 py-3 text-center">
                      View
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {orderhistory.map((item, index) => {
                    return (
                      <tr key={index} className="bg-white border-b hover:bg-gray-50 ">
                        <th
                          scope="row"
                          className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap text-center"
                        >
                          {item.orderId}
                        </th>
                        <td className="px-6 py-4 text-center">
                          {moment(item.orderDate).format('DD-MM-YYYY')}
                        </td>
                        <td className="px-6 py-4 text-center">{item.totalPrice}</td>
                        <td className="px-4 py-3 text-center">
                          <button
                            className="text-sm font-semibold px-6 outline-none focus:outline-none ease-linear transition-all duration-150"
                            type="button"
                            onClick={() => {
                              setShowModal(true);
                              setSelectData(item);
                            }}
                          >
                            <AiOutlineEye size="18" />
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg p-4 border-2 broder-gray-400">
              <p className="text-lg text-gray-900 pb-5 text-center">
                {dic.t('Margaux-No Order History')}
              </p>
              <IconContext.Provider value={{ className: 'text-2xl mx-auto' }}>
                <TbShoppingCartX />
              </IconContext.Provider>
            </div>
          )}
        </>
      )}
      {showModal && <CustomerHistoryModal closeModal={closeModal} data={selectData} />}
    </Fragment>
  );
};

export default CustomerHistroy;
