export const CART_LIST = 'CART_LIST';
export const CART_LIST_STATUS_UPDATE = 'CART_LIST_STATUS_UPDATE';
import axios from 'axios';
import { MicroRoutes } from '../../constants';
import * as session from '../../helpers/sessionHandler';
import { encryptePayload } from '../../helpers/config';

export const onUserCartList = (data) => async (dispatch) => {
  const { userId, token } = session.LoggedUserDetails();
  const requestJSON = {
    userId: userId,
    userEmailId: 'test@gmail.com',
  };
  let paylaod = encryptePayload(requestJSON);
  await axios
    .post(MicroRoutes.CartUserId, paylaod, { headers: { token: `${token}` } })
    .then(async (response) => {
      if (response?.data?.errorStatus) {
      } else {
        localStorage.setItem('usercartid', response.data.cartId);
        dispatch({
          type: CART_LIST,
          existcartstatus: true,
          existcartdata: response.data.cartJson.data,
        });
      }
    })
    .catch((error) => {
      const show = JSON.stringify(error);
      const errorlist = JSON.parse(show);
    });
};

export const onUserCartStatusModify = (data) => async (dispatch) => {
  dispatch({
    type: CART_LIST_STATUS_UPDATE,
    existcartstatus: false,
    existcartdata: false,
  });
};
