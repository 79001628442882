import React, { Fragment, useState } from 'react';
import Main from './Main';
import LeftMenu from '../LeftMenu';
import ProgressBar from './ProgressBar';
import NavigationBar from './NavigationBar';

const CallCenter = (props) => {
  const [tab, setTabStatus] = useState(1);
  const [selectedRadio, setSelectedRadio] = useState(1);
  const updateRadio = (current) => {
    setSelectedRadio(current);
  };
  const updateTab = (current) => {
    setTabStatus(current);
  };
  const signatureboxnames = props?.fields?.['Pastry Names'].filter(
    (item) => item?.fields['Pastry Category']?.fields?.Title?.value === 'Signature'
  );
  const macroonNames = props?.fields?.['Pastry Names'].filter(
    (item) => item?.fields['Pastry Category']?.fields?.Title?.value === 'Macaroons'
  );
  const Regular = props?.fields?.['Regular Pastries'];
  const codes = props?.fields?.['Country Codes'];
  const Indiviual = props?.fields?.['Individual Pastries'];
  const Types = props?.fields?.['Pastry Types'];
  const Signature = props?.fields?.['Signature Boxes'];
  const Macroons = props?.fields?.['Macaroon Boxes'];
  const deliverycharges = props?.fields?.['Delivery Charges'];
  const leftmenu = props?.fields?.['Left Menus'];
  const deliveryTime = props?.fields?.['Delivery Time'];
  const pickupTime = props?.fields?.['Pickup Time'];
  const signatureName = signatureboxnames;
  const macaroonName = macroonNames;
  return (
    <Fragment>
      <div className="flex w-full bg-[#1d1d1d]">
        <LeftMenu selectOption={3} leftmenu={leftmenu} />
        <div className="w-[inherit] mt-14 bg-white">
          <ProgressBar tab={tab} updateTab={updateTab} />
          <Main
            tab={tab}
            updateTab={updateTab}
            Regular={Regular}
            Indiviual={Indiviual}
            Signature={Signature}
            Macroons={Macroons}
            Types={Types}
            codes={codes}
            deliverycharges={deliverycharges}
            deliveryTime={deliveryTime}
            pickupTime={pickupTime}
            signatureName={signatureName}
            macaroonName={macaroonName}
          />
          <NavigationBar tab={tab} updateTab={updateTab} />
        </div>
      </div>
    </Fragment>
  );
};

export default CallCenter;
