import React, { useEffect, useState } from 'react';
import { BsCheck2All } from 'react-icons/bs';
import { onBookPaymentStatus } from '../../actions/bookpayment.action';
import { onOrder } from '../../actions/order/order.action';
import { useDispatch, useSelector } from 'react-redux';
import * as helpers from '../../helpers/config';
import * as order from '../../helpers/order';
import { useToasts } from 'react-toast-notifications';
import { RiLoader5Line } from 'react-icons/ri';
import { onCartAction, onCartCount } from '../../actions/cart/addtocart.action';
import dic from 'i18next';
import ViewDetail from '../ViewDetail';
import { LazyLoadImage } from 'react-lazy-load-image-component';

function BookingConfirmation() {
  const cartproducts = localStorage.getItem('cartproducts');
  if (cartproducts !== null) {
    localStorage.setItem('getcartproduct', cartproducts);
  }
  const getorderproduct = JSON.parse(localStorage.getItem('getcartproduct'));
  if (getorderproduct !== null) {
    localStorage.removeItem('cartproducts');
  }
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [Status, setStaus] = useState('');
  const [OrderData, setOrderData] = useState(false);
  const params = new URLSearchParams(window.location.search);
  const payment_loading = useSelector((state) => state.bookpayment.payment_loading);
  const payment_message = useSelector((state) => state.bookpayment.payment_message);
  const orderloading = useSelector((state) => state.userorder.order_loading);
  const islogin = localStorage.getItem('islogin');
  const orderdetail = useSelector((state) => state.userorder.order_detail);
  const [modalStatus, setmodalStatus] = useState(false);
  const [getItem, setItem] = useState();
  const reference_id = params.get('ref');
  const addressresult = helpers.OrderAddress();
  const Loggeduser = helpers.LoggedUserDetails();
  const orderProductDetail = helpers.orderProductDetail();
  const GuestDetail = helpers.GuestDetail();
  const getSelectedAddress = helpers.getSelectedAddress();
  const addonpricelist = [];
  // Booking
  useEffect(() => {
    dispatch(onBookPaymentStatus(reference_id));
  }, [reference_id]);
  useEffect(() => {
    if (payment_message?._embedded?.payment[0].state === 'CAPTURED') {
      var deliveryCharge = payment_message.merchantDefinedData.deliveryCharge;
      const discount = payment_message.merchantDefinedData.discount;
      const couponCode = payment_message.merchantDefinedData.couponCode;
      var response = helpers.paymentBeforeValidate(deliveryCharge, discount);
      if (response.status === 'success') {
        var OrderData = {
          delivery: {
            address:
              response?.details?.deliveryOptions?.deliverytype === 'delivery'
                ? addressresult.deliveryaddress
                : {},
            deliverytype:
              response?.details?.deliveryOptions?.deliverytype === 'delivery'
                ? 'DELIVERY'
                : 'PICKUP',
            date: response?.details?.deliveryOptions?.deliverydate,
            time: response?.details?.deliveryOptions?.deliverytime,
          },
          discount: discount,
          couponCode: couponCode,
          remarks:
            response?.details?.deliveryOptions?.deliverytype === 'pickup'
              ? response?.details?.deliveryOptions?.remarks !== undefined
                ? response?.details?.deliveryOptions?.remarks
                : ''
              : '',
          region:
            response?.details?.deliveryOptions?.region !== undefined
              ? response?.details?.deliveryOptions?.region
              : '',
          charge: deliveryCharge,
          subtotal: response?.details?.subtotal,
          transaction_number: reference_id,
          deliveryOptions: response?.details?.deliveryOptions?.deliverytype,
          savedAddress: addressresult.savedAddress,
          orderProductDetail: orderProductDetail.order_product,
          total: response?.details?.total,
          Email: islogin === 'true' ? Loggeduser?.userEmailId : GuestDetail?.email,
          Phone: islogin === 'true' ? Loggeduser?.phoneNumber : GuestDetail?.phone,
          Name:
            islogin === 'true'
              ? Loggeduser?.firstName + ' ' + Loggeduser?.lastName
              : GuestDetail?.name,
          userId: islogin === 'true' ? Loggeduser?.userId : '',
        };
        const OrderObj =
          islogin === 'true'
            ? order.LoggedOrderDelivery(OrderData)
            : order.GuestOrderDelivery(OrderData);
        if (localStorage.getItem('order_Id') === null) {
          dispatch(onOrder(OrderObj,deliveryCharge, discount));
        }
        setStaus(true);
      } else {
        addToast(<p className="py-0.5">{dic.t('Margaux-Order Failed')}...!</p>, {
          appearance: 'error',
        });
        setStaus(!Status);
      }
    }
    setOrderData(OrderData);
  }, [payment_message]);
  useEffect(() => {
    if (orderloading === true) {
      localStorage.setItem('order_Id', orderdetail?.OrderId);
      setStaus(!Status);
    }
  }, [orderloading]);
  useEffect(() => {
    window !== 'undefined' && window.scroll(0, 500);
  }, []);
  return (
    <div className="container-fluid pb-10 bg-black ">
      <div className="sm:py-14"></div>
      {payment_loading === false && (
        <div className="py-20">
          <RiLoader5Line className="w-12 h-12 mx-auto text-gray-200 animate-spin fill-white" />
          <h4 className="text-white text-center text-xl pt-2">{dic.t('Margaux-Loading')}</h4>
          <p className="text-white text-center py-2">
            {dic.t('Margaux-This May Take a Few Seconds')}
          </p>
        </div>
      )}
      {payment_message?._embedded?.payment[0].state === 'CAPTURED' ? (
        localStorage.getItem('order_Id') === null ? (
          <div className="py-20">
            <RiLoader5Line className="w-12 h-12 mx-auto text-gray-200 animate-spin fill-white" />
            <h4 className="text-white text-center text-xl pt-2">{dic.t('Margaux-Loading')}</h4>
            <p className="text-white text-center py-2">
              {dic.t('Margaux-This May Take a Few Seconds')}
            </p>
          </div>
        ) : (
          <div className="lg:flex lg:flex-row ">
            <div className="lg:basis-3/4 lg:px-10 px-4 pt-8 lg:pt-0 lg:pl-[4rem]  border-white-500 ">
              <div className="main-accordition">
                <div className="sub-accordition">
                  <div className="accord-title tracking-widest text-black font-bold p-4 bg-white">
                    <BsCheck2All className="inline text-xl font-bold" />{' '}
                    {dic.t('Margaux-Order Confirmed')}
                  </div>
                  <div className="accord-description border border-white py-4 px-5">
                    <div className="grid grid-cols-1  md:grid-cols-1">
                      <div>
                        <div className="grid grid-cols-1  md:grid-cols-3 text-sm">
                          <div className="order-basic">
                            <p className="text-white py-0.5">
                              {dic.t('Margaux-Order ID')} :{' '}
                              {localStorage.getItem('order_Id') === null ? (
                                localStorage.getItem('order_Id') ? (
                                  localStorage.getItem('order_Id')
                                ) : (
                                  <>
                                    <RiLoader5Line className="w-3 h-3 text-gray-200 inline position-relative bottom-2 animate-spin fill-white" />
                                  </>
                                )
                              ) : (
                                localStorage.getItem('order_Id')
                              )}
                            </p>
                            <p className="text-white py-0.5">
                              {dic.t('Margaux-Order Date')} : {order.Orderdate()}
                            </p>
                            <p className="text-white py-0.5">
                              {dic.t('Margaux-Order Status')} : Placed
                            </p>
                          </div>
                          <div>
                            <p className="py-3 capitalize text-white tracking-widest">
                              Your order has been placed successfully.
                            </p>
                          </div>
                          <div className="order-delivery-address text-left sm:text-right">
                            {OrderData !== undefined && (
                              <div>
                                <p className="text-white">
                                  {dic.t('Margaux-Delivery Type')} :{' '}
                                  {OrderData?.delivery?.deliverytype === 'DELIVERY'
                                    ? 'Delivery'
                                    : 'Pickup'}
                                </p>
                              </div>
                            )}
                            {getSelectedAddress ? (
                              <div>
                                <p className="text-white">{dic.t('Margaux-Delivery Address')} : </p>
                                <p className="text-white">{getSelectedAddress}</p>
                              </div>
                            ) : (
                              <></>
                            )}

                            <div className="pt-2">
                              <p className="text-white ">
                                {OrderData?.delivery?.deliverytype === 'DELIVERY'
                                  ? 'Delivery'
                                  : 'Pickup'}{' '}
                                {dic.t('Margaux-Date and Time')} :
                              </p>
                              <p className="text-white">
                                {OrderData !== undefined &&
                                  OrderData?.delivery?.date + ' , ' + OrderData?.delivery?.time}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="grid grid-cols-1">
                      <div className="text-white pb-3 mt-3 text-sm ">
                        <h4 className="text-lg text-center py-2 underline underline-offset-1 ">
                          Cancellation Policy
                        </h4>
                        <p className="py-1">
                          Your order is very important to us, and we understand that sometimes,
                          unexpected events can occur.
                        </p>
                        <p className="py-1">
                          Cancellations made for 24-48hours or more in advance of the delivery or
                          pick up date will receive a 100% refund or a credit to your account for
                          your next order.
                        </p>
                        <p className="py-1">
                          Cancellations made less than 24 hours will not receive a refund nor a
                          credit to the next order.
                        </p>
                        <p>
                          Please call us at +97158 601 7474 between the hours of 9am to 8pm if you
                          need further assistance.
                        </p>
                      </div>
                    </div> */}
                    <div className="grid grid-cols-1 pt-5  md:grid-cols-1 gap-3 hidden sm:block">
                      <div className="">
                        {/* <div className="border-b border-white"></div> */}
                        <div className="overflow-x-auto sm:rounded-lg">
                          <table className="w-full   text-left text-white">
                            <thead className="md:text-sm text-xs text-white-700 uppercase bg-black">
                              <tr>
                                <th scope="col" className="px-6 py-3">
                                  {dic.t('Margaux-Product Image')}
                                </th>
                                <th scope="col" className="px-4 py-3">
                                  {dic.t('Margaux-Product Name')}
                                </th>
                                <th scope="col" className="px-4 py-3">
                                  {dic.t('Margaux-Quantity')}
                                </th>
                                <th scope="col" className="px-6 py-3">
                                  {dic.t('Margaux-Total Price')}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {getorderproduct?.map((item, keys) => {
                                // const title = item?.fields['Pastry Name']?.fields?.Title?.value;
                                // const images =
                                //   item?.fields['Pastry Images'][0]?.fields?.Image?.value?.src;
                                const title = item?.ItemDetails?.title;
                                const images = item?.ItemDetails?.image;

                                return item.cartaddedservice.map((serviceitem, getkey) => {
                                  addonpricelist.push(
                                    serviceitem?.addon?.Price !== undefined
                                      ? serviceitem?.addon?.Price
                                      : 0
                                  );
                                  const signatureBox = serviceitem && serviceitem.signatureBox;
                                  const macaroonBox = serviceitem && serviceitem.macaroonBox;
                                  const type = serviceitem && serviceitem.type;
                                  return (
                                    <tr
                                      className={'border-t  lg:text-base sm:text-md text-sm'}
                                      key={serviceitem.id}
                                    >
                                      <td scope="row" className="w-[80px]  sm:w-auto">
                                        <LazyLoadImage
                                          src={images}
                                          className="object-cover my-3 h-28 w-44"
                                        />
                                      </td>
                                      <td className="px-2 sm:px-6 sm:py-4">
                                        {title} <br /> {serviceitem.serves.value} <br />
                                        {serviceitem?.addon &&
                                          serviceitem?.addon?.Price !== (undefined || 0) && (
                                            <>
                                              {serviceitem?.addon?.addonName +
                                                ' ' +
                                                serviceitem?.addon?.Price +
                                                ' ' +
                                                serviceitem?.addon?.Currency}
                                              <br />
                                            </>
                                          )}
                                        {helpers.currency} &nbsp;
                                        {serviceitem.price.value} <br />
                                        {serviceitem?.message?.value}
                                        {signatureBox.length > 0 || macaroonBox.length ? (
                                          type === 'SIGNATUREBOX' ? (
                                            <span
                                              onClick={() => {
                                                setmodalStatus(!modalStatus);
                                                setItem(signatureBox);
                                              }}
                                              className="text-white text-xs cursor-pointer underline  underline-offset-1"
                                            >
                                              View Details
                                            </span>
                                          ) : (
                                            <span
                                              onClick={() => {
                                                setmodalStatus(!modalStatus);
                                                setItem(macaroonBox);
                                              }}
                                              className="text-white text-xs cursor-pointer underline  underline-offset-1"
                                            >
                                              View Details
                                            </span>
                                          )
                                        ) : (
                                          <></>
                                        )}
                                      </td>
                                      <td className="px-3 sm:px-12 sm:py-4">
                                        {serviceitem.quantity.value}
                                      </td>
                                      <td className="px-2 sm:px-12 sm:py-4">
                                        {parseInt(
                                          serviceitem.price.value * serviceitem.quantity.value
                                        ) +
                                          parseInt(
                                            serviceitem?.addon?.Price !== undefined
                                              ? serviceitem?.addon?.Price
                                              : 0
                                          )}
                                      </td>
                                    </tr>
                                  );
                                });
                              })}
                            </tbody>
                          </table>
                        </div>
                        <div className="order-confirm text-right lg:px-5"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <p>
                  Your order is very important to us, and we understand that sometimes, unexpected
                  events can occur.
                </p>
                <p>
                  Cancellations made for 24-48hours or more in advance of the delivery or pick up
                  date will receive a 100% refund or a credit to your account for your next order.
                </p>
                <p>
                  Cancellations made less than 24 hours will not receive a refund nor a credit to
                  the next order.
                </p>
                <p>
                  Please call us at +97158 601 7474 between the hours of 9am to 8pm if you need
                  further assistance.
                </p>
              </div>
            </div>
            {/* Small Device Started */}
            <div className="small-device block sm:hidden">
              <div className="pointer-events-auto w-screen max-w-md">
                <div className="flex h-full flex-col overflow-y-scroll bg-black shadow-xl">
                  <div className="flex-1 overflow-y-auto px-4 sm:px-6">
                    <div className="mt-8">
                      <div className="flow-root">
                        <ul role="list" className="-my-6 divide-y divide-gray-200">
                          {getorderproduct.map((item, keys) => {
                            const title = item?.ItemDetails?.title;
                            const images = item?.ItemDetails?.image;
                            const productkey = keys;
                            return item.cartaddedservice.map((serviceitem, getkey) => {
                              const serviceid = serviceitem?.id;
                              const message = serviceitem?.message?.value;
                              const price = serviceitem?.price?.value;
                              const serves = serviceitem?.serves?.value;
                              const quantity = serviceitem?.quantity?.value;
                              const servicekey = getkey;
                              const addon = serviceitem?.addon;
                              addonpricelist.push(
                                serviceitem?.addon?.Price !== undefined
                                  ? serviceitem?.addon?.Price
                                  : 0
                              );
                              const signatureBox = serviceitem && serviceitem.signatureBox;
                              const macaroonBox = serviceitem && serviceitem.macaroonBox;
                              const type = serviceitem && serviceitem.type;
                              return (
                                <li className="flex py-6" key={keys}>
                                  <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
                                    <LazyLoadImage
                                      src={images}
                                      alt="Product Image"
                                      className="h-full w-full object-cover object-center"
                                    />
                                  </div>

                                  <div className="ml-4 flex flex-1 flex-col">
                                    <div>
                                      <div className="flex justify-between text-sm font-medium">
                                        <p className="text-base">
                                          <a href="javascript:;" className="text-white">
                                            {title}
                                          </a>
                                        </p>
                                      </div>
                                      <p className="mt-1 text-sm text-white">
                                        {serves}
                                        <br />
                                        <span className="text-white">
                                          AED{' '}
                                          {parseInt(price * quantity) +
                                            parseInt(addon?.Price !== undefined ? addon?.Price : 0)}
                                        </span>
                                        <span> | Qty {quantity}</span>
                                        {serviceitem?.addon &&
                                          serviceitem?.addon?.Price !== undefined && (
                                            <>
                                              {serviceitem?.addon?.addonName +
                                                ' ' +
                                                serviceitem?.addon?.Price +
                                                ' ' +
                                                serviceitem?.addon?.Currency}
                                              <br />
                                            </>
                                          )}
                                        {message !== undefined ? (
                                          <>
                                            <br /> {message}
                                          </>
                                        ) : (
                                          ''
                                        )}
                                      </p>
                                      {signatureBox.length > 0 || macaroonBox.length ? (
                                        type === 'SIGNATUREBOX' ? (
                                          <span
                                            onClick={() => {
                                              setmodalStatus(!modalStatus);
                                              setItem(signatureBox);
                                            }}
                                            className="text-white text-xs cursor-pointer underline  underline-offset-1"
                                          >
                                            View Details
                                          </span>
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setmodalStatus(!modalStatus);
                                              setItem(macaroonBox);
                                            }}
                                            className="text-white text-xs cursor-pointer underline  underline-offset-1"
                                          >
                                            View Details
                                          </span>
                                        )
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  </div>
                                </li>
                              );
                            });
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Small Device End */}
            <div className="lg:basis-1/5  basis-9/12  lg:pt-0 px-4 lg:px-0 text-white lg:pl-5">
              <div className="lg:sticky lg:self-baseline lg:top-[6rem] lg:border lg:px-5">
                <h5 className="font-bold pt-2 pb-3 uppercase xl:text-lg sm:text-md text-md">
                  {dic.t('Margaux-Order Summary')}
                </h5>
                <p className="text-white text-xs">{dic.t('Margaux-Inclusive of Tax')}</p>
                <table className="w-full text-white">
                  <tbody>
                    <tr className="text-left lg:text-base sm:text-md text-sm">
                      <th className="text-left font-medium py-2">Subtotal</th>
                      <td className="text-center">
                        {parseInt(helpers.CartSubtotalSum(getorderproduct)) +
                          parseInt(helpers.ArraySum(addonpricelist))}
                      </td>
                    </tr>
                    {OrderData !== undefined && OrderData?.discount && (
                      <tr className="text-left lg:text-base sm:text-md text-sm">
                        <th className="text-left font-medium py-2">{dic.t('Discount')}</th>
                        <td className="text-center text-sm">{OrderData?.discount}</td>
                      </tr>
                    )}
                    {OrderData !== undefined ? (
                      <tr className="text-left lg:text-base sm:text-md text-sm">
                        <th className="text-left font-medium py-2">
                          {dic.t('Margaux-Delivery Charges')}
                        </th>
                        <td className="text-center">{OrderData.charge}</td>
                      </tr>
                    ) : (
                      <></>
                    )}

                    <tr className="text-left xl:text-lg sm:text-md text-md border-t-2 border-white-500">
                      <th className="text-left ">{dic.t('Margaux-Total Amount')}</th>
                      <td className="text-center pt-3 pb-2">
                        {OrderData !== undefined ? order.insertDecimal(OrderData.total) : 0}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )
      ) : (
        ''
      )}
      {payment_message?._embedded?.payment[0].state === 'FAILED' ? (
        <div className="lg:flex lg:flex-row ">
          <div className="lg:basis-full mb-20 lg:px-10 px-4 pt-8 lg:pt-0 lg:pl-[4rem]  border-white-500">
            <div className="main-accordition">
              <div className="sub-accordition">
                <div className="accord-title tracking-wider text-black font-bold p-4 bg-white">
                  <BsCheck2All className="inline text-xl font-bold" />{' '}
                  {dic.t('Margaux-Order Failed')}
                </div>
                <div className="accord-description border border-white py-4 px-5">
                  <div className="grid grid-cols-1  md:grid-cols-2">
                    <div>
                      <p className="text-white pb-1">
                        {dic.t('Margaux-Booking Failed Transaction')}.
                      </p>
                      <p className="text-white py-1">{dic.t('Margaux-Please try again later')}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      {modalStatus === true && (
        <ViewDetail modalStatus={modalStatus} setmodalStatus={setmodalStatus} getItem={getItem} />
      )}
    </div>
  );
}

export default BookingConfirmation;
