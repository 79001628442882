import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { onInvoicePayment } from '../../../actions/payment/payment.action';
import * as Helpers from '../../../helpers/AdminHelpers';
import * as helpers from '../../../helpers/config';
import { EndUserRoutes } from '../../../constants';
import dic from 'i18next';
import { onAdminOrder } from '../../../actions/admin/AdminOrder.action';
import OrderConfirmModal from '../OrderConfirmModal';
import SendLinkModal from './SendLinkModal';

const SummaryRight = () => {
  const dispatch = useDispatch();
  const loggeddetails = helpers.LoggedUserDetails();
  const { customerDeliveryInfo, customerInfo, cartItems } = useSelector(
    (state) => state.callcenter
  );
  const { invoice_loading, invoice_success_message, invoice_order_ref } = useSelector(
    (state) => state.payment
  );
  const [finalcartItems, setfinalcartItems] = useState(cartItems);
  const [confirmModal, setConfirmModal] = useState(false);
  const [sendLinkModal, setSendLinkModal] = useState(false);
  const [subTotalPrice, setSubTotalPrice] = useState(0);
  const closeConfirmModal = () => {
    setConfirmModal(false);
  };
  const closeSendLinkModal = () => {
    setSendLinkModal(false);
  };

  useEffect(() => {
    setfinalcartItems(cartItems);
  }, [cartItems]);
  useEffect(() => {
    let Price = 0;
    finalcartItems.map((item) => (Price = Price + item.totalPrice));
    setSubTotalPrice(Price);
  }, [finalcartItems]);
  useEffect(() => {
    if (invoice_order_ref !== '') {
      let orderPayload = Helpers.getInvoiceOrderPayload(
        customerDeliveryInfo,
        cartItems,
        customerInfo,
        subTotalPrice,
        loggeddetails,
        TotalPayment,
        invoice_order_ref
      );
      dispatch(onAdminOrder(orderPayload));
    }
  }, [invoice_order_ref]);
  let deliveryprice =
    customerDeliveryInfo.deliverytype === 'PICKUP'
      ? 0
      : customerDeliveryInfo.deliveryAddress.deliveryPrice;
  let TotalPayment = subTotalPrice + deliveryprice;
  let orderPayload = Helpers.getOrderPayload(
    customerDeliveryInfo,
    cartItems,
    customerInfo,
    subTotalPrice,
    loggeddetails,
    TotalPayment
  );
  const paymentPayload = Helpers.getPaymentPayload(
    TotalPayment,
    customerInfo,
    customerDeliveryInfo,
    EndUserRoutes.redirectUrl
  );
  const InvoicePayload = Helpers.getInvoicePayload(
    customerDeliveryInfo,
    cartItems,
    customerInfo,
    subTotalPrice,
    loggeddetails,
    TotalPayment,
    deliveryprice
  );
  const SaveOrder = () => {
    setConfirmModal(true);
    dispatch(onAdminOrder(orderPayload));
  };
  const SendLink = () => {
    dispatch(onInvoicePayment(InvoicePayload));
    setSendLinkModal(true);
  };
  return (
    <Fragment>
      <div className="col-span-12 lg:col-span-4 shadow-md border-2 px-4 py-2 border-gray-200 h-64 overflow-auto scrollbar-thin scrollbar-thumb-gray-700 scrollbar-thumb-full scrollbar-track-rounded-full scrollbar-track-gray-300">
        <div className="card-body">
          <p className="text-base font-bold pb-2 border-b-2 border-gray-400">
            {dic.t('Margaux-Order Summary')}
          </p>
          <div className="flex justify-between pt-2">
            <p className="text-base font-bold mr-2">{dic.t('Margaux-Sub Total')} </p>
            <p className="text-base">
              {dic.t('Margaux-AED')} {subTotalPrice}
            </p>
          </div>
          <div className="flex justify-between mb-3">
            <p className="text-base font-bold mr-2">{dic.t('Margaux-Delivery Charges')} </p>
            <p className="text-base">
              {dic.t('Margaux-AED')} {deliveryprice}
            </p>
          </div>
          <hr className="py-2" />
          <div className="flex justify-between mb-3">
            <p className="text-base font-bold mr-2">{dic.t('Margaux-Total Payment')} </p>
            <p className="text-base">
              {dic.t('Margaux-AED')} {TotalPayment}
            </p>
          </div>
        </div>
        <div className="col-span-4">
          <button
            type="submit"
            onClick={SaveOrder}
            className="text-white mt-2 bg-gray-700  hover:bg-gray-500 font-medium rounded-lg text-sm w-full px-2 py-1.5 text-center"
          >
            {dic.t('Margaux-Complete Order')}
          </button>
          <button
            type="submit"
            onClick={SendLink}
            className="text-white mt-2 bg-gray-700  hover:bg-gray-500 font-medium rounded-lg text-sm w-full px-2 py-1.5 text-center"
          >
            Send Payment Link
          </button>
        </div>
      </div>
      {confirmModal && <OrderConfirmModal closeModal={closeConfirmModal} />}
      {sendLinkModal && <SendLinkModal closeModal={closeSendLinkModal} />}
    </Fragment>
  );
};

export default SummaryRight;
