import { combineReducers } from 'redux';
import { userForgotPasswordReducer } from './forgotpassword.reducer';
import { userLoginReducer } from './login.reducer';
import { userRegistrationReducer } from './registration.reducer';
import { userCartReducer } from './addtocart.reducer';
import { userOrderFunction, userDeliveryChargeCost } from './order.reducer';
import { GuestReducer } from './guest.reducer';
import { userResetPasswordReducer } from './resetpassword.reducer';
import { allUserReducer } from './admin/UserList.reducer';
import { getDashboardReducer } from './admin/GetDashboard.reducer';
import { getAllRolesReducer } from './admin/GetAllRoles.reducer';
import { getAllOrdersReducer } from './admin/GetAllOrders.reducer';
import { filterOrdersReducer } from './admin/FilterOrders.reducer';
import { userCartListReducer } from './cartlist.reducer';
import { onPaymentReducer } from './payment.reducer';
import { onBookPaymentReducer } from './bookpayment.reducer';
import { getOrderHistoryReducer } from './admin/UserHistory.reducer';
import { callCenterReducer } from './admin/CallCenter.reducer';
import { userContactUs } from './contactus.reducer';
import { saveAdminOrder } from './admin/AdminOrder.reducer';

export default combineReducers({
  registration: userRegistrationReducer,
  login: userLoginReducer,
  forgotpassword: userForgotPasswordReducer,
  usercart: userCartReducer,
  userorder: userOrderFunction,
  guest: GuestReducer,
  resetpassword: userResetPasswordReducer,
  deliverycharge: userDeliveryChargeCost,
  cartlist: userCartListReducer,
  contactus: userContactUs,
  // Admin Reducers
  allusers: allUserReducer,
  dashboard: getDashboardReducer,
  allorders: getAllOrdersReducer,
  allroles: getAllRolesReducer,
  filterorders: filterOrdersReducer,
  deliverycharge: userDeliveryChargeCost,
  cartlist: userCartListReducer,
  payment: onPaymentReducer,
  bookpayment: onBookPaymentReducer,
  orderhistory: getOrderHistoryReducer,
  callcenter: callCenterReducer,
  adminorder: saveAdminOrder,
});
