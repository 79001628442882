import axios from 'axios';
import { AdminMicroRoutes } from '../../constants';
import * as helpers from '../../helpers/config';

export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS';
export const USER_LIST_FAILURE = 'USER_LIST_FAILURE';
export const USER_LIST_LOADING = 'USER_LIST_LOADING';

export const onUserList = (data) => async (dispatch) => {
  dispatch({ type: USER_LIST_LOADING, payload: true });
  const loggeddetails = helpers.LoggedUserDetails();
  await axios
    .post(AdminMicroRoutes.getalluser, data, {
      headers: {
        token: loggeddetails?.token,
      },
    })
    .then((response) => {
      console.log('response', response);
      if (response.status === 200) {
        dispatch({
          type: USER_LIST_SUCCESS,
          payload: response.data.users,
        });
      } else {
        dispatch({
          type: USER_LIST_FAILURE,
          payload: response.data.status,
        });
      }
    });
};
