export const getGuestStatus = () => {
  return localStorage.getItem('guestLoggedIn');
};

export const GuestDetails = (item) => {
  return localStorage.setItem('GuestDetail', item);
};

const LoggedUserToken = () => {
  return localStorage.getItem('tokenId');
};

export const GetGuestDetails = () => {
  const sessionGuestDetails = localStorage.getItem('GuestDetail');
  const sessionrefer = JSON.parse(sessionGuestDetails);
  return sessionrefer;
};

export const SessionStore = (keyname, response) => {
  return localStorage.setItem(keyname, response);
};

export const JsonStringfySession = (keyname, response) => {
  return localStorage.setItem(keyname, JSON.stringify(response));
};

export const GuestSessionRemove = () => {
  localStorage.setItem('guestLoggedIn', false);
  localStorage.removeItem('GuestDetail');
};

export const UserLogout = () => {
  localStorage.removeItem('tokenId');
  localStorage.removeItem('islogin');
  localStorage.removeItem('LoggedUserDetails');
};

export const LoggedUserDetails = () => {
  const getdetail = localStorage.getItem('LoggedUserDetails');
  const response = JSON.parse(getdetail);
  return response;
};

export const CompleteUserLogout = () => {
  const emprtyarray = [];
  localStorage.setItem('islogin', false);
  localStorage.removeItem('tokenId');
  localStorage.setItem('cartproducts', JSON.stringify(emprtyarray));
  localStorage.removeItem('usercartid');
  localStorage.removeItem('LoggedUserDetails');
  localStorage.setItem('guestLoggedIn', false);
};

export const getLoginStatus = () => {
  return localStorage.getItem('islogin');
};
