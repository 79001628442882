import React, { Fragment } from 'react';
import { AiFillCloseCircle } from 'react-icons/ai';
import { MdOutlineDeliveryDining, MdOutlineDoneOutline } from 'react-icons/md';
import { GiCampCookingPot } from 'react-icons/gi';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../Loader/Loader';
import { useToasts } from 'react-toast-notifications';
import { onupdateorder, revertOrderStatus } from '../../../actions/admin/UpdateOrder.action';
import dic from 'i18next';
import * as helpers from '../../../helpers/config';
import { useEffect } from 'react';

const ConfirmationModal = ({ closeModal, confirmModalType, item }) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { updateloading, statusCode, updateError, successStatus } = useSelector(
    (state) => state.allorders
  );
  const loggeddetails = helpers.LoggedUserDetails();
  const userEmailId = loggeddetails?.userEmailId;
  const sendupdateuser = () => {
    const sendupdateuser = {
      orderId: item.orderId,
      orderStatus: confirmModalType,
      userDTO: {
        userEmail: userEmailId,
      },
    };
    dispatch(onupdateorder(sendupdateuser)).then(() => closeModal());
  };
  useEffect(() => {
    if (successStatus === true) {
      addToast(<p className="py-0.5">{dic.t('Margaux-Order Status Changed Successfully')}</p>, {
        appearance: 'success',
      });
      dispatch(revertOrderStatus());
    }
  }, [successStatus]);

  console.log('successStatus === true', successStatus);
  return (
    <Fragment>
      <div className=" fixed z-10 overflow-y-auto top-0 w-full left-0">
        <div className="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-black opacity-75" />
          </div>
          <span className="flex h-screen justify-center items-center sm:inline-block sm:align-middle sm:h-screen"></span>
          <div
            className="inline-block align-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="bg-gray-100 shadow-md rounded p-4 w-full">
              <div className="p-6 text-center">
                {updateloading ? (
                  <Loader />
                ) : (
                  <>
                    {confirmModalType === 'COOKING' && (
                      <>
                        <GiCampCookingPot className="mx-auto mb-4 text-gray-400 w-14 h-14" />
                        <h3 className="mb-5 text-lg font-normal text-gray-500">
                          {dic.t('Margaux-Prepare this Order')}?
                        </h3>
                      </>
                    )}
                    {confirmModalType === 'DISPATCH' && (
                      <>
                        <MdOutlineDeliveryDining className="mx-auto mb-4 text-gray-400 w-14 h-14" />
                        <h3 className="mb-5 text-lg font-normal text-gray-500">
                          {dic.t('Margaux-Dispatch this Order')} ?
                        </h3>
                      </>
                    )}
                    {confirmModalType === 'DELIVERED' && (
                      <>
                        <MdOutlineDoneOutline className="mx-auto mb-4 text-gray-400 w-14 h-14" />
                        <h3 className="mb-5 text-lg font-normal text-gray-500">
                          {dic.t('Margaux-Delivered this Order')}?
                        </h3>
                      </>
                    )}
                    <button
                      data-modal-toggle="popup-modal"
                      type="button"
                      onClick={() => {
                        sendupdateuser();
                      }}
                      className="text-white bg-gray-700 hover:bg-gray-500 hover:text-gray-300 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                    >
                      {dic.t('Margaux-I am sure')}
                    </button>
                    <button
                      data-modal-toggle="popup-modal"
                      type="button"
                      onClick={() => {
                        closeModal();
                      }}
                      className="text-white bg-gray-700 hover:bg-gray-500 hover:text-gray-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5"
                    >
                      {dic.t('Margaux-No Cancel')}
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ConfirmationModal;
