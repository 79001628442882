import React, { useEffect, useState } from 'react';
import * as helpers from '../../helpers/config';
import * as session from '../../helpers/sessionHandler';
import { useSelector, useDispatch } from 'react-redux';
import { onOrder } from '../../actions/order/order.action';
import { onPayment, onPaymentStatusRevert } from '../../actions/payment/payment.action';
import { BsShieldCheck, BsCheck2Circle, BsCheck2All } from 'react-icons/bs';
import { useToasts } from 'react-toast-notifications';
import { EndUserRoutes } from '../../constants';
import dic from 'i18next';
import NIPaymentModel from './NIPaymentModel';
import NiPaymentFailModel from './NIPaymentFailModel';

export default function Payment({ CouponCode, Discount }) {
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const order_loading = useSelector((state) => state.userorder.order_loading);
  const [Login, SetLogin] = useState(false);
  const [sessionRefreshCount, setsessionRefreshCount] = useState(0);
  const [paynowstate, setpaynowstate] = useState(false);
  const toggleModal = () => {
    document.getElementById('modal').classList.toggle('hidden');
  };
  const login_status = helpers.CurrentLoginStatus();
  const islogin = localStorage.getItem('islogin');
  const cart = localStorage.getItem('cartproducts');
  const jsoncart = JSON.parse(cart);
  const [cartItems, setCartItems] = useState(jsoncart || []);
  const gueststatus_redux = useSelector((state) => state.guest.status);
  const guestdetails_redux = useSelector((state) => state.guest.details);
  const GuestDetails = session.GetGuestDetails();
  const GuestStatus = localStorage.getItem('guestLoggedIn');
  const deliverycharge = useSelector((state) => state.deliverycharge.ordercharge);
  const { payment_loading, payment_message, payment_error, nipayment_loading , nipayment_fail } = useSelector((state) => state.payment);
  const orderAction = () => {
    addToast('Order has been Intialized', { appearance: 'info' });
    dispatch(onOrder());
    SetOrders(false);
  };
  const paymentAction = () => {
    if (cartItems?.length === 0) {
      addToast('Your cart is empty', { appearance: 'info' });
      return false;
    }
    const response = helpers.paymentBeforeValidate(deliverycharge, Discount);
    if (response.status === 'success') {
      const cartproducts = localStorage && localStorage.getItem('cartproducts');
      const getValidCartList = JSON.parse(cartproducts);
      const createPaymentObject = {
        action: 'SALE',
        amount: {
          currencyCode: 'AED',
          value: response?.details?.total,
        },
        emailAddress: response?.details?.Loggeduser?.userEmailId,
        language: 'en',
        billingAddress: {
          firstName: response?.details?.Loggeduser?.firstName,
          lastName: response?.details?.Loggeduser?.lastName,
          address1:
            response?.details?.deliveryOptions?.deliverytype === 'delivery'
              ? response?.details?.address
              : '',
        },
        merchantAttributes: {
          redirectUrl: EndUserRoutes.redirectUrl,
          skipConfirmationPage: true,
        },
        merchantDefinedData: {
          deliveryCharge: deliverycharge,
          discount: Discount,
          couponCode: CouponCode,
        },
      };
      addToast('Order has been Intiaized', { appearance: 'info' });
      dispatch(onPayment(createPaymentObject,getValidCartList,deliverycharge, Discount));
    } else {
      addToast(<p className="py-0.5">{response?.description}</p>, {
        appearance: 'error',
      });
    }
  };
  const orderGuestAction = () => {
    addToast(<p className="py-0.5">{dic.t('Margaux-Guest account order disabled')}</p>, {
      appearance: 'error',
    });
  };
  useEffect(() => {
    if (payment_message !== false) {
      addToast(<p className="py-0.5">{payment_message}</p>, {
        appearance: 'error',
      });
      dispatch(onPaymentStatusRevert());
    }
  }, [payment_message]);
  useEffect(() => {
    if (sessionRefreshCount === 0) {
      localStorage.removeItem('NewDeliveryAddress');
      localStorage.removeItem('NewDeliveryAddressExtra');
      localStorage.removeItem('selectAddress');
      setsessionRefreshCount(1);
    }
  }, [sessionRefreshCount]);
  useEffect(() => {
    if (login_status === 200) {
      SetLogin(!Login);
    } else {
      if (islogin === 'true') {
        SetLogin(!Login);
      }
    }
  }, [login_status, islogin]);

  const OnAcceptClick = (e) => {
    setpaynowstate(e.target.checked);
  };
  return (
    <div className="sub-accordition my-3">
      <div className="accord-title tracking-wider text-black font-bold p-4 bg-white">
        <span className="bg-black text-white text-xs font-semibold mr-2 px-2.5 py-1 rounded">
          5
        </span>
        PAYMENT{' '}
        {login_status === 200 ||
          islogin === 'true' ||
          (GuestStatus === 'true' && (
            <>
              <BsCheck2All className="inline text-xl font-bold" />
            </>
          ))}
      </div>
      {login_status === 200 || islogin === 'true' || GuestStatus === 'true' ? (
        <div className="accord-description border border-white py-4 px-5">
        {
              nipayment_loading && <NIPaymentModel/>
          }
          {
              nipayment_fail && <NiPaymentFailModel/>
          }
          <div className="grid grid-cols-1  md:grid-cols-1 py-1 gap-3">
            <div className="shield-description">
              <div className="grid grid-cols-1 md:grid-cols-1 gap-2">
                <div className=" sm:text-left">
                  <div className="flex">
                    <div className="flex items-center h-5">
                      <input
                        id="helper-checkbox"
                        aria-describedby="helper-checkbox-text"
                        type="checkbox"
                        value=""
                        onClick={OnAcceptClick}
                        className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300"
                      />
                    </div>
                    <div className="ml-2 text-sm text-white">
                      <label htmlFor="helper-checkbox" className="font-medium">
                        I agree with the{' '}
                        <a
                          href="https://www.jumeirah.com/en/jumeirah-group/terms-and-conditions"
                          className="text-gray-400 underline"
                        >
                          terms & conditions
                        </a>
                      </label>
                      <p id="helper-checkbox-text" className="text-xs font-normal">
                        {dic.t('Margaux-Secure Payment Partner')}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-white sm:text-right">
              <button
                disabled={!paynowstate}
                onClick={() => paymentAction()}
                className=" text-white w-36 text-center bg-gray-700 font-bold rounded-lg text-sm px-5 py-2  mb-2 tracking-wider	"
              >
                {dic.t('Margaux-Pay Now')}
              </button>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
}
