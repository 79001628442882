import React, { Fragment, useEffect, useState } from 'react';
import { IconContext } from 'react-icons';
import { MdDataSaverOn } from 'react-icons/md';
import { HiOutlineOfficeBuilding } from 'react-icons/hi';
import { FiEdit } from 'react-icons/fi';
import NewAddressForm from './NewAddress';
import { useDispatch, useSelector } from 'react-redux';
import EditAddress from './EditAddress';
import dic from 'i18next';

const DeliveryType = ({ deliverycharges, setdptime, dptime }) => {
  const { deliveryAddress } = useSelector((state) => state.callcenter.customerDeliveryInfo);
  const [selectedAddress, setSelectedAddress] = useState(deliveryAddress);
  // Add New modal & Edit User Modal
  const [editModal, setEditModal] = useState(false);
  const [AddModal, setAddModel] = useState(false);
  useEffect(() => {
    setSelectedAddress(deliveryAddress);
  }, [deliveryAddress]);
  // making region list for user
  let regionlist = [];
  deliverycharges.map((item) => {
    let price = item?.fields?.Price?.value;
    let label = item?.fields?.Region?.fields?.Region?.value;
    let Timings = item?.fields?.Region?.fields?.Timings;
    let DeliveryTime = Timings[0]?.fields?.Title?.value;
    regionlist.push({
      label: label,
      // value: {
      //   deliverycharge: price,
      //   deliverytime: DeliveryTime,
      //   DeliveryLabel: label,
      // },
      value: label,
    });
  });
  return (
    <Fragment>
      <div className="grid grid-cols-1 py-4">
        <div className="flex justify-end">
          <IconContext.Provider
            value={{
              className: 'text-2xl hover:cursor-pointer text-gray-800',
            }}
          >
            {Object.keys(selectedAddress).length > 0 ? (
              <FiEdit onClick={() => setEditModal(true)} />
            ) : (
              <MdDataSaverOn onClick={() => setAddModel(true)} />
            )}
          </IconContext.Provider>
        </div>
      </div>
      {Object.keys(selectedAddress).length > 0 && (
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
          <div className="border-2 border-gray-500 rounded-md shadow-md p-2">
            <figcaption className="flex  items-center space-x-3">
              <HiOutlineOfficeBuilding className={'text-2xl text-gray-800'} />
              <div className="space-y-0.5 font-medium text-left ml-2">
                <div>
                  {selectedAddress.makani !== '' && (
                    <span className="mr-2">{selectedAddress.makani}</span>
                  )}
                  {selectedAddress.address}
                </div>
                <div className="text-sm font-light text-gray-500">
                  {selectedAddress.region}
                  <span className="ml-2">
                    ( {dic.t('Margaux-AED')} {selectedAddress.deliveryPrice} )
                  </span>
                </div>
                {dptime === '12:00pm - 03:00pm' ? (
                  <></>
                ) : (
                  <div className="text-sm font-light text-gray-500">{dptime}</div>
                )}
              </div>
            </figcaption>
          </div>
        </div>
      )}
      {AddModal && (
        <NewAddressForm
          setAddModel={setAddModel}
          deliverycharges={deliverycharges}
          regionlist={regionlist}
          setdptime={setdptime}
        />
      )}
      {editModal && (
        <EditAddress
          deliverycharges={deliverycharges}
          setEditModal={setEditModal}
          regionlist={regionlist}
          setdptime={setdptime}
        />
      )}
    </Fragment>
  );
};

export default DeliveryType;
