/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react';
import * as helpers from '../../helpers/config';
import { BsXCircleFill } from 'react-icons/bs';
import Info from '../CommonIcon/Info';
import dic from 'i18next';

function ProductService(props) {
  const { modalStatus, servicelist, productAddons } = props;
  const [getServes, setServes] = useState();
  const [getCheckRadion, setCheckRadio] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const [Addon, setAddon] = useState('');
  const service = servicelist?.fields?.Services;
  const checkRadionValidate = (serviceid) => {
    setShowMessage(false);
    setCheckRadio(serviceid);
  };
  const AddingAddion = (name, price, currency) => {
    const list = {
      addonName: name,
      Price: Number(price),
      Currency: currency,
    };
    setAddon(list);
  };
  const closeModal = () => {
    setShowMessage(false);
    props.closeModal();
    document.getElementById('message').value = '';
  };
  const callingCartTrigger = () => {
    if (getCheckRadion === '') {
      setShowMessage(true);
      return false;
    }
    const serviceid = getCheckRadion;
    const message = document.querySelector('.messagebox').value;
    const price = document.querySelector('.service-checkbox:checked').id;
    const serves = getServes;
    props.onCartFunction(
      servicelist,
      serviceid,
      message,
      price,
      serves,
      Addon,
      '',
      'plus',
      'customize'
    );
    setCheckRadio('');
    document.getElementById('message').value = '';
  };
  return (
    <div>
      <div
        className={`fixed z-20 overflow-y-auto top-0 w-full left-0 ${
          modalStatus === true ? '' : 'hidden'
        }`}
        id="servicemodal"
      >
        <div className="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-black opacity-75" />
          </div>
          <span className="flex h-screen justify-center items-center sm:inline-block sm:align-middle sm:h-screen">
            &#8203;
          </span>
          <div
            className="inline-block  align-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-md  w-full"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="bg-white sm:pb-4">
              {/* Body Start */}
              <div className="modal-body">
                <h4 className="text-base sm:text-lg text-center bg-gray-700 font-semibold tracking-wider py-3">
                  {dic.t('Margaux-Customize Your Order')}
                </h4>
                <div className="position-relative">
                  <BsXCircleFill
                    onClick={() => closeModal()}
                    className="absolute right-[17px] top-[19px] cursor-pointer text-lg"
                  />
                </div>
                <div className="text-black px-5 pt-2">
                  <table className="table-auto w-full mb-3 ">
                    <thead className="text-base border-b-2 border-gray-300  font-bold">
                      <tr>
                        <th className="pb-2 px-3 text-sm sm:text-base">{props && props.type}</th>
                        <th className="pb-2 text-sm sm:text-base">{dic.t('Margaux-Price')}</th>
                        <th className="pb-2"></th>
                      </tr>
                    </thead>
                    <tbody className="text-sm">
                      {service &&
                        service.map((serviceitem, keys) => {
                          const servicetopeople =
                            serviceitem &&
                            serviceitem.fields['Service To Persons'] &&
                            serviceitem.fields['Service To Persons'].fields &&
                            serviceitem.fields['Service To Persons'].fields.Title &&
                            serviceitem.fields['Service To Persons'].fields.Title.value;
                          const price =
                            serviceitem && serviceitem.fields && serviceitem.fields.Price.value;
                          const serviceid = serviceitem.id;
                          return (
                            <tr key={keys}>
                              <td className="pt-3 px-3 servicetopeople" id={servicetopeople}>
                                {servicetopeople}
                              </td>
                              <td className="">
                                {helpers.currency} {price}
                              </td>
                              <td className="">
                                <div className="flex items-center">
                                  <input
                                    value={serviceid}
                                    id={price}
                                    type="radio"
                                    name="radioList"
                                    onChange={() => setServes(servicetopeople)}
                                    onClick={() => checkRadionValidate(serviceid)}
                                    className="w-4 h-4   service-checkbox cursor-pointer"
                                  />
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                  {showMessage === true && (
                    <div
                      className="flex p-4 mb-1 text-sm text-yellow-700 bg-yellow-100 rounded-lg"
                      role="alert"
                    >
                      <Info />

                      <div className="inline">
                        <span className="font-medium pt-3 px-2">
                          {dic.t('Margaux-Select a serve from the serves list')}
                        </span>
                      </div>
                    </div>
                  )}

                  {productAddons.map((item, keys) => {
                    const charges = item?.fields?.Charges;
                    return (
                      <div className="addons px-3">
                        <div className="each-addon-title font-bold border-b-2 pb-1">
                          <p>{item?.fields?.Title?.value}</p>
                        </div>
                        <div className="each-addon-price pb-2">
                          <div className="grid grid-cols-4 gap-1  sm:gap-4 pt-2">
                            {charges?.map((chargeitem, keyvalue) => {
                              return (
                                <div className="text-xs py-1 sm:py-0 sm:text-sm" key={keyvalue}>
                                  <input
                                    onClick={() =>
                                      AddingAddion(
                                        item?.fields?.Title?.value,
                                        chargeitem?.fields?.Price?.value,
                                        chargeitem?.fields?.Currency?.fields['Currency Code'].value
                                      )
                                    }
                                    type="radio"
                                    name="addon"
                                    className="w-4 h-4   relative top-0.5 service-checkbox cursor-pointer"
                                  />{' '}
                                  {chargeitem?.fields?.Price?.value}{' '}
                                  {chargeitem?.fields?.Currency?.fields['Currency Code'].value}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    );
                  })}

                  <div className="message-box px-2 pt-1">
                    <textarea
                      id="message"
                      rows="2"
                      maxLength={50}
                      className="block p-2.5 messagebox capitalize placeholder:tracking-widest w-full text-sm text-black bg-gray-50 rounded-lg border border-gray-500 outline-none"
                      placeholder={dic.t('Margaux-Message To Put On The Pastry')}
                    ></textarea>
                  </div>

                  <div className="pt-3 pb-5 sm:pb-0 text-base  text-center">
                    <button
                      onClick={() => callingCartTrigger(servicelist)}
                      href="#"
                      className={
                        'text-white  bg-gray-700 font-bold   tracking-wider  lg:mr-1 rounded-lg text-sm px-5 sm:mr-1 mr-2 sm:px-6 py-2 text-center'
                      }
                    >
                      {dic.t('Margaux-Add')}
                    </button>
                  </div>
                </div>
              </div>
              {/* Body End */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ProductService;
