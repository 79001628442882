import axios from 'axios';
import { MicroRoutes } from '../../constants';
import * as config from '../../helpers/config';
export const PAYMENT_STATUS = 'PAYMENT_STATUS';
export const INVOICE_CREATE_SUCCESS = 'INVOICE_CREATE_SUCCESS';
export const INVOICE_CREATE_LOADING = 'INVOICE_CREATE_LOADING';
export const INVOICE_CREATE_FAIL = 'INVOICE_CREATE_FAIL';
export const NIPAYMENT_CREATE_SUCCESS = 'NIPAYMENT_CREATE_SUCCESS';
export const NIPAYMENT_CREATE_FAIL = 'NIPAYMENT_CREATE_FAIL';
export const NIPAYMENT_CREATE_LOADING = 'NIPAYMENT_CREATE_LOADING';

export const onPayment = (data,cartproducts,deliverycharge, Discount) => async (dispatch) => {
  dispatch({ type: NIPAYMENT_CREATE_LOADING, payload: true });
  const getPaymentObject = {'data':data,'cartproducts':cartproducts, 'deliverycharge' : deliverycharge , 'Discount' : Discount };
  let encryptPayload = config.encryptePayload(getPaymentObject);
  await axios
    .post(MicroRoutes.paymentorder, encryptPayload)
    .then((response) => {
      dispatch({ type: NIPAYMENT_CREATE_LOADING, payload: false });
      window.location.href = response.data._links.payment.href;
    })
    .catch((error) => {
      dispatch({ type: NIPAYMENT_CREATE_LOADING, payload: false });
      return dispatch({
        type: PAYMENT_STATUS,
        loading: false,
        message: 'Payment gatway server down...!',
      });
    });
};

export const onInvoicePayment = (data) => async (dispatch) => {
  dispatch({ type: INVOICE_CREATE_LOADING, payload: true });
  await axios
    .post(MicroRoutes.createInvoiceOrder, data)
    .then((response) => {
      console.log('response', response);
      dispatch({ type: INVOICE_CREATE_LOADING, payload: false });
      response.status === 201
        ? dispatch({
            type: INVOICE_CREATE_SUCCESS,
            payload: {
              message: 'Invoice Created',
              orderReference: response?.data?.orderReference,
            },
          })
        : dispatch({
            type: INVOICE_CREATE_FAIL,
            payload: response?.data?.errors[0]?.localizedMessage,
          });
      return response?.data?.orderReference;
    })
    .catch((error) => {
      dispatch({ type: INVOICE_CREATE_LOADING, payload: false });
      dispatch({
        type: INVOICE_CREATE_FAIL,
        payload: error?.[0]?.localizedMessage,
      });
      console.log('error', error);
    });
};

export const onPaymentStatusRevert = (data) => async (dispatch) => {
  dispatch({
    type: PAYMENT_STATUS,
    message: false,
  });
};
