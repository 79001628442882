import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { onUserList } from '../../actions/admin/Userlist.action';
import { getDadshboard } from '../../actions/admin/GetDashboard.action';
import * as helpers from '../../helpers/config';
import CustomerTable from './CustomerTable';
import SalesGraph from './SalesGraph';
import Detailes from './Detailes';

const Main = () => {
  const dispatch = useDispatch();
  const { loading, dashBoardDetails, error } = useSelector((state) => state.dashboard);
  // taking logged user data from storage
  const loggeddetails = helpers.LoggedUserDetails();
  const usertype = loggeddetails?.accessDetails?.role;
  const userEmailId = loggeddetails?.userEmailId;
  const userId = loggeddetails?.userId;
  useEffect(() => {
    const userlistdata = {
      userEmailId: userEmailId,
      userType: 'END_USER',
    };
    dispatch(onUserList(userlistdata));
    const dashboarddata = {
      userEmailId: userEmailId,
      userId: userId,
    };
    dispatch(getDadshboard(dashboarddata));
  }, []);
  return (
    <Fragment>
      <main className="w-[inherit] mt-14 bg-white">
        <div className="grid px-6 mx-auto">
          <div className="grid grid-cols-3 sm:grid-cols-6 lg:grid-cols-12 mt-4">
            <div className="col-span-6">
              {loading ? (
                <>
                  <div className="rounded h-56 shadow-md animate-pulse bg-gray-300" />
                </>
              ) : (
                <SalesGraph dashBoardDetails={dashBoardDetails} />
              )}
            </div>
            <div className="col-span-6">
              <Detailes loading={loading} dashBoardDetails={dashBoardDetails} />
            </div>
          </div>
          <div className="w-full xl:w-12/12 mb-12 xl:mb-0">
            <CustomerTable />
          </div>
        </div>
      </main>
    </Fragment>
  );
};

export default Main;
