import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import TableLoader from '../Admin/TableLoader';
import dic from 'i18next';

const CustomerTable = () => {
  const { loading, users, error } = useSelector((state) => state.allusers);
  const [items, setItems] = useState(users);
  const [pageCount, setPageCount] = useState(0);
  const [currentItems, setCurrentItems] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 10;

  useEffect(() => {
    setItems(users);
  }, [users]);
  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(items && items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items && items.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, items]);

  useEffect(() => {
    setItemOffset(0);
    // document.querySelector('page-item.selected').style.background = 'red';
  }, [items]);
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    setItemOffset(newOffset);
  };
  return (
    <Fragment>
      <div className="pb-4 md:m-0">
        {loading ? (
          <>
            <TableLoader />
          </>
        ) : (
          <Fragment>
            <div className="breadcrumb flex items-center">
              {/* <p className="text-xl mr-1 font-semibold">{dic.t('Margaux-Customer List')}</p> */}
            </div>
            <div className="container grid mx-auto mt-4">
              <div
                className={'w-full overflow-hidden rounded-lg shadow-md border-2 border-gray-300'}
              >
                <div className="w-full overflow-x-auto">
                  <table className="w-full whitespace-no-wrap">
                    <thead className="text-xs font-semibold tracking-wide text-left text-gray-500 capitalize border-b bg-gray-50">
                      <tr>
                        {/* <td className="px-4 py-3">Customer ID</td> */}
                        <td className="border px-4 py-3 tracking-widest">
                          {dic.t('Margaux-Customer Name')}
                        </td>
                        {/* <td className="px-4 py-3 text-center">Phone</td> */}
                        <td className="border px-4 py-3 tracking-widest">Customer Email</td>
                        <td className="border px-4 py-3 tracking-widest">Marketing Consent</td>
                        <td className="border px-4 py-3 tracking-widest">Consent</td>
                        <td className="border px-4 py-3 tracking-widest">Created Date</td>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y  text-gray-700 ">
                      {currentItems &&
                        currentItems.map((item, key) => {
                          return (
                            <tr key={key}>
                              {/* <td className="px-4 py-3">
                                <p className="font-semibold text-black">{item.userId}</p>
                              </td> */}
                              <td className="border px-4 py-3">
                                <p className="text-sm font-semibold text-black">{`${item.firstName} ${item.lastName}`}</p>
                              </td>
                              {/* <td className="px-4 py-3 text-center">
                    <p className="text-sm font-semibold text-black">{item.phone}</p>
                  </td> */}
                              <td className="border px-4 py-3">
                                <p className="text-sm font-semibold text-black">{item.emailId}</p>
                              </td>
                              <td className="border px-4 py-3">
                                <p className="text-sm font-semibold text-black">
                                  {item.marketingConsent ? 'Yes' : 'No'}
                                </p>
                              </td>
                              <td className="border px-4 py-3">
                                <p className="text-sm font-semibold text-black">
                                  {item.consent ? 'Yes' : 'No'}
                                </p>
                              </td>
                              <td className="border px-4 py-3">
                                <p className="text-sm font-semibold text-black">
                                  {moment(item.createdDate).format('YYYY-MM-DD')}
                                </p>
                              </td>
                              {/* <td className="px-4 py-3 content-center">
                    {(() => {
                      if (item.active) {
                        return (
                          <span className="inline-flex mx-auto px-2 text-xs font-medium leading-5 rounded-full  text-green-700 bg-green-300">
                            Active
                          </span>
                        );
                      } else {
                        return (
                          <span className="inline-flex mx-auto px-2 text-xs font-medium leading-5 rounded-full text-red-700 bg-red-300">
                            Inactive
                          </span>
                        );
                      }
                    })()}
                  </td> */}
                              {/* <td className="px-4 py-3">
                      <span className="font-semibold inline-flex py-1 px-1 uppercase rounded text-stone-600">
                        {(() => {
                          if (item.orderstatus === 'Cooking') {
                            return <GiCampCookingPot size="25" className="text-amber-500" />;
                          } else if (item.orderstatus === 'on the way') {
                            return <RiTakeawayFill size="25" className="text-red-600" />;
                          } else {
                            return (
                              <MdOutlineDoneOutline size="25" className="text-green-600" />
                            );
                          }
                        })()}
                        <GiCampCookingPot size="25" className="text-amber-500" />
                        <RiTakeawayFill size="25" className="mx-4 text-gray-600" />
                        <MdOutlineDoneOutline size="25" className="text-green-600" />
                      </span>
                    </td> */}
                              {/* <td className="px-4 py-3">
                    <span>
                      <button
                        className="text-sm mx-auto outline-none focus:outline-none ease-linear transition-all duration-150"
                        type="button"
                        onClick={toggleModal}
                      >
                        <FaUserEdit size="18" />
                      </button>
                      {showModal && <OrderMoreDetails closeModal={closeModal} />}
                    </span>
                  </td> */}
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
                <div className="px-4 py-3 border-t bg-gray-50 text-gray-500 ">
                  <div className="flex flex-col justify-end text-xs sm:flex-row text-gray-600">
                    {/* <span className="flex items-center font-semibold tracking-wide uppercase">
                      {items.length > itemsPerPage && (
                        <span>
                          Showing {itemOffset + 1} - {itemOffset + itemsPerPage} of {items.length}
                        </span>
                      )}
                    </span> */}
                    <div className="flex mt-2 sm:mt-auto sm:justify-end">
                      {items && items.length > itemsPerPage && (
                        <ReactPaginate
                          containerClassName={'inline-flex items-center'}
                          pageLinkClassName={
                            'align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-3 py-1 rounded-md text-xs text-gray-600  focus:outline-none border border-transparent active:text-white active:bg-gray-100 hover:bg-gray-100 focus:bg-gray-600 focus:text-white'
                          }
                          breakLabel="..."
                          nextLabel={'Next'}
                          previousClassName={
                            'align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-3 py-1 rounded-md text-xs text-gray-600  focus:outline-none border border-transparent active:text-white active:bg-gray-600 hover:bg-gray-100 focus:bg-gray-600 focus:text-white'
                          }
                          nextClassName={
                            'align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-3 py-1 rounded-md text-xs text-gray-600  focus:outline-none border border-transparent active:text-white active:bg-gray-600 hover:bg-gray-100 focus:bg-gray-600 focus:text-white'
                          }
                          onPageChange={handlePageClick}
                          pageRangeDisplayed={5}
                          pageCount={pageCount}
                          previousLabel={'Previous'}
                          renderOnZeroPageCount={null}
                          activeClassName={
                            'text-gray-700 bg-white border border-gray-400 rounded-md hover:bg-gray-700 hover:text-white'
                          }
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default CustomerTable;
