import axios from 'axios';
import { AdminMicroRoutes } from '../../constants';

export const ORDER_HISTORY_SUCCESS = 'ORDER_HISTORY_SUCCESS';
export const ORDER_HISTORY_FAILURE = 'ORDER_HISTORY_FAILURE';
export const ORDER_HISTORY_LOADING = 'ORDER_HISTORY_LOADING';
import * as helpers from '../../helpers/config';

export const onuserhistory = (data) => async (dispatch) => {
  const loggeddetails = helpers.LoggedUserDetails();
  dispatch({ type: ORDER_HISTORY_LOADING, payload: true });
  await axios
    .post(AdminMicroRoutes.getorderhistory, data, {
      headers: {
        token: loggeddetails?.token,
      },
    })
    .then((response) => {
      if (response.status === 200) {
        dispatch({
          type: ORDER_HISTORY_SUCCESS,
          payload: response.data.order,
        });
      } else {
        dispatch({
          type: ORDER_HISTORY_FAILURE,
          payload: response.data.status,
        });
      }
    });
};
