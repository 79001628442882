import React, { Fragment } from 'react';
import { FiArrowLeftCircle, FiArrowRightCircle } from 'react-icons/fi';
import { IconContext } from 'react-icons';
import { useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';

const NavigationBar = ({ tab, updateTab }) => {
  const { addToast } = useToasts();
  const { customerDeliveryInfo, cartItems, customerInfo } = useSelector(
    (state) => state.callcenter
  );
  const currenttab = tab;
  const prevtab = tab - 1;
  const nexttab = tab + 1;
  return (
    <Fragment>
      <div className="container p-4 sm:mx-auto sm:px-8 flex justify-between">
        <div>
          {tab !== 1 ? (
            <IconContext.Provider value={{ className: 'text-gray-500 cursor-pointer' }}>
              <FiArrowLeftCircle
                size={30}
                onClick={() => {
                  updateTab(prevtab);
                }}
              />
            </IconContext.Provider>
          ) : (
            <></>
          )}
        </div>
        <div>
          {tab !== 4 ? (
            <IconContext.Provider value={{ className: 'text-gray-500 cursor-pointer' }}>
              <FiArrowRightCircle
                size={30}
                onClick={() => {
                  switch (currenttab) {
                    case 1:
                      if (cartItems.length > 0) {
                        return updateTab(nexttab);
                      } else {
                        addToast('Please Add Items', {
                          appearance: 'error',
                        });
                        return null;
                      }
                      break;
                    case 2:
                      if (cartItems.length > 0 && Object.keys(customerInfo).length > 0) {
                        return updateTab(nexttab);
                      } else {
                        addToast('Please Add Customer Details', {
                          appearance: 'error',
                        });
                        return null;
                      }
                      break;
                    case 3:
                      if (
                        cartItems.length > 0 &&
                        Object.keys(customerInfo).length > 0 &&
                        customerDeliveryInfo.deliverytype === 'DELIVERY'
                      ) {
                        if (Object.keys(customerDeliveryInfo.deliveryAddress).length > 0) {
                          return updateTab(nexttab);
                        } else {
                          addToast('Please Add Delivery Address', {
                            appearance: 'error',
                          });
                          return null;
                        }
                      } else {
                        return updateTab(nexttab);
                      }
                      break;
                    default:
                      break;
                  }
                }}
              />
            </IconContext.Provider>
          ) : (
            <></>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default NavigationBar;
