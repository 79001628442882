import React, { Fragment } from 'react';
import { FaUsers, FaBirthdayCake } from 'react-icons/fa';
import { MdClose, MdPendingActions } from 'react-icons/md';
import { TiTick } from 'react-icons/ti';
import { IoToday } from 'react-icons/io5';
import { IconContext } from 'react-icons';
import DetailesLoader from './DetailesLoader';
import dic from 'i18next';

const Detailes = ({ dashBoardDetails, loading }) => {
  return (
    <Fragment>
      {loading ? (
        <DetailesLoader />
      ) : (
        <div className="grid gap-6 mb-4 md:grid-cols-2 xl:grid-cols-2 px-4 mt-4">
          <div className="min-w-0 rounded-lg shadow-md border-2 border-gray-200 overflow-hidden bg-white">
            <div className="p-2 flex items-center">
              <div className="p-2 rounded-full text-purple-500  bg-purple-100 mr-2">
                <IconContext.Provider
                  value={{
                    className: 'text-2xl w-5 h-5',
                  }}
                >
                  <FaBirthdayCake />
                </IconContext.Provider>
              </div>
              <div className="flex flex-col min-w-[5rem]">
                <p className="text-sm font-semibold text-gray-600">{dic.t('Margaux-Total')}</p>
                <p className="text-sm font-semibold text-gray-600">{dic.t('Margaux-Orders')}</p>
              </div>
              <div>
                <p className="text-lg ml-4 font-semibold text-gray-700">
                  {dashBoardDetails &&
                    dashBoardDetails.length > 0 &&
                    dashBoardDetails[0].totalOrderCount}
                </p>
              </div>
            </div>
          </div>
          <div className="min-w-0 rounded-lg shadow-md border-2 border-gray-200 overflow-hidden bg-white">
            <div className="p-2 flex items-center">
              <div className="p-2 rounded-full text-orange-500 bg-orange-100 mr-2">
                <IconContext.Provider
                  value={{
                    className: 'text-2xl  w-5 h-5',
                  }}
                >
                  <FaUsers />
                </IconContext.Provider>
              </div>
              <div className="flex flex-col min-w-[5rem]">
                <p className="text-sm font-semibold text-gray-600 ">{dic.t('Margaux-Total')}</p>
                <p className="text-sm font-semibold text-gray-600">{dic.t('Margaux-Users')}</p>
              </div>
              <div>
                <p className="text-lg ml-4 font-semibold text-gray-700 ">
                  {dashBoardDetails &&
                    dashBoardDetails.length > 0 &&
                    dashBoardDetails[0].endUserCount}
                </p>
              </div>
            </div>
          </div>
          <div className="min-w-0 rounded-lg shadow-md border-2 border-gray-200 overflow-hidden bg-white">
            <div className="p-2 flex items-center">
              <div className="p-2 rounded-full text-blue-500 bg-blue-100 mr-2">
                <IconContext.Provider
                  value={{
                    className: 'text-2xl  w-5 h-5',
                  }}
                >
                  <MdPendingActions />
                </IconContext.Provider>
              </div>
              <div className="flex flex-col min-w-[5rem]">
                <p className="text-sm font-semibold text-gray-600">{dic.t('Margaux-Pending')}</p>
                <p className="text-sm font-semibold text-gray-600">{dic.t('Margaux-Orders')}</p>
              </div>
              <div>
                <p className="text-lg ml-4 font-semibold text-gray-700">
                  {dashBoardDetails &&
                    dashBoardDetails.length > 0 &&
                    dashBoardDetails[0].unDeliveredOrderCount}
                </p>
              </div>
            </div>
          </div>
          <div className="min-w-0 rounded-lg shadow-md border-2 border-gray-200 overflow-hidden bg-white">
            <div className="p-2 flex items-center">
              <div className="p-2 rounded-full text-green-500  bg-green-100  mr-2">
                <IconContext.Provider
                  value={{
                    className: 'text-2xl  w-5 h-5',
                  }}
                >
                  <TiTick />
                </IconContext.Provider>
              </div>
              <div className="flex flex-col min-w-[5rem]">
                <p className="text-sm font-semibold text-gray-600">{dic.t('Margaux-Completed')}</p>
                <p className="text-sm font-semibold text-gray-600">{dic.t('Margaux-Orders')}</p>
              </div>
              <div>
                <p className="text-lg ml-4 font-semibold text-gray-700">
                  {dashBoardDetails &&
                    dashBoardDetails.length > 0 &&
                    dashBoardDetails[0].deliveredOrderCount}
                </p>
              </div>
            </div>
          </div>
          <div className="min-w-0 rounded-lg shadow-md border-2 border-gray-200 overflow-hidden bg-white">
            <div className="p-2 flex items-center">
              <div className="p-2 rounded-full text-teal-500 bg-teal-100 mr-2">
                <IconContext.Provider
                  value={{
                    className: 'text-2xl  w-5 h-5',
                  }}
                >
                  <IoToday />
                </IconContext.Provider>
              </div>
              <div className="flex flex-col min-w-[5rem]">
                <p className="text-sm font-semibold text-gray-600">{dic.t('Margaux-Todays')}</p>
                <p className="text-sm font-semibold text-gray-600">{dic.t('Margaux-Orders')}</p>
              </div>
              <div>
                <p className="text-lg ml-4 font-semibold text-gray-700">
                  {dashBoardDetails &&
                    dashBoardDetails.length > 0 &&
                    dashBoardDetails[0].todaysOrder}
                </p>
              </div>
            </div>
          </div>
          <div className="min-w-0 rounded-lg shadow-md border-2 border-gray-200 overflow-hidden bg-white">
            <div className="p-2 flex items-center">
              <div className="p-2 rounded-full text-rose-500  bg-rose-100  mr-2">
                <IconContext.Provider
                  value={{
                    className: 'text-2xl  w-5 h-5',
                  }}
                >
                  <MdClose />
                </IconContext.Provider>
              </div>
              <div className="flex flex-col min-w-[5rem]">
                <p className="text-sm font-semibold text-gray-600">{dic.t('Margaux-Cancel')}</p>
                <p className="text-sm font-semibold text-gray-600">{dic.t('Margaux-Orders')}</p>
              </div>
              <div>
                <p className="text-lg ml-4 font-semibold text-gray-700">
                  {dashBoardDetails &&
                    dashBoardDetails.length > 0 &&
                    dashBoardDetails[0].cancelledOrderCount}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default Detailes;
