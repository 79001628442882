import React, { Fragment } from 'react';

const TableLoader = () => {
  return (
    <Fragment>
      <div className="grid grid-cols-12 gap-4 mt-4">
        <div className="h-8 animate-pulse bg-gray-300 rounded col-span-3"></div>
        <div className="h-8 animate-pulse bg-gray-300 rounded col-span-3"></div>
        <div className="h-8 animate-pulse bg-gray-300 rounded col-span-3"></div>
        <div className="h-8 animate-pulse bg-gray-300 rounded col-span-3"></div>
        <div className="h-4 animate-pulse bg-gray-300 rounded col-span-12"></div>
        <div className="h-4 animate-pulse bg-gray-300 rounded col-span-12"></div>
        <div className="h-4 animate-pulse bg-gray-300 rounded col-span-12"></div>
      </div>
    </Fragment>
  );
};

export default TableLoader;
