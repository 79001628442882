import {
  SET_CART_DATA,
  REMOVE_CART_DATA,
  SET_CUSTOMER_DATA,
  SET_CUSTOMER_DELIVERY_DATA,
} from '../../actions/admin/CallCenter.action';
import * as Helpers from '../../helpers/AdminHelpers';

const initailState = {
  cartItems: [],
  customerInfo: {},
  customerDeliveryInfo: {
    dpdate: new Date(),
    dptime: '12:00pm - 03:00pm',
    deliverytype: 'DELIVERY',
    deliveryAddress: {},
  },
};

export const callCenterReducer = (state = initailState, action) => {
  switch (action.type) {
    case SET_CART_DATA:
      if (state.cartItems.length > 0) {
        let cartItems = [...state.cartItems];
        let founditem = cartItems.filter((item) => {
          const { cakeFlavour, cakeServings, cakeBerries, cakeMessage, cakeRemarks } = item;
          if (
            cakeFlavour === action.payload.cakeFlavour &&
            cakeServings === action.payload.cakeServings &&
            cakeBerries === action.payload.cakeBerries &&
            cakeMessage === action.payload.cakeMessage &&
            cakeRemarks === action.payload.cakeRemarks
          ) {
            item.cakeQuantity = item.cakeQuantity + 1;
            item.totalPrice = item.cakeQuantity * item.cakePerItemPrice;
            return true;
          }
        });
        if (founditem.length > 0) {
          return {
            ...state,
            cartItems: [...cartItems],
          };
        }
      }
      return {
        ...state,
        cartItems: [...state.cartItems, action.payload],
      };
    case REMOVE_CART_DATA:
      if (state.cartItems.length > 0) {
        let cartItems = [...state.cartItems];
        let founditem = cartItems.filter((item) => {
          const { cakeFlavour, cakeServings, cakeBerries, cakeMessage, cakeRemarks, cakeQuantity } =
            item;
          if (
            cakeFlavour !== action.payload.cakeFlavour ||
            cakeServings !== action.payload.cakeServings ||
            cakeBerries !== action.payload.cakeBerries ||
            cakeMessage !== action.payload.cakeMessage ||
            cakeRemarks !== action.payload.cakeRemarks
          ) {
            return true;
          } else if (
            cakeFlavour === action.payload.cakeFlavour &&
            cakeServings === action.payload.cakeServings &&
            cakeBerries === action.payload.cakeBerries &&
            cakeMessage === action.payload.cakeMessage &&
            cakeRemarks === action.payload.cakeRemarks
          ) {
            if (cakeQuantity > 1) {
              item.cakeQuantity = item.cakeQuantity - 1;
              item.totalPrice = item.cakeQuantity * item.cakePerItemPrice;
              return true;
            } else {
              return false;
            }
          }
        });
        return {
          ...state,
          cartItems: [...founditem],
        };
      }
      return {
        ...state,
        cartItems: [...state.cartItems, action.payload],
      };
    case SET_CUSTOMER_DATA:
      return {
        ...state,
        customerInfo: action.payload,
      };
    case SET_CUSTOMER_DELIVERY_DATA:
      return {
        ...state,
        customerDeliveryInfo: {
          ...state.customerDeliveryInfo,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};
