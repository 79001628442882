/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react';
import * as helpers from '../../helpers/config';
import { BsXCircleFill, BsFillArrowLeftCircleFill } from 'react-icons/bs';
import { ImFilesEmpty } from 'react-icons/im';
import Info from '../CommonIcon/Info';
import dic from 'i18next';
import { IoMdCloseCircle } from 'react-icons/io';
import { useToasts } from 'react-toast-notifications';
import { HiBadgeCheck } from 'react-icons/hi';

function CustomizeService(props) {
  const { modalStatus, servicelist, productAddons, pastryName } = props;
  console.log('pastryName', pastryName);
  const { addToast } = useToasts();
  const [getServes, setServes] = useState();
  const [getCheckRadion, setCheckRadio] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const [getServiceCount, setServiceCount] = useState(0);
  const [Addon, setAddon] = useState('');
  const [selectStatus, setselectStatus] = useState([]);
  const [tabStatus, setTabStatus] = useState(false);
  const [getPrice, setPrice] = useState();
  const [chefSelectionStatus, setchefSelectionStatus] = useState(false);
  const [showCount, setCount] = useState([]);
  const [showtempDetail, settempDetail] = useState([]);
  const service = servicelist?.fields?.Services;
  const CheckCountValidate = (e) => {
    const getCount = document.getElementsByClassName('checkCount');
    let tempCounts = [];
    let tempDetail = [];
    getCount.forEach((element) => {
      tempCounts.push(parseInt(element.value) || 0);
      tempDetail.push(element.id);
      document.getElementById(element.id).value = `${parseInt(element.value) || 0}`;
    });
    const currentTotal = tempCounts.reduce((partialSum, a) => partialSum + a, 0);
    if (getServiceCount < currentTotal) {
      // addToast('Please select ' + getServiceCount + ' items only', { appearance: 'info' });
    } else {
      settempDetail(tempDetail);
      setCount(tempCounts);
    }
    // console.log(tempCounts);
  };
  const checkRadionValidate = (serviceid, ServiceInNumber, price) => {
    setShowMessage(false);
    setCheckRadio(serviceid);
    setTabStatus(true);
    setServiceCount(ServiceInNumber);
    setPrice(price);
  };
  // const takeService = () => {
  //   const serviceProductName = document.getElementById('getflavour').value;
  //   if (serviceProductName === '' || serviceProductName === undefined) {
  //     addToast('Please select the flavour', { appearance: 'info' });
  //     return false;
  //   }
  //   if (getServiceCount > selectStatus.length) {
  //     let setvalue = [...selectStatus, serviceProductName];
  //     setselectStatus(setvalue);
  //   } else {
  //     addToast('You have selected ' + getServiceCount + ' items', { appearance: 'info' });
  //   }
  // };
  // const RemoveItem = (removekey) => {
  //   const setvalue = selectStatus.filter((item, key) => key !== removekey);
  //   setselectStatus([...setvalue]);
  // };
  const AddingAddion = (name, price, currency) => {
    const list = {
      addonName: name,
      Price: Number(price),
      Currency: currency,
    };
    setAddon(list);
  };
  const closeModal = () => {
    props.closeModalCustomize();
    document.getElementById('message').value = '';
  };
  const callingCartTrigger = () => {
    if (getCheckRadion === '') {
      setShowMessage(true);
      return false;
    }
    if (chefSelectionStatus === false) {
      let tempCounts = [];
      const getCount = document.getElementsByClassName('checkCount');
      getCount.forEach((element) => {
        tempCounts.push(parseInt(element.value) || 0);
      });
      const currentTotal = tempCounts.reduce((partialSum, a) => partialSum + a, 0);
      if (parseInt(getServiceCount) === parseInt(currentTotal)) {
      } else {
        addToast('You need to select minimum ' + getServiceCount + ' flavour', {
          appearance: 'info',
        });
        return false;
      }
    }
    let tempCounts = [];
    const getCount = document.getElementsByClassName('checkCount');
    getCount.forEach((element) => {
      tempCounts.push(parseInt(element.value) || 0);
    });
    let LoopData = [];
    showtempDetail.map((item, key) => {
      for (let i = 0; i < tempCounts[key]; i++) {
        LoopData.push(item);
      }
    });
    const serviceid = getCheckRadion;
    const message = document.querySelector('.messagebox').value;
    const price = getPrice;
    const serves = getServes;
    setTabStatus(false);
    props.onCartFunction(
      servicelist,
      serviceid,
      message,
      price,
      serves,
      Addon,
      chefSelectionStatus === false ? LoopData : ['Chefs Selection'],
      'plus',
      props.customizeType
    );
    setCheckRadio('');
    document.getElementById('message').value = '';
  };
  var count = 0;
  return (
    <div>
      <div
        className={`fixed z-20 overflow-y-auto top-0 w-full left-0 ${
          modalStatus === true ? '' : 'hidden'
        }`}
        id="servicemodal"
      >
        <div className="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-black opacity-75" />
          </div>
          <span className="flex h-screen justify-center items-center sm:inline-block sm:align-middle sm:h-screen">
            &#8203;
          </span>
          <div
            className={
              'inline-block  align-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform  transition-all sm:my-8 sm:align-middle  sm:max-w-md  w-full'
            }
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="bg-white sm:pb-4">
              {/* Body Start */}
              <div className="modal-body">
                <h4 className="text-base sm:text-lg text-center bg-gray-700 font-semibold tracking-wider py-3">
                  {dic.t('Margaux-Customize Your Order')}
                </h4>
                <div className="position-relative">
                  <BsXCircleFill
                    onClick={() => closeModal()}
                    className="absolute right-[17px] top-[19px] cursor-pointer text-lg"
                  />
                </div>
                <div className="text-black px-5 pt-2">
                  {tabStatus === true ? (
                    <div className="sm:overflow-x-auto  sm:max-h-full scrollbar-thumb-gray-700 scrollbar-track-gray-100 scrollbar-thin  overflow-x-auto h-[18rem]">
                      <div className="grid text-sm grid-cols-1">
                        {chefSelectionStatus === '' || chefSelectionStatus === true ? (
                          <p className="px-2 visible">You Have Selected Chef Selection</p>
                        ) : (
                          <p className="px-2 text-sm font-bold">{`Please select the ${getServiceCount} flavours`}</p>
                        )}
                        <p className="text-right px-4 relative">
                          <BsFillArrowLeftCircleFill
                            size={21}
                            onClick={() => setTabStatus(false)}
                            className="inline cursor-pointer absolute right-[12px] bottom-[-2px] text-slate-400"
                          />
                        </p>
                      </div>
                      <div>
                        <>
                          <div className="grid grid-cols-2 mt-4 px-2">
                            <button
                              onClick={() => {
                                setchefSelectionStatus(false);
                                setselectStatus([]);
                              }}
                              className="text-white relative mx-1.5 bg-gray-700   tracking-wider  rounded-lg text-sm px-5 sm:px-6 py-2.5 text-center"
                            >
                              Customize
                              {chefSelectionStatus === false ? (
                                <HiBadgeCheck
                                  className="absolute mx-1 top-2 right-[2px]"
                                  size={20}
                                />
                              ) : (
                                <></>
                              )}
                            </button>
                            <button
                              onClick={() => {
                                setchefSelectionStatus(true);
                                setselectStatus(['Chefs Selection']);
                              }}
                              className="text-white mx-1.5 relative bg-gray-700   tracking-wider  rounded-lg text-sm px-5 sm:px-6 py-2.5 text-center"
                            >
                              Chefs Selection{' '}
                              {chefSelectionStatus === true ? (
                                <HiBadgeCheck
                                  className="absolute mx-1 top-2 right-[2px]"
                                  size={20}
                                />
                              ) : (
                                <></>
                              )}
                            </button>
                          </div>
                        </>
                        {chefSelectionStatus === false ? (
                          <>
                            <div className="pt-4 pb-3">
                              <div className="parent-select mx-3">
                                <div className="border cursor-pointer  border-gray-500 text-sm py-2 rounded-md  px-5">
                                  {showCount.length > 0 &&
                                  showCount.reduce((partialSum, a) => partialSum + a, 0) ? (
                                    showtempDetail.map((item, key) => {
                                      return (
                                        <>
                                          {showCount[key] > 0 ? (
                                            item + ' x ' + showCount[key] + ','
                                          ) : (
                                            <></>
                                          )}
                                        </>
                                      );
                                    })
                                  ) : (
                                    <>Select Flavors</>
                                  )}
                                </div>
                                <div className="bg-gray-200  rounded-md pb-1">
                                  {props?.customizeType === 'signature'
                                    ? pastryName?.map((item, key) => {
                                        let TempValue = item?.fields?.Title?.value.replace(
                                          /\'/g,
                                          ''
                                        );
                                        if (
                                          item?.fields['Pastry Category']?.fields?.Title?.value ===
                                            'Signature' &&
                                          item?.fields?.Title?.value === TempValue
                                        ) {
                                          return (
                                            <>
                                              <div
                                                key={`signature-${key}`}
                                                className="flex flex-row hover:bg-gray-300 cursor-pointer"
                                              >
                                                <div className="basis-9/12 px-1">
                                                  <div className="text-md py-1 ">
                                                    <p className="px-4 pt-1">
                                                      {item?.fields?.Title?.value}
                                                    </p>
                                                  </div>
                                                </div>
                                                <div className="basis-3/12 text-right px-1">
                                                  <input
                                                    type={'number'}
                                                    min={0}
                                                    onChange={(e) => CheckCountValidate(e)}
                                                    onInput={(e) => {
                                                      e.target.value = e.target.value.slice(0, 2);
                                                    }}
                                                    value={parseInt(showCount[key]) || 0}
                                                    id={item?.fields?.Title?.value}
                                                    className="form-control checkCount block w-full px-3 py-1 my-1 "
                                                  />
                                                </div>
                                              </div>
                                            </>
                                          );
                                        }
                                      })
                                    : pastryName?.map((item, keys) => {
                                        let TempValue = item?.fields?.Title?.value.replace(
                                          /\'/g,
                                          ''
                                        );
                                        if (
                                          item?.fields['Pastry Category']?.fields?.Title?.value ===
                                            'Macaroons' &&
                                          item?.fields?.Title?.value === TempValue
                                        ) {
                                          count++;
                                          return (
                                            <>
                                              <div className="flex flex-row hover:bg-gray-300 cursor-pointer">
                                                <div className="basis-9/12 px-1">
                                                  <div className="text-md py-1 ">
                                                    <p className="px-4 pt-1">
                                                      {item?.fields?.Title?.value}
                                                    </p>
                                                  </div>
                                                </div>
                                                <div className="basis-3/12 text-right px-1">
                                                  <input
                                                    type={'number'}
                                                    min={0}
                                                    onChange={(e) => CheckCountValidate(e)}
                                                    onInput={(e) =>
                                                      (e.target.value = e.target.value.slice(0, 2))
                                                    }
                                                    value={parseInt(showCount[count - 1] || 0)}
                                                    id={item?.fields?.Title?.value}
                                                    className="form-control checkCount block w-full px-3 py-1 my-1 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                                  />
                                                </div>
                                              </div>
                                            </>
                                          );
                                        }
                                      })}
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  ) : (
                    <>
                      <table className="table-auto w-full mb-3 ">
                        <thead className="text-base border-b-2 border-gray-300  font-bold">
                          <tr>
                            <th className="pb-2 px-3 text-sm sm:text-base">
                              {props && props.type}
                            </th>
                            <th className="pb-2 text-sm sm:text-base">{dic.t('Margaux-Price')}</th>
                            <th className="pb-2"></th>
                          </tr>
                        </thead>
                        <tbody className="text-sm">
                          {service &&
                            service.map((serviceitem, keys) => {
                              const servicetopeople =
                                serviceitem &&
                                serviceitem.fields['Service To Persons'] &&
                                serviceitem.fields['Service To Persons'].fields &&
                                serviceitem.fields['Service To Persons'].fields.Title &&
                                serviceitem.fields['Service To Persons'].fields.Title.value;
                              const ServiceInNumber =
                                serviceitem &&
                                serviceitem.fields['Service To Persons'] &&
                                serviceitem.fields['Service To Persons'].fields &&
                                serviceitem.fields['Service To Persons'].fields.Code &&
                                serviceitem.fields['Service To Persons'].fields.Code.value;
                              const price =
                                serviceitem && serviceitem.fields && serviceitem.fields.Price.value;
                              const serviceid = serviceitem.id;
                              return (
                                <tr key={keys}>
                                  <td className="pt-3 px-3 servicetopeople" id={servicetopeople}>
                                    {servicetopeople}
                                  </td>
                                  <td className="">
                                    {helpers.currency} {price}
                                  </td>
                                  <td className="">
                                    <div className="flex items-center">
                                      <input
                                        value={serviceid}
                                        id={price}
                                        type="radio"
                                        name="radioList"
                                        onChange={() => setServes(servicetopeople)}
                                        onClick={() =>
                                          checkRadionValidate(serviceid, ServiceInNumber, price)
                                        }
                                        className="w-4 h-4   service-checkbox cursor-pointer"
                                      />
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                      {showMessage === true && (
                        <div
                          className="flex p-4 mb-1 text-sm text-yellow-700 bg-yellow-100 rounded-lg"
                          role="alert"
                        >
                          <Info />

                          <div className="inline">
                            <span className="font-medium pt-3 px-2">
                              {dic.t('Margaux-Select a serve from the serves list')}
                            </span>
                          </div>
                        </div>
                      )}

                      {productAddons.map((item, keys) => {
                        const charges = item?.fields?.Charges;
                        return (
                          <div className="addons px-3">
                            <div className="each-addon-title font-bold border-b-2 pb-1">
                              <p>{item?.fields?.Title?.value}</p>
                            </div>
                            <div className="each-addon-price pb-2">
                              <div className="grid grid-cols-4 gap-1  sm:gap-4 pt-2">
                                {charges?.map((chargeitem, keyvalue) => {
                                  return (
                                    <div className="text-xs py-1 sm:py-0 sm:text-sm" key={keyvalue}>
                                      <input
                                        onClick={() =>
                                          AddingAddion(
                                            item?.fields?.Title?.value,
                                            chargeitem?.fields?.Price?.value,
                                            chargeitem?.fields?.Currency?.fields['Currency Code']
                                              .value
                                          )
                                        }
                                        type="radio"
                                        name="addon"
                                        className="w-4 h-4   relative top-0.5 cursor-pointer"
                                      />{' '}
                                      {chargeitem?.fields?.Price?.value}{' '}
                                      {chargeitem?.fields?.Currency?.fields['Currency Code'].value}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  )}

                  <div className="message-box px-2 pt-1">
                    <textarea
                      id="message"
                      rows="2"
                      maxLength={50}
                      className="block p-2.5 messagebox capitalize placeholder:tracking-widest w-full text-sm text-black bg-gray-50 rounded-lg border border-gray-500 outline-none"
                      placeholder={dic.t('Margaux-Message To Put On The Pastry')}
                    ></textarea>
                  </div>

                  <div className="pt-3 pb-5 sm:pb-0 text-base  text-center">
                    <button
                      onClick={() => callingCartTrigger(servicelist)}
                      className={
                        'text-white  bg-gray-700 font-bold   tracking-wider  lg:mr-1 rounded-lg text-sm px-5 sm:mr-1 mr-2 sm:px-6 py-2 text-center'
                      }
                    >
                      {dic.t('Margaux-Add')}
                    </button>
                  </div>
                </div>
              </div>
              {/* Body End */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default CustomizeService;
