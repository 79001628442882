import {
  GET_ORDER_LOADING,
  GET_ORDER_SUCCESS,
  GET_ORDER_FAILURE,
} from '../../actions/admin/GetAllOrder.action';

import {
  UPDATE_ORDER_LOADING,
  UPDATE_ORDER_SUCCESS,
  UPDATE_ORDER_FAILURE,
  ORDER_STATUS_REVERT,
} from '../../actions/admin/UpdateOrder.action';

const initailState = {
  loading: false,
  orders: [],
  error: '',
  updateloading: false,
  updateMessage: '',
  updateError: '',
  statusCode: '',
  message: '',
  successStatus: 'initial',
};

export const getAllOrdersReducer = (state = initailState, action) => {
  switch (action.type) {
    case GET_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        orders: action.payload,
        statusCode: action.status,
      };

    case ORDER_STATUS_REVERT:
      return {
        ...state,
        successStatus: 'initial',
      };

    case GET_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        statusCode: action.status,
        message: action.message,
      };

    case GET_ORDER_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case UPDATE_ORDER_LOADING:
      return {
        ...state,
        updateloading: action.payload,
      };

    case UPDATE_ORDER_FAILURE:
      return {
        ...state,
        updateError: action.payload,
        successStatus: false,
      };

    case UPDATE_ORDER_SUCCESS:
      const UpdatOrderIndex = state.orders.findIndex(
        (order) => order.orderId === action.payload.order.orderId
      );
      const neworders = [...state.orders];
      neworders[UpdatOrderIndex] = action.payload.order;
      return {
        ...state,
        orders: neworders,
        updateMessage: action.payload.message,
        successStatus: true,
      };
    default:
      return state;
  }
};
