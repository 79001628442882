import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import dic from 'i18next';
import Decrement from '../CommonIcon/Decrement';
import Increment from '../CommonIcon/Increment';

function SignatureBox(props) {
  const signature = props?.signatureBox;
  const signature_banner = signature[0]?.fields['Pastry Images'][0]?.fields?.Image?.value?.src;
  const description = signature[0]?.fields?.Description?.fields?.Description?.value;
  return (
    <Fragment>
      <div className="py-4 rounded-md">
        <section>
          <div className="px-0 py-6 sm:py-16 mx-auto max-w-screen-2xl sm:px-6 lg:px-12">
            <div className="grid grid-cols-1  sm:grid-cols-2 lg:grid-cols-12">
              <div className="relative z-10 col-span-1 lg:py-16 lg:col-span-8">
                <div className="relative h-64 sm:h-80 overflow-hidden rounded-md">
                  <img
                    alt="House"
                    src={signature_banner}
                    className="absolute inset-0 object-cover w-full h-full rounded-md hover:scale-105 ease-in duration-500 "
                  />
                </div>
              </div>

              <div className="relative col-span-1 lg:col-span-4 flex items-center bg-transperent lg:border-r-2 lg:border-y-2 rounded-tr-md rounded-br-md border-gray-400">
                <span className="hidden lg:inset-y-[-2px] lg:absolute bg-transperent lg:border-l-2 lg:border-y-2 rounded-tl-md rounded-bl-md border-gray-400 lg:w-16 lg:block lg:-left-16"></span>

                <div className="p-3 sm:p-8">
                  <h2 className="text-2xl tracking-widest sm:text-3xl uppercase">Signature Box</h2>

                  <div className="mt-4">
                    <div dangerouslySetInnerHTML={{ __html: props.Description }}></div>
                    <ul className="mt-2">
                      {signature?.map((item, keys) => {
                        const exists = props.cartItems.filter((currentcart) => {
                          return currentcart?.ItemDetails?.id === item.id;
                        });
                        var counts = 0;
                        exists.map((itemcheck) => {
                          itemcheck.cartaddedservice.map((serviceitem) => {
                            return (counts = counts + serviceitem?.quantity?.value);
                          });
                        });
                        const service = item?.fields?.Services;
                        const addons = item?.fields?.Addons;
                        return (
                          <>
                            {service?.map((serviceitem, key) => {
                              return (
                                <>
                                  <li>
                                    {serviceitem?.displayName} AED{' '}
                                    {serviceitem?.fields?.Price?.value}
                                  </li>
                                </>
                              );
                            })}
                            <div className="flex relative	cursor-pointer py-5" key={keys}>
                              {exists &&
                              exists[0] &&
                              exists[0].cartaddedservice &&
                              exists[0].cartaddedservice.length > 0 ? (
                                <div className="grid grid-cols-3 addtocartbox bg-gray-700 px-1 py-1 rounded-md">
                                  <div
                                    className="flex relative top-1	cursor-pointer"
                                    onClick={props.SorryCartStatusUpdate}
                                  >
                                    <Decrement />
                                  </div>
                                  <div className="flex text-white px-1 justify-center text-lg">
                                    {counts}
                                  </div>
                                  <div
                                    className="flex relative top-[0.2em] cursor-pointer"
                                    onClick={() =>
                                      props.modalUpdateStatus(item, addons, 'signature')
                                    }
                                  >
                                    <Increment />
                                  </div>
                                </div>
                              ) : (
                                <div className="grid grid-cols-1 justify-items-end">
                                  <Link
                                    onClick={() =>
                                      props.modalUpdateStatus(item, addons, 'signature')
                                    }
                                    className="text-white font-normal hover:bg-gray-700 bg-gray-600 uppercase rounded-lg text-xs lg:text-sm px-2 py-2 sm:px-2  sm:py-2.5 text-center   "
                                  >
                                    {dic.t('Margaux-Buy Now')}
                                  </Link>
                                </div>
                              )}
                            </div>
                          </>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Fragment>
  );
}
export default SignatureBox;
