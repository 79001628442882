const MicroEndPoint = process.env.NODE_ENV === 'development' ? 'http://localhost:9001' : '';
const ServiceEndPoint = process.env.REACT_APP_SERVICES_ENDPOINT;
export const EmailServiceURL = `${MicroEndPoint}/services/send-email`;
export const reactAppSecretScan = '16KKymy#90!#8WU$d@28$@%';
const EndUserEndPoint =
  process.env.NODE_ENV === 'production'
    ? 'https://margauxpastryboutique.com'
    : 'https://uat.margauxpastryboutique.com';
export const MicroRoutes = {
  register: `${MicroEndPoint}/pastry/register`,
  login: `${MicroEndPoint}/pastry/login-user`,
  forgot: `${MicroEndPoint}/pastry/forgotPassword`,
  guestUserToken: `${MicroEndPoint}/pastry/guest-user-token`,
  decryptToken: `${MicroEndPoint}/pastry/decrypt-token`,
  update: `${MicroEndPoint}/pastry/updatePassword`,
  getProfile: `${MicroEndPoint}/pastry/getProfile`,
  updateProfile: `${MicroEndPoint}/pastry/updateProfile`,
  addtocart: `${MicroEndPoint}/pastry/add-Cart`,
  ordercreate: `${MicroEndPoint}/pastry/ordercreate`,
  paymentorder: `${MicroEndPoint}/pastry/paymentorder`,
  createInvoiceOrder: `${MicroEndPoint}/pastry/invoice`,
  paymentstatus: `${MicroEndPoint}/pastry/getpaymentstatus`,
  viewOrderbyCustId: `${MicroEndPoint}/pastry/viewOrderbyCustId`,
  CartUserId: `${MicroEndPoint}/pastry/cart-userid`,
  ContactUs: `${MicroEndPoint}/pastry/contactus`,
};

export const EndUserRoutes = {
  redirectUrl: `${EndUserEndPoint}/en/booking-confirmation`,
};

export const AdminMicroRoutes = {
  getalluser: `${MicroEndPoint}/pastry/all-users-for-admin`,
  getallroles: `${MicroEndPoint}/pastry/all-roles`,
  getdashboard: `${MicroEndPoint}/pastry/dashboard-details`,
  updateuser: `${MicroEndPoint}/pastry/update-user`,
  updateorder: `${MicroEndPoint}/pastry/update-order`,
  getorderhistory: `${MicroEndPoint}/pastry/user-order-history`,
  getallorders: `${MicroEndPoint}/pastry/all-orders`,
  filterorders: `${MicroEndPoint}/pastry/filter-orders`,
  orderReport: `${MicroEndPoint}/pastryreport/order-report`,
  kitchenReport: `${MicroEndPoint}/pastryreport/kicthen-report`,
};

// export const PaymentMicroRoutes = {
//   createorder: `${MicroEndPoint}/orders`,
//   createInvoiceOrder: `${MicroEndPoint}/invoice`,
// };

export const USER_END_PERMISSION = {
  home: 'HOME',
  grandsGateux: 'GRANDS_GATEAUX',
  pettiesPattiseries: 'PETITES_PATISSERIES',
  customizeCake: 'CUSTOMIZE',
  dashboard: 'DASHBOARD',
  callcenter: 'CALL_CENTER',
  cart: 'CART',
  kitchendisplay: 'KITCHEN_DISPLAY',
  individual: 'INDIVIDUAL_PASTRIES',
  signature: 'SIGNATURE',
  macroons: 'MACAROONS',
};

export const ADMIN_PERMISSION = {
  dashboard: 'DASHBOARD',
  kitchenDisplay: 'KITCHEN_DISPLAY',
  employess: 'EMPLOYEES',
  orders: 'ORDERS',
  callcenter: 'CALL_CENTER',
};

export const SITECORE_TABS = {
  HOME: 'Home',
  GRANDS_GATEAUX: 'Grands Gateaux',
  PATTIES_PASTRIESS: 'Petites Patisseries',
  CUSTOMIZE_CAKE: 'Customize Pastry',
  DASHBOARD: 'Dashboard',
  CALLCENTER: 'Call Center',
  KITCHEN_DISPLAY: 'Kitchen Display',
  ADDTOCART: 'Add To Cart',
  INDIVIDUAL: 'Individual Pastries',
  SIGNATURE_BOX: 'Signature Box',
  MACROONS: 'Macaroons',
};

export const ADMIN_TABS = {
  KITCHEN_DISPLAY: 'Kitchen Display',
  EMPLOYEES: 'Employees',
  CALL_CENTER: 'Call Center',
  ORDERS: 'Orders',
  DASHBOARD: 'Dashboard',
};

export const DEFALUT_PERMISSION = [
  'HOME',
  'GRANDS_GATEAUX',
  'PETITES_PATISSERIES',
  'SIGNATURE',
  'CUSTOMIZE',
  'CART',
  'INDIVIDUAL_PASTRIES',
  'MACAROONS',
];
