import React, { Fragment, useEffect, useState } from 'react';
import DatePickup from './DatePickup';
import { useDispatch, useSelector } from 'react-redux';
import Delivery from './Delivery';
import { setCustomerDeliveryInfo } from '../../../actions/admin/CallCenter.action';

const DeliveryScreen = ({ deliverycharges, pickupTime, deliveryTime }) => {
  const dispatch = useDispatch();
  const { customerDeliveryInfo } = useSelector((state) => state.callcenter);
  const [dpdate, setdpdate] = useState(customerDeliveryInfo.dpdate);
  const [dptime, setdptime] = useState(customerDeliveryInfo.dptime);
  const [deliveryType, setdeliveryType] = useState(customerDeliveryInfo.deliverytype);
  useEffect(() => {
    const customerinfopayload = {
      dpdate: dpdate,
      dptime: dptime,
      deliverytype: deliveryType,
    };
    dispatch(setCustomerDeliveryInfo(customerinfopayload));
  }, [dpdate, dptime, deliveryType]);
  return (
    <Fragment>
      <div className="grid grid-cols-1 lg:grid-cols-12 p-4 sm:p-0 gap-2">
        <Delivery
          deliverycharges={deliverycharges}
          setdeliveryType={setdeliveryType}
          setdptime={setdptime}
          dptime={dptime}
          deliveryTime={deliveryTime}
          deliveryType={deliveryType}
          pickupTime={pickupTime}
        />
        <DatePickup setdpdate={setdpdate} dpdate={dpdate} />
      </div>
    </Fragment>
  );
};

export default DeliveryScreen;
