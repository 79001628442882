/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react';
import * as helpers from '../../helpers/config';
import { BsXCircleFill } from 'react-icons/bs';
import Slider from 'react-slick';
import Decrement from '../CommonIcon/Decrement';
import Increment from '../CommonIcon/Increment';
import { Link } from 'react-router-dom';
import dic from 'i18next';

function ViewMore({
  getViewMore,
  ViewMoreUpdate,
  getDescription,
  modalUpdateStatus,
  SorryCartStatusUpdate,
}) {
  var settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    autoPlay: 1000,
    autoPlaySpeed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const { images, description, cakename, items, addons, type, exists, counts, findlowestprice } =
    getDescription;
  return (
    <div>
      <div
        className={`fixed z-10 overflow-y-auto top-0 w-full left-0 ${
          getViewMore === true ? '' : 'hidden'
        } `}
        id="viewmoremodal"
      >
        <div className="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-black opacity-75" />
          </div>
          <span className="flex h-screen justify-center items-center sm:inline-block sm:align-middle sm:h-screen">
            &#8203;
          </span>
          <div
            className="inline-block  align-center bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle max-w-screen-sm sm:max-w-screen-sm lg:max-w-[80%] xl:max-w-screen-[32rem] 2xl:max-w-[70%] w-full"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="bg-white">
              {/* Body Start */}
              <div className="modal-body">
                <h4 className="text-lg text-center bg-gray-700 font-semibold tracking-wider py-3">
                  {cakename}
                </h4>
                <div className="position-relative">
                  <BsXCircleFill
                    className="absolute right-[17px] top-[19px] cursor-pointer text-lg"
                    onClick={() => ViewMoreUpdate()}
                  />
                </div>
                <div className="grid grid-cols-1  lg:grid-cols-2 ">
                  <div>
                    <Slider {...settings}>
                      {images.map((items) => {
                        const image = items?.fields?.Image?.value?.src;
                        return (
                          <div className="w-full h-[35vh] lg:h-[70vh] 2xl:h-[60vh] overflow-hidden relative">
                            <img
                              className="absolute inset-0 hover:scale-150 ease-in duration-500 lg:h-full	w-full object-cover"
                              src={image}
                              alt="cake"
                            />
                          </div>
                        );
                      })}
                    </Slider>
                  </div>
                  <div>
                    <div className="scrollbar-thumb-gray-700 scrollbar-track-gray-100 scrollbar-thin text-center py-3 overflow-y-auto max-h-[35vh] sm:max-h-[35vh] md:max-h-[50vh]  px-14">
                      <p
                        className="text-black text-left"
                        dangerouslySetInnerHTML={{ __html: description }}
                      />
                    </div>
                    <div className="grid grid-cols-1 px-14">
                      {/* <div className="text-center text-black pt-2">
                        {'AED'} {findlowestprice}
                      </div> */}
                      <div className="flex relative	cursor-pointer text-sm sm:text-lg justify-end">
                        {exists &&
                        exists[0] &&
                        exists[0].cartaddedservice &&
                        exists[0].cartaddedservice.length > 0 ? (
                          <div className="grid grid-cols-3 addtocartbox bg-gray-700 px-1 py-1 rounded-md  h-[36px]">
                            <div
                              className="flex relative top-1	cursor-pointer"
                              onClick={SorryCartStatusUpdate}
                            >
                              <Decrement />
                            </div>
                            <div className="flex text-white relative top-1 sm:top-0 px-1 justify-center">
                              {counts}
                            </div>
                            <div
                              className="flex relative top-[0.2em] cursor-pointer"
                              onClick={() => modalUpdateStatus(items, addons, type)}
                            >
                              <Increment />
                            </div>
                          </div>
                        ) : (
                          <div className="grid grid-cols-1 justify-items-center h-[36px]">
                            <Link
                              onClick={() => modalUpdateStatus(items, addons, type)}
                              className="text-white font-normal hover:bg-gray-700 bg-gray-600 uppercase rounded-lg text-xs ml-2 sm:ml-0 lg:text-sm px-2 py-2 sm:px-2 lg:px-5  sm:pt-2.5 text-center"
                            >
                              {dic.t('Margaux-Add To Cart')}
                            </Link>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Body End */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ViewMore;
