import React, { Fragment } from 'react';

const ChefInfo = () => {
  return (
    <Fragment>
      <div className="bg-black">
        <div className="max-w-5xl px-4 py-8 mx-auto ">
          <section className="p-8 bg-black">
            <div className="grid grid-cols-1 gap-12 sm:grid-cols-3 sm:items-center">
              <img
                alt="Man"
                src="https://cdn.jumeirah.com/-/mediadh/dh/hospitality/jumeirah/restaurants/dubai/mina-a-salam-margaux/chef-image/mas--alexandre--pastry-chef.jpg?h=1125&w=1080"
                className="object-cover w-full rounded-full aspect-square"
              />

              <blockquote className="sm:col-span-2">
                <cite className="inline-flex items-center not-italic">
                  <p className="text-xl text-gray-500 uppercase tracking-widest">
                    <strong>Alexandre Dufeu</strong>, EXECUTIVE PASTRY CHEF
                  </p>
                </cite>
                <p className="text-md font-medium text-white pt-2">
                  Alexandre began his professional career in 2009 at the five-star Grand Hotel Des
                  Thermes in France as Chef de Partie. Following this he moved to the Hotel Plaza
                  Athenee as Commis Patissier where he worked his way up the ladder for three years
                  eventually being appointed as Chef Patissier in 2015.
                </p>
                <p className="text-md font-medium text-white pt-2">
                  During his time here, he was mentored by the renowned Alain Ducasse and Angelo
                  Musa. In 2021, Alexandre moved to Dubai and joined Jumeirah Mina A’Salam where he
                  has brought and revitalized the pastry experience from the lobby to in-room dining
                  and across the restaurants in the hotel.
                </p>
              </blockquote>
            </div>
          </section>
        </div>
      </div>
    </Fragment>
  );
};

export default ChefInfo;
