'use strict';
var __assign =
  (this && this._assign) ||
  function () {
    __assign =
      Object.assign ||
      function (t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
          s = arguments[i];
          for (var p in s) {
            if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
          }
        }
        return t;
      };
    return __assign.apply(this, arguments);
  };

Object.defineProperty(exports, '_esModule', { value: true });

var ResponseError =
  /** @class */
  (function (_super) {
    function ResponseError(message, response) {
      var _this = _super.call(this, message) || this;
      Object.setPrototypeOf(_this, ResponseError.prototype);
      _this.response = response;
      return _this;
    }
    return ResponseError;
  })(Error);

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  var error = new ResponseError(response.statusText, response);
  throw error;
}

// note: encodeURIComponent is available via browser (window) or natively in node.js
// if you use another js engine for server-side rendering you may not have native encodeURIComponent
// and would then need to install a package for that functionality

function getQueryString(params) {
  return Object.keys(params)
    .map(function (k) {
      return encodeURIComponent(k) + '=' + encodeURIComponent(params[k]);
    })
    .join('&');
}

function fetchData(url, fetcher, params) {
  if (params === void 0) {
    params = 1;
  }
  var qs = getQueryString(params);
  var fetchUrl = url.indexOf('?') !== -1 ? url + '&' + qs : url + '?' + qs;
  // Home Page
  // var fetchUrl =
  //   'https://dxp-hospitality-dev-rg-sit-467300-cm.azurewebsites.net/sitecore/api/layout/render/mobile-jss?item={9E853D6B-ACF8-4B82-9C26-D081689EA30E}&sc_apikey={818C6ACF-67CE-4D54-859E-0E47C79150AF}';
  // Regular Page
  // var fetchUrl =
  //   'https://dxp-hospitality-dev-rg-sit-467300-cm.azurewebsites.net/sitecore/api/layout/render/mobile-jss?item={FD4A392A-5B67-46B5-AB40-3E967DC591DA}&sc_apikey={818C6ACF-67CE-4D54-859E-0E47C79150AF}';
  // Cart Page
  // var fetchUrl =
  //   'https://dxp-hospitality-dev-rg-sit-467300-cm.azurewebsites.net/sitecore/api/layout/render/mobile-jss?item=%7B7007FAFA-C9BE-4FEB-B21A-48D5E4B2E05D%7D&sc_apikey=%7b818C6ACF-67CE-4D54-859E-0E47C79150AF%7d';
  // Checkout Page
  // var fetchUrl =
  //   'https://dxp-hospitality-dev-rg-sit-467300-cm.azurewebsites.net/sitecore/api/layout/render/mobile-jss?item={D3AF54FB-D877-4A69-B4F6-B2F455111177}&sc_apikey={818C6ACF-67CE-4D54-859E-0E47C79150AF}';
  // CustomizeCake
  // var fetchUrl =
  //   'https://dxp-hospitality-dev-rg-sit-467300-cm.azurewebsites.net/sitecore/api/layout/render/mobile-jss?item={0DCA224D-91A0-43FA-82DB-7A1B303FBFF0}&sc_apikey={818C6ACF-67CE-4D54-859E-0E47C79150AF}';
  // Booking Confirmation
  // var fetchUrl =
  //   'https://dxp-hospitality-dev-rg-sit-467300-cm.azurewebsites.net/sitecore/api/layout/render/mobile-jss?item={97B4B382-AE19-4772-B92E-C938D800A8C1}&sc_apikey={818C6ACF-67CE-4D54-859E-0E47C79150AF}';
  // Admin page
  // var fetchUrl =
  // 'https://dxp-hospitality-dev-rg-sit-467300-cm.azurewebsites.net/sitecore/api/layout/render/mobile-jss?item={B5EB55D7-B749-4239-917F-A76144A16CDD}&sc_apikey={818C6ACF-67CE-4D54-859E-0E47C79150AF}';
  // Admin Call Center
  // var fetchUrl =
  //   'https://dxp-hospitality-dev-rg-sit-467300-cm.azurewebsites.net/sitecore/api/layout/render/mobile-jss?item={B5EB55D7-B749-4239-917F-A76144A16CDD}&sc_apikey={818C6ACF-67CE-4D54-859E-0E47C79150AF}';
  // Admin Orders
  // var fetchUrl =
  //   'https://dxp-hospitality-dev-rg-sit-467300-cm.azurewebsites.net/sitecore/api/layout/render/mobile-jss?item={B22752BA-5213-4B27-BC3E-601B9BFDEF7B}&sc_apikey={818C6ACF-67CE-4D54-859E-0E47C79150AF}';
  // Admin Dashboard
  // var fetchUrl =
  //   'https://dxp-hospitality-dev-rg-sit-467300-cm.azurewebsites.net/sitecore/api/layout/render/mobile-jss?item={3DD85C40-D621-475D-912B-D0E8404926F2}&sc_apikey={818C6ACF-67CE-4D54-859E-0E47C79150AF}';
  // Admin Kithche Display
  // var fetchUrl =
  //   'https://dxp-hospitality-dev-rg-sit-467300-cm.azurewebsites.net/sitecore/api/layout/render/mobile-jss?item={184DD680-BC40-43CB-A4D2-C14534E01866}&sc_apikey={818C6ACF-67CE-4D54-859E-0E47C79150AF}';
  // Admin Employees
  // var fetchUrl =
  //   'https://dxp-hospitality-dev-rg-sit-467300-cm.azurewebsites.net/sitecore/api/layout/render/mobile-jss?item={F1DDBF2A-116E-41E1-9A61-0467CA0D0240}&sc_apikey={818C6ACF-67CE-4D54-859E-0E47C79150AF}';
  // User Reset Password
  // var fetchUrl =
  //   'https://dxp-hospitality-dev-rg-sit-467300-cm.azurewebsites.net/sitecore/api/layout/render/mobile-jss?item={126A8525-CAE7-4047-BD56-7E75F67ACA8C}&sc_apikey={818C6ACF-67CE-4D54-859E-0E47C79150AF}';
  // User Order History
  // var fetchUrl =
  //   'https://dxp-hospitality-dev-rg-sit-467300-cm.azurewebsites.net/sitecore/api/layout/render/mobile-jss?item={E0406AB0-B2BA-485A-AB65-EF77AD0BFA50}&sc_apikey={818C6ACF-67CE-4D54-859E-0E47C79150AF}';
  // User Contact Us
  // var fetchUrl =
  //   'https://dxp-hospitality-dev-rg-sit-467300-cm.azurewebsites.net/sitecore/api/layout/render/mobile-jss?item={8D924B4B-60B7-419D-B1E7-BAF2F84A3AB7}&sc_apikey={818C6ACF-67CE-4D54-859E-0E47C79150AF}';

  return fetcher(fetchUrl)
    .then(checkStatus)
    .then(function (response) {
      return response.data;
    });
}

var resolveLayoutServiceUrl = function resolveLayoutServiceUrl(options, verb) {
  if (options === void 0) {
    options = {};
  }
  var _a = options.host,
    host = _a === void 0 ? '' : _a,
    _b = options.configurationName,
    configurationName = _b === void 0 ? 'jss' : _b,
    serviceUrl = options.serviceUrl;
  if (serviceUrl) {
    return serviceUrl;
  }
  return host + '/margauxsitecore/api/layout/' + verb + '/' + configurationName;
};

/**
 * Makes a request to Sitecore Layout Service for the specified route item path.
 */

function fetchRouteData(itemPath, options) {
  var querystringParams = options.querystringParams,
    layoutServiceConfig = options.layoutServiceConfig;
  var fetchUrl = resolveLayoutServiceUrl(layoutServiceConfig, 'render');
  return fetchData(
    fetchUrl,
    options.fetcher,
    __assign(
      {
        item: itemPath,
      },
      querystringParams
    )
  );
}

exports.fetchRouteData = fetchRouteData;

/**
 * Makes a request to Sitecore Layout Service for the specified placeholder in
 * a specific route item. Allows you to retrieve rendered data for individual placeholders instead of entire routes.
 */

function fetchPlaceholderData(placeholderName, itemPath, options) {
  var querystringParams = options.querystringParams,
    layoutServiceConfig = options.layoutServiceConfig;
  var fetchUrl = resolveLayoutServiceUrl(layoutServiceConfig, 'placeholder');
  return fetchData(
    fetchUrl,
    options.fetcher,
    __assign(
      {
        placeholderName: placeholderName,
        item: itemPath,
      },
      querystringParams
    )
  );
}

exports.fetchPlaceholderData = fetchPlaceholderData;
