import React, { useState, Fragment, useEffect } from 'react';
import { useToasts } from 'react-toast-notifications';
import { useForm } from 'react-hook-form';
import { alphaNumeric } from '../../helpers/Patterns';
import dic from 'i18next';

const PromoCode = ({ promocodes, Currentpromocode, setCurrentpromocode }) => {
  // const [Currentpromocode, setCurrentpromocode] = useState({});
  const [Wrongpromocode, setWrongPromocode] = useState(false);
  const PromoCodeList = [];
  promocodes &&
    promocodes.map((item, index) => {
      const title = item?.fields?.Title?.value;
      const code = item?.fields?.Code?.value;
      PromoCodeList.push({
        Title: title,
        Value: code,
      });
    });
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const onSubmits = (data) => {
    let promocode = data.Promocode.toUpperCase();
    let filterarray = PromoCodeList.filter((item) => item.Title.toUpperCase() === promocode);
    if (filterarray.length > 0) {
      setCurrentpromocode(filterarray[0]);
    } else {
      setWrongPromocode(true);
    }
  };
  return (
    <Fragment>
      <div className="sub-accordition my-3">
        <div className="accord-title tracking-wider text-black font-bold p-4 bg-white">
          <span className="bg-black text-white text-xs font-semibold mr-2 px-2.5 py-1 rounded">
            4
          </span>
          PROMOCODE
        </div>
        <div className="accord-description border border-white py-4 px-5">
          <form className="w-full max-w-sm" onSubmit={handleSubmit(onSubmits)}>
            <div className="flex items-center border-b border-gray-500 py-2">
              <input
                className="appearance-none bg-transparent border-none w-full text-white mr-3 py-1 px-2 leading-tight focus:outline-none"
                type="text"
                placeholder="Apply Promocode"
                {...register('Promocode', {
                  required: 'Promocode is Required',
                  pattern: {
                    value: alphaNumeric,
                    message: 'Please enter a valid Promocode',
                  },
                })}
                onChange={() => {
                  setCurrentpromocode({});
                  setWrongPromocode(false);
                }}
                autoComplete="off"
              />
              <button
                className="flex-shrink-0 bg-gray-500 hover:bg-gray-700 border-gray-500 hover:border-gray-700 text-sm border-4 text-white py-1 px-2 rounded"
                type="submit"
              >
                Apply
              </button>
              <button
                className="flex-shrink-0 ml-2 bg-gray-500 hover:bg-gray-700 border-gray-500 hover:border-gray-700 text-sm border-4 text-white py-1 px-2 rounded"
                type="button"
                onClick={() => {
                  reset();
                  setCurrentpromocode({});
                  setWrongPromocode(false);
                }}
              >
                Cancel
              </button>
            </div>
            {errors.Promocode && errors.Promocode.message && (
              <span className=" mt-4 text-xs text-red-400">{errors.Promocode.message}</span>
            )}
            {Currentpromocode && Object.keys(Currentpromocode).length > 0 && (
              <span className=" mt-4 text-xs text-green-600">
                {Currentpromocode.Title} has been applied
              </span>
            )}
            {Wrongpromocode && (
              <span className=" mt-4 text-xs text-red-600">Invalid Promocode</span>
            )}
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default PromoCode;
