import React, { Fragment } from 'react';

const DetailesLoader = () => {
  return (
    <Fragment>
      <div className="grid gap-6 mb-4 md:grid-cols-2 xl:grid-cols-2 px-4 mt-4">
        <div className="min-w-0 rounded-lg h-10 shadow-md border-2 border-gray-200 overflow-hidden animate-pulse bg-gray-300"></div>
        <div className="min-w-0 rounded-lg h-10 shadow-md border-2 border-gray-200 overflow-hidden animate-pulse bg-gray-300"></div>
        <div className="min-w-0 rounded-lg h-10 shadow-md border-2 border-gray-200 overflow-hidden animate-pulse bg-gray-300"></div>
        <div className="min-w-0 rounded-lg h-10 shadow-md border-2 border-gray-200 overflow-hidden animate-pulse bg-gray-300"></div>
        <div className="min-w-0 rounded-lg h-10 shadow-md border-2 border-gray-200 overflow-hidden animate-pulse bg-gray-300"></div>
        <div className="min-w-0 rounded-lg h-10 shadow-md border-2 border-gray-200 overflow-hidden animate-pulse bg-gray-300"></div>
      </div>
    </Fragment>
  );
};

export default DetailesLoader;
