import axios from 'axios';
import * as helpers from '../helpers/authorizationToken';
import { MicroRoutes } from '../constants';
import * as session from '../helpers/config';

export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const CLEAR_UPDATE_STATE = 'CLEAR_UPDATE_STATE';
export const DECRYPT_STATUS = 'DECRYPT_STATUS';
export const DECRYPT_STATUS_REVERT = 'DECRYPT_STATUS_REVERT';
export const onUserResetPassword = (data) => async (dispatch) => {
  dispatch({ type: UPDATE_PASSWORD, loadingstatus: true });
  const ResetData = {
    newPassword: data.password,
    userEmailId: data.email,
    token: data.token,
  };
  let payload = session.encryptePayload(ResetData);
  await axios
    .post(MicroRoutes.update, payload)
    .then((response) => {
      if (response?.data?.errorStatus) {
        dispatch({
          type: UPDATE_PASSWORD,
          loadingstatus: false,
          statusCode: 400,
          payload: response?.data?.message,
        });
      } else {
        dispatch({
          type: UPDATE_PASSWORD,
          statusCode: 200,
          loadingstatus: false,
          payload: 'Passsword Updated Successfully...!',
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: UPDATE_PASSWORD,
        loadingstatus: false,
        payload: error?.response?.data?.message,
      });
    });
};

export const CheckTokenValidation = (data) => async (dispatch) => {
  await axios
    .post(MicroRoutes.decryptToken + '?token=' + data)
    .then((response) => {
      if (response?.data?.errorStatus) {
        dispatch({
          type: DECRYPT_STATUS,
          error: response.data.message,
          status: 404,
          loadingtoken: true,
        });
      } else {
        dispatch({
          type: DECRYPT_STATUS,
          status: 200,
          loadingtoken: true,
          email: response.data.userEmailId,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: DECRYPT_STATUS,
        error: error.response.data.message,
        status: error.response.status,
        loadingtoken: true,
      });
    });
};

export const RevertReset = (data) => async (dispatch) => {
  dispatch({
    type: DECRYPT_STATUS_REVERT,
  });
};
