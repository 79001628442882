import React, { Fragment, useState } from 'react';
import { MdClose } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { emailPattern } from '../../../helpers/Patterns';
import { colourStyles } from '../../../helpers/Patterns';
import Select from 'react-select';
import { setCustomerDeliveryInfo } from '../../../actions/admin/CallCenter.action';
import dic from 'i18next';
import { useEffect } from 'react';

const EditAddress = ({ setEditModal, deliverycharges, regionlist, setdptime }) => {
  const dispatch = useDispatch();
  const { deliveryAddress } = useSelector((state) => state.callcenter.customerDeliveryInfo);
  const [deliveryTime, setDeliveryTime] = useState([]);
  const [getRegion, setRegion] = useState('');
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({ defaultValues: { region: '' } });
  const onSubmits = (data) => {
    let deliverCharge = 0;
    deliverycharges.filter((item, key) => {
      if (
        data.region.toLowerCase() === item?.fields?.Region?.fields?.Region?.value?.toLowerCase()
      ) {
        deliverCharge = item.fields.Price.value;
      }
    });
    const deliveryAddress = {
      region: data.region,
      makani: data.makahani,
      address: data.address,
      deliveryTime: data.deliveryTime,
      deliveryPrice: Number(deliverCharge),
    };
    setdptime(data.deliveryTime);
    dispatch(setCustomerDeliveryInfo({ deliveryAddress }));
    reset();
    setEditModal(false);
  };
  let deliverytimeList = [];
  const checkRegion = (e) => {
    const getregiontime = deliverycharges.filter((item) => {
      if (
        e.target.value.toLowerCase() === item?.fields?.Region?.fields?.Region?.value?.toLowerCase()
      ) {
        return item;
      }
    });
    setRegion(e.target.value);
    if (getregiontime.length > 0) {
      const call_center_time = getregiontime[0].fields.Region.fields['Call Center Timings'];
      call_center_time.map((items, key) => {
        const time = items.fields.Title.value;
        deliverytimeList.push({
          label: time,
          deliveryPrice: items,
          value: time,
        });
      });
      setDeliveryTime(deliverytimeList);
    }
  };

  useEffect(() => {
    const getregiontime = deliverycharges.filter((item) => {
      if (
        deliveryAddress?.region.toLowerCase() ===
        item?.fields?.Region?.fields?.Region?.value?.toLowerCase()
      ) {
        return item;
      }
    });
    setRegion(deliveryAddress.region);
    if (getregiontime.length > 0) {
      const call_center_time = getregiontime[0].fields.Region.fields['Call Center Timings'];
      call_center_time.map((items, key) => {
        const time = items.fields.Title.value;
        deliverytimeList.push({
          label: time,
          deliveryPrice: items,
          value: time,
        });
      });
      setDeliveryTime(deliverytimeList);
    }
  }, []);
  return (
    <Fragment>
      <div className="fixed z-10 overflow-y-auto top-0 w-full left-0" id="CustomerAddress">
        <div className="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-black opacity-75" />
          </div>
          <span className="inline-block align-middle h-screen">&#8203;</span>
          <div
            className="inline-block  align-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            {/* Body Start */}
            <div className="bg-gray-100 rounded px-8 pt-6 pb-6 w-full z-10">
              <button
                type="button"
                className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                onClick={() => setEditModal(false)}
              >
                <MdClose className="w-5 h-5" />
              </button>
              <h1 className="block text-black-700 text-xl font-bold mb-4">Edit Address</h1>
              <form className="pt-1 px-1" onSubmit={handleSubmit(onSubmits)}>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <select
                      {...register('region', {
                        required: dic.t('Margaux-Region is Required'),
                      })}
                      onChange={(e) => checkRegion(e)}
                      className="form-select form-select-sm rounded-lg appearance-none block w-full px-2 py-1 text-base font-normal text-gray-700 bg-transparent bg-clip-padding bg-no-repeat border border-solid border-gray-300 transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                    >
                      <option value="" selected>
                        Region *
                      </option>
                      {regionlist.map((item) => {
                        return (
                          <>
                            <option value={item.label} selected={getRegion === item.label}>
                              {item.label}
                            </option>
                          </>
                        );
                      })}
                    </select>
                    {errors.region && errors.region.message ? (
                      <span className="text-red-400 text-sm">{errors.region.message}</span>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="relative z-0 w-full hidden">
                    <input
                      type="text"
                      className="form-select form-select-sm rounded-lg appearance-none block w-full px-2 py-1 text-base font-normal text-gray-700 bg-transparent bg-clip-padding bg-no-repeat border border-solid border-gray-300 transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                      placeholder={dic.t('Margaux-Makani No')}
                      {...register('makahani')}
                      defaultValue={deliveryAddress.makani}
                      autoComplete="off"
                    />
                    {errors.makahani && errors.makahani.message && (
                      <span className="text-red-400 text-sm">{errors.makahani.message}</span>
                    )}
                  </div>
                  <div className="">
                    <input
                      type="text"
                      className="form-select form-select-sm rounded-lg appearance-none block w-full px-2 py-1 text-base font-normal text-gray-700 bg-transparent bg-clip-padding bg-no-repeat border border-solid border-gray-300 transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                      placeholder={`${dic.t('Margaux-Detailed Address') + ' *'}`}
                      {...register('address', {
                        required: 'Address is Required',
                      })}
                      defaultValue={deliveryAddress.address}
                      autoComplete="off"
                    />
                    {errors.address && errors.address.message && (
                      <span className="text-red-400 text-sm">{errors.address.message}</span>
                    )}
                  </div>
                  <div>
                    <select
                      {...register('deliveryTime', {
                        required: 'Delivery Time is required',
                      })}
                      className="form-select form-select-sm rounded-lg appearance-none block w-full px-2 py-1 text-base font-normal text-gray-700 bg-transparent bg-clip-padding bg-no-repeat border border-solid border-gray-300 transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                    >
                      <option value="" selected>
                        Delivery Time *
                      </option>
                      {deliveryTime.map((item, key) => {
                        return (
                          <>
                            <option
                              value={item.label}
                              selected={deliveryAddress.deliveryTime === item.label ? true : false}
                            >
                              {item.label}
                            </option>
                          </>
                        );
                      })}
                    </select>
                    {errors.deliveryTime && errors.deliveryTime.message ? (
                      <span className="text-red-400 text-sm">{errors.deliveryTime.message}</span>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="text-center col-span-2">
                    <button
                      type="submit"
                      className="text-white my-2 bg-gray-700  hover:bg-gray-500 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2 text-center"
                    >
                      Update
                    </button>
                  </div>
                </div>
              </form>
            </div>
            {/* Body End */}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default EditAddress;
