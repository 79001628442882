import React from 'react';
import { BsXCircleFill } from 'react-icons/bs';
import dic from 'i18next';
import { useForm, Controller } from 'react-hook-form';

export default function AddAddress({
  modalStatus,
  AddAddressModal,
  deliveryTimeRegion,
  CheckRegionListCharge,
  deliveryTime,
  onDeliveryAddressSubmit,
  deliveryRegion,
}) {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm();
  return (
    <div>
      <div
        className={`fixed z-20 overflow-y-auto top-0 w-full left-0 ${
          modalStatus === true ? '' : 'hidden'
        }`}
        id="servicemodalefef"
      >
        <div className="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-black opacity-75" />
          </div>
          <span className="flex h-screen justify-center items-center sm:inline-block sm:align-middle sm:h-screen">
            &#8203;
          </span>
          <div
            className="inline-block  align-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-md  w-full"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="bg-white sm:pb-4">
              {/* Body Start */}
              <div className="modal-body">
                <h4 className="text-base sm:text-base text-white text-center bg-gray-700 font-semibold tracking-wider py-3">
                  Add Address
                </h4>
                <div className="position-relative">
                  <BsXCircleFill
                    onClick={() => AddAddressModal(false)}
                    className="absolute text-white right-[17px] top-[19px] cursor-pointer text-lg"
                  />
                </div>
                <div className="text-black px-3 sm:px-5 py-2 sm:overflow-x-auto  scrollbar-thumb-gray-700 scrollbar-track-gray-100 scrollbar-thin  overflow-x-auto max-h-screen">
                  <div className="flex flex-col scrollbar-thumb-gray-700 scrollbar-track-gray-100 scrollbar-thin  overflow-x-auto">
                    <form className="pt-2 px-1" onSubmit={handleSubmit(onDeliveryAddressSubmit)}>
                      <div className="py-1 px-2">
                        <label className="block mb-2 text-sm font-bold text-black">
                          Region *
                          {errors.region && errors.region.message && (
                            <span className="text-red-400 text-xs absolute pt-1">
                              &nbsp;({errors.region.message})
                            </span>
                          )}
                        </label>
                        <select
                          className="form-select block w-full px-3 py-2 text-sm font-medium text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-400 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                          {...register('region', {
                            required: 'Region is Required',
                          })}
                          onChange={(e) => CheckRegionListCharge(e.target.value)}
                        >
                          <option value="" className="tracking-widest">
                            Select Region
                          </option>
                          {deliveryTimeRegion?.map((value, key) => {
                            return (
                              value?.fields?.Region?.fields?.Region?.value && (
                                <option
                                  value={value?.fields?.Region?.fields?.Region?.value}
                                  selected={
                                    value?.fields?.Region?.fields?.Region?.value === deliveryRegion
                                  }
                                >
                                  {value?.fields?.Region?.fields?.Region?.value}
                                </option>
                              )
                            );
                          })}
                        </select>
                      </div>
                      <div className="py-1 px-2 hidden">
                        <label className="block mb-2 text-sm font-bold text-black">
                          {dic.t('Margaux-Makani No')}
                        </label>
                        <input
                          type="text"
                          {...register('makani')}
                          className="block w-full px-3 py-2 text-sm font-medium text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-400 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                          placeholder={dic.t('Margaux-Makani No')}
                          maxLength={10}
                          minLength={1}
                          autoComplete="off"
                        />
                      </div>
                      <div className="py-1 px-2">
                        <label className="block mb-2 text-sm font-bold text-black">
                          {dic.t('Margaux-Address')} *
                          {errors.address && errors.address.message && (
                            <span className="text-red-400 text-xs absolute pt-1">
                              &nbsp;({errors.address.message})
                            </span>
                          )}
                        </label>
                        <input
                          type="text"
                          {...register('address', {
                            required: 'Address is Required',
                          })}
                          className="block w-full px-3 py-2 text-sm font-medium text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-400 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                          placeholder={dic.t('Margaux-Address')}
                          maxLength={50}
                          autoComplete="off"
                        />
                      </div>
                      <div className="py-1 px-2">
                        <label className="block mb-2 text-sm font-bold text-black">
                          Delivery Time *
                          {errors.deliverytime && errors.deliverytime.message && (
                            <span className="text-red-400 text-xs absolute pt-1">
                              &nbsp;({errors.deliverytime.message})
                            </span>
                          )}
                        </label>
                        <select
                          className="form-select block w-full px-3 py-2 text-sm font-medium text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-400 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                          {...register('deliverytime', {
                            required: 'Delivery Time is Required',
                          })}
                        >
                          {deliveryTime.length > 0 ? (
                            deliveryTime[0]?.fields?.Region?.fields?.Timings.map((item, key) => {
                              return (
                                <>
                                  {key === 0 ? (
                                    <option value="" selected>
                                      Select Time
                                    </option>
                                  ) : (
                                    <></>
                                  )}
                                  <option value={item?.fields?.Title?.value} key={key}>
                                    {item?.fields?.Title?.value}
                                  </option>
                                </>
                              );
                            })
                          ) : (
                            <option value="" selected>
                              Select Time
                            </option>
                          )}
                        </select>
                      </div>
                      <div className="text-center pt-4">
                        <button
                          type="submit"
                          className="w-56 text-white bg-gray-700 font-semibold text-sm rounded-lg px-10 py-2.5  tracking-wider"
                        >
                          Add Address
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              {/* Body End */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
