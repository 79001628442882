import React, { useState, Fragment, useEffect } from 'react';
import { MdClose } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { useForm } from 'react-hook-form';
import { emailPattern, letters, alphaNumeric, phonepattern } from '../../../helpers/Patterns';
import { onUserRegistration, onClearRegistrationState } from '../../../actions/registration.action';
import { colourStyles } from '../../../helpers/Patterns';
import { GiCook } from 'react-icons/gi';
import { RiCustomerService2Fill, RiAdminFill } from 'react-icons/ri';
import { IconContext } from 'react-icons/lib';
import Loader from '../../Loader/Loader';
import * as helpers from '../../../helpers/config';
import { useToasts } from 'react-toast-notifications';
import { onUserList } from '../../../actions/admin/Userlist.action';
import ResponseShow from '../../ResponseShow/ResponseShow';
import dic from 'i18next';

const AddNewForm = ({ closeAddNewModal }) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { roles } = useSelector((state) => state.allroles);
  const { statuscode, loading, error } = useSelector((state) => state.registration);
  const [selectedRadio, setSelectedRadio] = useState(4);
  const [temproles, settemproles] = useState();
  useEffect(() => {
    settemproles(roles);
  }, [roles]);

  // taking logged user data from storage
  const loggeddetails = helpers.LoggedUserDetails();
  const usertype = loggeddetails?.accessDetails?.role;
  const userEmailId = loggeddetails?.userEmailId;
  const userlistdata = {
    userEmailId: userEmailId,
    userType: usertype,
  };

  useEffect(() => {
    if (error === '' && statuscode !== '') {
      dispatch(onUserList(userlistdata));
      closeAddNewModal();
      dispatch(onClearRegistrationState());
    }
  }, [statuscode]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const onSubmits = (data) => {
    const logindata = {
      consent: true,
      marketingConsent: true,
      phoneNumber: data.phone,
      userEmail: data.email,
      userFirstName: data.firstname,
      userLastName: data.lastname,
      requestType: 'ADMIN',
      roleId: selectedRadio,
      orgId: data.orgId,
    };
    dispatch(onUserRegistration(logindata)).then(() => {
      reset();
    });
  };
  return (
    <Fragment>
      <div className="rounded px-8 pt-6 pb-8 w-full">
        {loading ? (
          <Loader />
        ) : (
          <>
            <ResponseShow success={statuscode} error={error} />
            <form className="grid grid-cols-12 gap-2" onSubmit={handleSubmit(onSubmits)}>
              <div className="col-span-12 mb-2">
                <h3 className="font-semibold text-gray-900">
                  {dic.t('Margaux-Create New Employee')}
                </h3>
                <button
                  type="button"
                  className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                  onClick={() => {
                    reset();
                    closeAddNewModal();
                    dispatch(onClearRegistrationState());
                  }}
                >
                  <MdClose className="w-5 h-5" />
                </button>
              </div>
              <div className="w-full mb-3 col-span-12">
                <input
                  type="text"
                  name="org_id"
                  maxLength={20}
                  className="border-0 px-3 py-3 tracking-wider placeholder-gray-500 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Jumeriah ID"
                  {...register('orgId', {
                    required: 'ID is Required',
                    pattern: {
                      value: alphaNumeric,
                      message: 'Please enter a valid ID',
                    },
                  })}
                  autoComplete="off"
                />
                {errors.orgId && errors.orgId.message && (
                  <span className=" mt-4 text-xs text-red-400">{errors.orgId.message}</span>
                )}
              </div>
              <div className="w-full mb-3 col-span-6">
                <input
                  type="text"
                  name="user_name"
                  maxLength={20}
                  className="border-0 px-3 py-3 tracking-wider placeholder-gray-500 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder={dic.t('Margaux-First Name')}
                  {...register('firstname', {
                    required: 'Name is Required',
                    pattern: {
                      value: letters,
                      message: 'Please enter a valid first name',
                    },
                  })}
                  autoComplete="off"
                />
                {errors.firstname && errors.firstname.message && (
                  <span className=" mt-4 text-xs text-red-400">{errors.firstname.message}</span>
                )}
              </div>
              <div className="w-full mb-3 col-span-6">
                <input
                  type="text"
                  className="border-0 px-3 py-3 tracking-wider placeholder-gray-500 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder={dic.t('Margaux-Last Name')}
                  maxLength={10}
                  {...register('lastname', {
                    required: 'Name is Required',
                    pattern: {
                      value: letters,
                      message: 'Please enter a valid last name',
                    },
                  })}
                  autoComplete="off"
                />
                {errors.lastname && errors.lastname.message && (
                  <span className=" mt-4 text-xs text-red-400">{errors.lastname.message}</span>
                )}
              </div>
              <div className="w-full mb-3 col-span-6">
                <input
                  type="text"
                  maxLength={50}
                  className="border-0 px-3 py-3 tracking-wider placeholder-gray-500 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder={dic.t('Margaux-Email')}
                  {...register('email', {
                    required: 'Email is Required',
                    pattern: {
                      value: emailPattern,
                      message: 'Please enter a valid email',
                    },
                  })}
                  autoComplete="off"
                />
                {errors.email && errors.email.message && (
                  <span className="mt-4 text-xs text-red-400">{errors.email.message}</span>
                )}
              </div>
              <div className="w-full mb-3 col-span-6">
                <input
                  type="text"
                  className="border-0 px-3 py-3 tracking-wider placeholder-gray-500 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder={dic.t('Margaux-Phone Number')}
                  {...register('phone', {
                    required: 'Phone is Required',
                    pattern: {
                      value: phonepattern,
                      message: 'Please enter a valid phone',
                    },
                  })}
                  autoComplete="off"
                />
                {errors.phone && errors.phone.message && (
                  <span className="text-xs mt-4 text-red-400">{errors.phone.message}</span>
                )}
              </div>
              <div className="col-span-12">
                <div className="grid grid-cols-1 sm:grid-cols-3 gap-2">
                  {temproles &&
                    temproles.map((item, index) => {
                      return (
                        <div
                          className={`border-2 text-gray-400 rounded-md shadow-md p-[0.4rem] ${
                            selectedRadio === item.role_id ? 'border-gray-500' : 'border-gray-200'
                          } hover:cursor-pointer flex`}
                          onClick={() => setSelectedRadio(item.role_id)}
                          key={index}
                        >
                          <div className="flex items-center">
                            <IconContext.Provider
                              value={{
                                className: `inline-flex ${
                                  selectedRadio === item.role_id && 'text-gray-800'
                                }`,
                              }}
                            >
                              {item.role === 'KITCHEN_USER' && <GiCook size={20} />}
                              {item.role === 'ADMIN_USER' && <RiAdminFill size={20} />}
                              {item.role === 'CENTER_USER' && <RiCustomerService2Fill size={20} />}
                              {item.role === 'SUPER_ADMIN' && <RiAdminFill size={20} />}
                            </IconContext.Provider>
                          </div>
                          <div className="ml-2 text-[0.840rem]">
                            <label htmlFor="helper-checkbox" className="font-medium text-gray-900">
                              {item.role === 'KITCHEN_USER' && <>KITCHEN</>}
                              {item.role === 'ADMIN_USER' && <>ADMIN</>}
                              {item.role === 'CENTER_USER' && <>CALL CENTER</>}
                              {item.role === 'SUPER_ADMIN' && <>SUPER ADMIN</>}
                            </label>
                          </div>
                        </div>
                      );
                    })}
                </div>
                <div className="col-span-12 mt-2 text-right">
                  <button
                    type="submit"
                    className="text-white my-2 bg-gray-700 tracking-wider hover:bg-gray-500 font-medium rounded-lg text-md w-full px-5 py-2 text-center"
                  >
                    Create
                  </button>
                </div>
              </div>
            </form>
          </>
        )}
      </div>
    </Fragment>
  );
};

export default AddNewForm;
