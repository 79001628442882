import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { onCartAction } from '../../actions/cart/addtocart.action';
import * as helpers from '../../helpers/config';
import DeliveryAddress from './DeliveryAddress';
import LoginCheckout from './LoginCheckout';
import OrderSummary from './OrderSummary';
import Payment from './Payment';
import dic from 'i18next';
import PromoCode from './PromoCode';

function Checkout(props) {
  const dispatch = useDispatch();
  const cart = localStorage.getItem('cartproducts');
  const jsoncart = JSON.parse(cart);
  const [cartItems, setCartItems] = useState(jsoncart || []);
  const [getdeliverycharge, setDeliverycharge] = useState(false);
  // const [isFestival, setIsFestival] = useState(false);
  const [Currentpromocode, setCurrentpromocode] = useState({});
  // const getregion = props?.fields?.Regions;
  const promocodes = props?.fields?.['Promo Codes'];
  const deliveryTime = props?.fields?.['Delivery Charges'];
  const deliveryChargelist = props?.fields?.['Delivery Charges'];
  const pickupTime = props?.fields?.['Pickup Time'];
  const deliverycharge = useSelector((state) => state.deliverycharge.ordercharge);
  const addonpricelist = [];
  let isFestival = false;
  localStorage.removeItem('order_Id');
  localStorage.removeItem('getcartproduct');
  useEffect(() => {
    if (deliverycharge !== undefined) {
      setDeliverycharge(deliverycharge);
    }
  }, [deliverycharge]);

  cartItems.map((item, keys) => {
    item.cartaddedservice.map((serviceitem, getkey) => {
      if (serviceitem?.addon?.Price !== undefined) {
        addonpricelist.push(serviceitem?.addon?.Price);
      }
    });
    if (item.ItemDetails.title === 'Vanilla-Hazelnut Signature Buche') {
      isFestival = true;
    }
    if (item.ItemDetails.title === 'Chocolate Buche') {
      isFestival = true;
    }
    if (item.ItemDetails.title === 'Chestnut Raspberry Buche') {
      isFestival = true;
    }
  });
  useEffect(() => {
    window !== 'undefined' && window.scroll(0, 500);
  }, []);
  const SubTotal =
    parseInt(helpers.CartSubtotalSum(cartItems)) + parseInt(helpers.ArraySum(addonpricelist));
  const Discount =
    Object.keys(Currentpromocode).length > 0
      ? (SubTotal * parseInt(Currentpromocode.Value)) / 100
      : 0;
  const CouponCode = Object.keys(Currentpromocode).length > 0 ? Currentpromocode.Title : '';
  return (
    <>
      <div className="container-fluid pb-10 bg-black">
        <div className="lg:flex lg:flex-row ">
          <div className="lg:basis-3/4 lg:px-10 px-4 pt-8 lg:pt-0 lg:pl-[4rem]  border-white-500">
            <div className="main-accordition">
              <LoginCheckout />
              <DeliveryAddress
                // getregion={getregion}
                pickupTime={pickupTime}
                isFestival={isFestival}
                deliveryCharge={deliveryChargelist}
                deliveryTimeRegion={deliveryTime}
              />
              <OrderSummary />
              <PromoCode
                promocodes={promocodes}
                setCurrentpromocode={setCurrentpromocode}
                Currentpromocode={Currentpromocode}
              />
              <Payment Discount={Discount} CouponCode={CouponCode} />
            </div>
          </div>
          <div className="lg:basis-1/5  basis-9/12  lg:pt-0 px-4 lg:px-0 text-white lg:pl-5 	">
            <div className="lg:sticky lg:self-baseline lg:top-[6rem] lg:border lg:px-5">
              <h5 className="font-bold pt-2 pb-3 uppercase xl:text-lg sm:text-md text-md">
                {dic.t('Margaux-Order Summary')}
              </h5>
              <p className="text-white text-xs">{dic.t('Margaux-Inclusive of Tax')}</p>
              <table className="w-full text-white">
                <tbody>
                  <tr className="text-left lg:text-base sm:text-md text-sm">
                    <th className="text-left font-medium py-2">{dic.t('Margaux-Sub Total')}</th>
                    <td className="text-center">{SubTotal}</td>
                  </tr>
                  {Currentpromocode && Object.keys(Currentpromocode).length > 0 && (
                    <tr className="text-left lg:text-base sm:text-md text-sm">
                      <th className="text-left font-medium py-2">{dic.t('Discount')}</th>
                      <td className="text-center text-sm">{Discount}</td>
                    </tr>
                  )}
                  {getdeliverycharge !== false && (
                    <tr className="text-left text-base">
                      <th className="text-left font-medium py-2">
                        {dic.t('Margaux-Delivery Charges')}
                      </th>
                      <td className="text-center">{getdeliverycharge}</td>
                    </tr>
                  )}

                  <tr className="text-left xl:text-lg sm:text-md text-md border-t-2 border-white-500">
                    <th className="text-left ">{dic.t('Margaux-Total Amount')}</th>

                    {getdeliverycharge !== false ? (
                      <td className="text-center pt-3 pb-2">
                        {parseInt(helpers.CartSubtotalSum(cartItems)) +
                          parseInt(helpers.ArraySum(addonpricelist)) +
                          parseInt(getdeliverycharge) -
                          parseInt(Discount)}
                      </td>
                    ) : (
                      <td className="text-center pt-3 pb-2 test">
                        {parseInt(helpers.CartSubtotalSum(cartItems)) +
                          parseInt(helpers.ArraySum(addonpricelist)) -
                          parseInt(Discount)}
                      </td>
                    )}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Checkout;
