import React, { useState, useEffect } from 'react';
import * as helpers from '../../helpers/config';
import ProductService from '../ProductService/index';
import { useDispatch, useSelector } from 'react-redux';
import { onCartAction } from '../../actions/cart/addtocart.action';
import { BsInfoCircle } from 'react-icons/bs';
import SorryCart from '../SorryCart/index';
import { Link } from 'react-router-dom';
import ViewMore from '../ViewMore';
import { RevertLoginStatus } from '../../actions/login.action';
import { BsSearch, BsFilter } from 'react-icons/bs';
import Decrement from '../CommonIcon/Decrement';
import Increment from '../CommonIcon/Increment';
import CustomizeService from '../CustomizeService';
import Cakes from './Cakes';
import dic from 'i18next';
import Flans from './Flans';
import Gateaux from './Gateaux';
import Tarts from './Tarts';

function CategorizedPastries(props) {
  const dispatch = useDispatch();
  const productshow = props?.fields?.Pastries;

  const CakesItem = props?.fields?.Cakes;
  const FlansItem = props?.fields?.Flans;
  const GateauxItem = props?.fields?.Gateaux;
  const TartsItem = props?.fields?.Tarts;
  const ServingValue = props?.fields?.Servings?.value;

  const [serviceUpdate, setServiceUpdate] = useState(false);
  const [customizeModal, setCustomizeModal] = useState(false);
  const [productAddons, setproductAddons] = useState(false);
  const [serviceModal, setServiceModal] = useState(false);
  const [getSorryStatus, setSorryModal] = useState(false);
  const [getViewMore, setViewMore] = useState(false);
  const [getDescription, SetDescription] = useState(false);
  const [customizeType, setCustomizeType] = useState('');
  const cartstatus = useSelector((state) => state.usercart.user_cart_status);
  const { existcartdata, existcartstatus } = useSelector((state) => state.cartlist);

  const sessionproducts = localStorage.getItem('cartproducts');
  const get_value = JSON.parse(sessionproducts);
  const [cartItems, setCartItems] = useState(get_value || []);
  var logoutstatus = useSelector((state) => state.login.logoutstatus);
  const [tempList, setTempList] = useState(productshow);
  const modalUpdateStatus = (items, addon, servicetype) => {
    setCustomizeType(servicetype);
    if (servicetype === 'customize') {
      setServiceModal(true);
      setCustomizeModal(false);
    } else if (servicetype === 'signature') {
      setServiceModal(false);
      setCustomizeModal(true);
    } else if (servicetype === 'macaroons') {
      setServiceModal(false);
      setCustomizeModal(true);
    }
    setServiceUpdate(items);
    setproductAddons(addon);
  };
  const closeModal = () => {
    setServiceModal(!serviceModal);
  };
  const closeModalCustomize = () => {
    setCustomizeModal(false);
  };
  const SorryCartStatusUpdate = () => {
    setSorryModal(!getSorryStatus);
  };
  const ViewMoreUpdate = (
    description,
    images,
    cakename,
    items,
    addons,
    type,
    exists,
    counts,
    findlowestprice
  ) => {
    const detail = {
      description: description,
      images: images,
      cakename: cakename,
      items: items,
      addons: addons,
      type: type,
      exists: exists,
      counts: counts,
      findlowestprice: findlowestprice,
    };
    SetDescription(detail);
    setViewMore(!getViewMore);
  };

  const onCartFunction = (
    items,
    serviceid,
    message,
    price,
    serves,
    addons,
    selectStatus,
    action,
    type
  ) => {
    if (serviceid === '') {
      return false;
    }
    let ProductType = '';
    if (type === 'customize') {
      setServiceModal(!serviceModal);
      ProductType = 'REGULAR';
    } else if (type === 'signature') {
      setCustomizeModal(false);
      ProductType = 'SIGNATUREBOX';
    } else if (type === 'macaroons') {
      setCustomizeModal(false);
      ProductType = 'MACAROONBOX';
    }
    var defaultValue = 1;
    setViewMore(false);
    // Product id equal checking
    const exist = cartItems.find((currentcart) => currentcart?.ItemDetails?.id === items?.id);
    // if product id match it is with inner service id checking
    if (exist) {
      const exist_service = exist?.cartaddedservice?.filter(
        (itemlist) => itemlist?.id === serviceid
      );
      const balance_service = exist?.cartaddedservice.filter(
        (itemlist) => itemlist?.id !== serviceid
      );
      if (exist_service && exist_service?.length > 0) {
        // Exist service price quantity added here
        const previouscart = cartItems?.filter(
          (currentcart) => currentcart?.ItemDetails?.id !== items?.id
        );
        const itemImage = items?.fields['Pastry Images'][0]?.fields?.Image?.value?.src;
        const itemTitle = items?.fields['Pastry Name']?.fields?.Title?.value;
        const ItemDetails = {
          id: items?.id,
          image: itemImage,
          title: itemTitle,
        };

        var updateresponse = [
          ...previouscart,
          {
            ItemDetails: ItemDetails,
            cartaddedservice: [
              ...balance_service,
              {
                id: serviceid,
                quantity: { value: exist_service[0]?.quantity?.value + defaultValue },
                message: { value: message },
                price: { value: price },
                serves: { value: serves },
                addon: addons,
                type: ProductType,
                signatureBox: type === 'signature' ? selectStatus : [],
                macaroonBox: type === 'macaroons' ? selectStatus : [],
              },
            ],
          },
        ];
        setCartItems(updateresponse);
        dispatch(onCartAction(updateresponse));
      } else {
        // Exist service price quantity intially added here
        const previouscart = cartItems?.filter(
          (currentcart) => currentcart?.ItemDetails?.id !== items?.id
        );
        const itemImage = items?.fields['Pastry Images'][0]?.fields?.Image?.value?.src;
        const itemTitle = items?.fields['Pastry Name']?.fields?.Title?.value;
        const ItemDetails = {
          id: items?.id,
          image: itemImage,
          title: itemTitle,
        };
        var updateresponse = [
          ...previouscart,
          {
            ItemDetails: ItemDetails,
            cartaddedservice: [
              ...exist.cartaddedservice,
              {
                id: serviceid,
                quantity: { value: defaultValue },
                message: { value: message },
                price: { value: price },
                serves: { value: serves },
                addon: addons,
                type: ProductType,
                signatureBox: type === 'signature' ? selectStatus : [],
                macaroonBox: type === 'macaroons' ? selectStatus : [],
              },
            ],
          },
        ];
        setCartItems(updateresponse);
        dispatch(onCartAction(updateresponse));
      }
    } else {
      const itemImage = items?.fields['Pastry Images'][0]?.fields?.Image?.value?.src;
      const itemTitle = items?.fields['Pastry Name']?.fields?.Title?.value;
      const ItemDetails = {
        id: items?.id,
        image: itemImage,
        title: itemTitle,
      };
      // intial product add to cart
      var updateresponse = [
        ...cartItems,
        {
          ItemDetails: ItemDetails,
          cartaddedservice: [
            {
              id: serviceid,
              quantity: { value: defaultValue },
              message: { value: message },
              price: { value: price },
              serves: { value: serves },
              addon: addons,
              type: ProductType,
              signatureBox: type === 'signature' ? selectStatus : [],
              macaroonBox: type === 'macaroons' ? selectStatus : [],
            },
          ],
        },
      ];
      setCartItems(updateresponse);
      dispatch(onCartAction(updateresponse));
    }
  };
  useEffect(() => {
    if (existcartstatus === true) {
      setCartItems(existcartdata);
    }
  }, [existcartdata]);
  useEffect(() => {
    localStorage.setItem('cartproducts', JSON.stringify(cartItems));
    if (logoutstatus === true) {
      dispatch(RevertLoginStatus());
      localStorage.setItem('cartproducts', JSON.stringify([]));
      setCartItems([]);
    }
  });
  const geturl = window.location.pathname;
  var customize_status = '';
  if (geturl.includes('signature-box')) {
    customize_status = 'signature';
  } else if (geturl.includes('macaroons')) {
    customize_status = 'macaroons';
  } else {
    customize_status = 'customize';
  }
  return (
    <div>
      <div className="bg-black text-white px-10 lg:pt-10 -z-10">
        <div className="container-fluid sm:px-6 md:m-0">
          <Gateaux
            GateauxItem={GateauxItem}
            cartItems={cartItems}
            modalUpdateStatus={modalUpdateStatus}
            customize_status={customize_status}
            SorryCartStatusUpdate={SorryCartStatusUpdate}
            ViewMoreUpdate={ViewMoreUpdate}
          />
          <Flans
            FlansItem={FlansItem}
            cartItems={cartItems}
            modalUpdateStatus={modalUpdateStatus}
            customize_status={customize_status}
            SorryCartStatusUpdate={SorryCartStatusUpdate}
            ViewMoreUpdate={ViewMoreUpdate}
          />

          <Tarts
            TartsItem={TartsItem}
            cartItems={cartItems}
            modalUpdateStatus={modalUpdateStatus}
            customize_status={customize_status}
            SorryCartStatusUpdate={SorryCartStatusUpdate}
            ViewMoreUpdate={ViewMoreUpdate}
          />
          <Cakes
            CakesItem={CakesItem}
            cartItems={cartItems}
            modalUpdateStatus={modalUpdateStatus}
            customize_status={customize_status}
            SorryCartStatusUpdate={SorryCartStatusUpdate}
            ViewMoreUpdate={ViewMoreUpdate}
          />

          {/* Product Service Start */}
          {serviceModal === true && (
            <>
              <ProductService
                modalStatus={serviceModal}
                modalUpdateStatus={modalUpdateStatus}
                productAddons={productAddons}
                servicelist={serviceUpdate}
                onCartFunction={onCartFunction}
                closeModal={closeModal}
                type={ServingValue}
              />
            </>
          )}
          {/* Customize Service End */}
          {customizeModal === true && (
            <>
              <CustomizeService
                pastryName={pastryName}
                customizeType={customizeType}
                closeModalCustomize={closeModalCustomize}
                modalStatus={customizeModal}
                modalUpdateStatus={modalUpdateStatus}
                productAddons={productAddons}
                servicelist={serviceUpdate}
                onCartFunction={onCartFunction}
                closeModal={closeModal}
                type={ServingValue}
              />
            </>
          )}
          {/* Product Service End */}
          {/* Sorry Cart Start */}
          {getSorryStatus === true && (
            <SorryCart SorryCartStatusUpdate={SorryCartStatusUpdate} checkModal={getSorryStatus} />
          )}
          {/* Sorry Cart End */}
          {/* View More Start */}
          {getViewMore === true && (
            <ViewMore
              getDescription={getDescription}
              ViewMoreUpdate={ViewMoreUpdate}
              getViewMore={getViewMore}
              modalUpdateStatus={modalUpdateStatus}
            />
          )}
          {/* View More End */}

          <div className="py-5"></div>
        </div>
      </div>
    </div>
  );
}

export default CategorizedPastries;
