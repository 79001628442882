import React, { Fragment, useState } from 'react';
import { MdClose } from 'react-icons/md';
import moment from 'moment';
import dic from 'i18next';
import ViewDetail from '../../ViewDetail';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const OrderModal = ({ closeModal, data }) => {
  const [modalStatus, setmodalStatus] = useState(false);
  const [getItem, setItem] = useState();
  return (
    <Fragment>
      <div className=" fixed z-10 overflow-y-auto top-0 w-full left-0">
        <div className="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-black opacity-75" />
          </div>
          <span className="flex h-screen justify-center items-center sm:inline-block sm:align-middle sm:h-screen"></span>
          <div
            className="inline-block align-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="bg-gray-100">
              <Fragment>
                <div className="shadow-md rounded p-2 w-full">
                  <div className="grid gap-2 mb-4 md:grid-cols-12 xl:grid-cols-12 mt-4">
                    <div className="col-span-12 mb-2">
                      <button
                        type="button"
                        className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1 ml-auto inline-flex items-center"
                        onClick={() => {
                          closeModal();
                        }}
                      >
                        <MdClose className="w-5 h-5" />
                      </button>
                    </div>
                    <div className="col-span-12 sm:col-span-4 rounded-lg shadow-md border-2 border-gray-200 overflow-hidden bg-white ">
                      <div className="p-2 flex items-center">
                        <div className="w-full h-40">
                          <p className="mb-2 text-sm font-semibold text-gray-600 ">
                            {dic.t('Margaux-Customer Info')}
                          </p>
                          <p className="text-sm font-medium text-gray-700 ">
                            <div className="flex justify-center items-center w-full space-y-1 flex-col border-gray-200">
                              <div className="flex justify-between w-full">
                                <p className="text-xs  text-gray-800">{dic.t('Margaux-Name')}</p>
                                <p className="text-xs  text-gray-600">
                                  {data && data.customerName}
                                </p>
                              </div>
                              <div className="flex justify-between w-full">
                                <p className="text-xs  text-gray-800">{dic.t('Margaux-Phone')}</p>
                                <p className="text-xs  text-gray-600">{data && data.phoneNumber}</p>
                              </div>
                            </div>
                          </p>
                          <p className="mb-2 mt-2 text-sm font-semibold text-gray-600 ">
                            {data?.deliveryType === 'PICKUP'
                              ? 'Delivery Type'
                              : dic.t('Margaux-Delivery Address')}
                          </p>
                          <p className="text-sm font-medium text-gray-700 ">
                            <p className="text-xs">
                              {data?.deliveryType === 'PICKUP'
                                ? 'Pickup'
                                : data?.deliveryAddress?.address +
                                  ', ' +
                                  data?.deliveryAddress?.region}
                            </p>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-12 sm:col-span-8 rounded-lg shadow-md border-2 border-gray-200 overflow-hidden bg-white ">
                      <div className="p-2 flex items-center">
                        <div className="w-full h-40">
                          <h3 className="text-sm  font-semibold leading-5 text-gray-800">
                            Order Summary
                          </h3>
                          <div className="flex justify-center items-center w-full space-y-1 flex-col border-gray-200 mt-2">
                            <div className="flex justify-between w-full">
                              <p className="text-xs  text-gray-800">{dic.t('Margaux-Order ID')}</p>
                              <p className="text-xs  text-gray-600">{data && data.orderId}</p>
                            </div>
                            <div className="flex justify-between w-full">
                              <p className="text-xs  text-gray-800">
                                {dic.t('Margaux-Order Date')}
                              </p>
                              <p className="text-xs  text-gray-600">
                                {data && moment(data.orderDate).format('DD/MM/YYYY')}
                              </p>
                            </div>
                            <div className="flex justify-between w-full">
                              <p className="text-xs  text-gray-800">
                                {dic.t('Margaux-Order Taken By')}
                              </p>
                              <p className="text-xs  text-gray-600">
                                {' '}
                                {data && data.userDTO === {}
                                  ? data.userDTO.firstName
                                  : data.customerName}
                              </p>
                            </div>
                            <div className="flex justify-between w-full">
                              <p className="text-xs  text-gray-800">
                                {dic.t('Margaux-Order Status')}
                              </p>
                              <p className="text-xs  text-gray-600">{data && data.orderStatus}</p>
                            </div>
                            <div className="flex justify-between w-full">
                              <p className="text-xs  text-gray-800">
                                {dic.t('Margaux-Payment Method')}
                              </p>
                              <p className="text-xs  text-gray-600">{data && data.paymentMode}</p>
                            </div>
                            <div className="flex justify-between w-full">
                              <p className="text-xs  text-gray-800">
                                {data?.deliveryType === 'DELIVERY'
                                  ? dic.t('Margaux-Delivery Date')
                                  : dic.t('Margaux-Pickup Date')}
                              </p>
                              <p className="text-xs  text-gray-600">
                                {data && moment(data.dpDate).format('DD/MM/YYYY')}
                              </p>
                            </div>
                            <div className="flex justify-between w-full">
                              <p className="text-xs  text-gray-800">
                                {data?.deliveryType === 'DELIVERY'
                                  ? dic.t('Margaux-Delivery Time')
                                  : dic.t('Margaux-Pickup Time')}
                              </p>
                              <p className="text-xs  text-gray-600">{data && data.dpTime}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="min-w-0 rounded-lg shadow-md border-2 border-gray-200 overflow-hidden bg-white ">
                    <div className="flex items-center">
                      <div className="flex flex-col p-4 min-h-fit max-h-52 w-full space-y-2 overflow-y-scroll h-34 scrollbar-thin scrollbar-thumb-gray-700 scrollbar-track-gray-300">
                        {/* <h3 className="text-sm  font-semibold leading-5 text-gray-800">Items</h3> */}
                        {data.items.map((item, key) => {
                          const type = item && item.cakeType;
                          var signatureBox = [];
                          var macaroonBox = [];
                          if (type === 'REGULAR') {
                          } else if (type === 'SIGNATUREBOX') {
                            signatureBox = item?.signatureItems.split(',');
                          } else if (type === 'MACAROONBOX') {
                            macaroonBox = JSON.parse(item.macaroonItems);
                          }
                          return (
                            <div key={key}>
                              <ul role="list" className="divide-y divide-gray-200 ">
                                <li className="">
                                  <div className="flex items-center space-x-4">
                                    <div className="flex-shrink-0">
                                      <LazyLoadImage
                                        className="object-cover w-20 h-20 rounded-lg shadow-sm"
                                        src={item.itemImageUrl}
                                        alt="product"
                                      />
                                    </div>
                                    <div className="flex-1 min-w-0">
                                      <p className="text-sm font-medium text-gray-900 truncate ">
                                        {item.itemName}
                                      </p>
                                      <p className="text-sm text-gray-500 truncate ">
                                        Qty: {item.quantity}
                                      </p>
                                      <p className="text-sm text-gray-500 truncate ">
                                        {item?.itemMessage !== '' ? (
                                          <p className="text-xs text-gray-500 truncate ">
                                            Message on pastry: <br /> {item?.itemMessage}
                                          </p>
                                        ) : (
                                          <></>
                                        )}
                                      </p>
                                      {signatureBox.length > 0 || macaroonBox.length > 0 ? (
                                        type === 'SIGNATUREBOX' ? (
                                          <span
                                            onClick={() => {
                                              setmodalStatus(!modalStatus);
                                              setItem(signatureBox);
                                            }}
                                            className="text-black text-xs cursor-pointer underline  underline-offset-1"
                                          >
                                            View Details
                                          </span>
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setmodalStatus(!modalStatus);
                                              setItem(macaroonBox);
                                            }}
                                            className="text-black text-xs cursor-pointer underline  underline-offset-1"
                                          >
                                            View Details
                                          </span>
                                        )
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                    <div className="inline-flex items-center text-base font-semibold text-gray-900 ">
                                      {dic.t('Margaux-AED')} {item.totalPrice}
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </Fragment>
            </div>
          </div>
        </div>
      </div>
      {modalStatus === true && (
        <ViewDetail modalStatus={modalStatus} setmodalStatus={setmodalStatus} getItem={getItem} />
      )}
    </Fragment>
  );
};

export default OrderModal;
