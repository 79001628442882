import React, { Fragment, useState } from 'react';
import { IconContext } from 'react-icons';
import { BsClockFill } from 'react-icons/bs';
import dic from 'i18next';
import { useEffect } from 'react';

const DeliveryTime = ({ dptime, setdptime, deliveryTime, pickupTime }) => {
  useEffect(() => {
    document.getElementById('pickupclick').click();
  }, []);
  return (
    <Fragment>
      <div className="grid grid-cols-1 sm:grid-cols-3 gap-2 mt-4">
        {pickupTime?.map((item, value) => {
          return (
            <div
              id={`${value === 0 ? 'pickupclick' : 'pickupunclick'}`}
              key={value}
              className={`border-2 text-gray-400 rounded-md shadow-md p-2 ${
                dptime === item?.fields?.Title?.value ? 'border-gray-500' : 'border-gray-200'
              } hover:cursor-pointer`}
              onClick={() => setdptime(item?.fields?.Title?.value)}
            >
              <div className="flex">
                <div className="flex items-center">
                  <IconContext.Provider
                    value={{
                      className: `inline-flex ${
                        dptime === item?.fields?.Title?.value && 'text-gray-800'
                      }`,
                    }}
                  >
                    <BsClockFill size={25} />
                  </IconContext.Provider>
                </div>
                <div className="ml-2 text-md">
                  <label htmlFor="helper-checkbox" className="font-Normal text-gray-900">
                    {item?.fields?.Title?.value}
                  </label>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </Fragment>
  );
};

export default DeliveryTime;
