import axios from 'axios';
import { AdminMicroRoutes } from '../../constants';
import * as helpers from '../../helpers/config';

export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';
export const UPDATE_USER_LOADING = 'UPDATE_USER_LOADING';

export const onupdateuser = (data) => async (dispatch) => {
  dispatch({ type: UPDATE_USER_LOADING, payload: true });
  const islogin = typeof Storage !== 'undefined' && localStorage.getItem('islogin');
  let tokenid = '';
  if (islogin === 'true') {
    tokenid = localStorage.getItem('tokenId');
    let payload = helpers.encryptePayload(data);
    await axios
      .post(AdminMicroRoutes.updateuser, payload, { headers: { token: `${tokenid}` } })
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: UPDATE_USER_SUCCESS,
            payload: response.data,
          });
          dispatch({ type: UPDATE_USER_LOADING, payload: false });
        } else {
          dispatch({
            type: UPDATE_USER_FAILURE,
            payload: response.data.status,
          });
          dispatch({ type: UPDATE_USER_LOADING, payload: false });
        }
      });
  }
};
