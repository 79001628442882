import React, { Fragment } from 'react';
import CustomerScreen from './CustomerScreen';
import DeliveryScreen from './DeliveryScreen';
import OrderScreen from './OrderScreen';
import SummaryScreen from './SummaryScreen';

const Main = ({
  tab,
  updateTab,
  Regular,
  Indiviual,
  Signature,
  Macroons,
  codes,
  Types,
  pickupTime,
  deliverycharges,
  deliveryTime,
  signatureName,
  macaroonName,
}) => {
  return (
    <Fragment>
      <div className="w-[inherit] pb-4 sm:px-8 bg-white">
        {tab === 1 && (
          <OrderScreen
            RegularList={Regular}
            Types={Types}
            IndiviualList={Indiviual}
            SignatureList={Signature}
            MacroonsList={Macroons}
            signatureName={signatureName}
            macaroonName={macaroonName}
          />
        )}
        {tab === 2 && <CustomerScreen codes={codes} />}
        {tab === 3 && (
          <DeliveryScreen
            pickupTime={pickupTime}
            deliverycharges={deliverycharges}
            deliveryTime={deliveryTime}
          />
        )}
        {tab === 4 && <SummaryScreen />}
      </div>
    </Fragment>
  );
};

export default Main;
