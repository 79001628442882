import React, { Fragment, useEffect, useState } from 'react';
import Select from 'react-select';
import { colourStyles } from '../../helpers/config';
import { GiBerriesBowl } from 'react-icons/gi';
import { useDispatch, useSelector } from 'react-redux';
import { onCartAction } from '../../actions/cart/addtocart.action';
import { useToasts } from 'react-toast-notifications';
import dic from 'i18next';

function CustomizePastry(props) {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [selectedRadio, setSelectedRadio] = useState(1);
  const [flavours, setFlavours] = useState(false);
  const [getserves, setServes] = useState(false);
  const [addonPrice, setaddonPrice] = useState('');
  const [addonName, setaddonName] = useState('');
  const sessionproducts = localStorage.getItem('cartproducts');
  const get_value = JSON.parse(sessionproducts);
  const [cartItems, setCartItems] = useState(get_value || []);
  const { existcartdata, existcartstatus } = useSelector((state) => state.cartlist);
  var logoutstatus = useSelector((state) => state.login.logoutstatus);
  const AddonDetail = (price, addonname) => {
    setaddonPrice(price);
    setaddonName(addonname);
  };
  const onCartFunction = (items, serviceid, message, price, serves, addons, action) => {
    if (serviceid === '') {
      return false;
    }
    var defaultValue = 1;
    // Product id equal checking
    const exist = cartItems.find((currentcart) => currentcart?.id === items?.id);
    // if product id match it is with inner service id checking
    if (exist) {
      const exist_service = exist?.cartaddedservice?.filter(
        (itemlist) => itemlist?.id === serviceid
      );
      const balance_service = exist?.cartaddedservice.filter(
        (itemlist) => itemlist?.id !== serviceid
      );
      if (exist_service && exist_service?.length > 0) {
        // Exist service price quantity added here
        const previouscart = cartItems?.filter((currentcart) => currentcart?.id !== items?.id);
        var updateresponse = [
          ...previouscart,
          {
            ...items,
            cartaddedservice: [
              ...balance_service,
              {
                id: serviceid,
                quantity: { value: exist_service[0]?.quantity?.value + defaultValue },
                message: { value: message },
                price: { value: price },
                serves: { value: serves },
                addon: addons,
                type: 'CUSTOMIZE',
              },
            ],
          },
        ];
        setCartItems(updateresponse);
        dispatch(onCartAction(updateresponse));
      } else {
        // Exist service price quantity intially added here
        const previouscart = cartItems?.filter((currentcart) => currentcart?.id !== items?.id);
        var updateresponse = [
          ...previouscart,
          {
            ...items,
            cartaddedservice: [
              ...exist.cartaddedservice,
              {
                id: serviceid,
                quantity: { value: defaultValue },
                message: { value: message },
                price: { value: price },
                serves: { value: serves },
                addon: addons,
                type: 'CUSTOMIZE',
              },
            ],
          },
        ];
        setCartItems(updateresponse);
        dispatch(onCartAction(updateresponse));
      }
    } else {
      // intial product add to cart
      var updateresponse = [
        ...cartItems,
        {
          ...items,
          cartaddedservice: [
            {
              id: serviceid,
              quantity: { value: defaultValue },
              message: { value: message },
              price: { value: price },
              serves: { value: serves },
              addon: addons,
              type: 'CUSTOMIZE',
            },
          ],
        },
      ];
      setCartItems(updateresponse);
      dispatch(onCartAction(updateresponse));
    }
  };

  var flavour = [];
  var cakes = props?.fields?.Pastries;
  cakes.map((label) => {
    flavour.push({
      label: label?.fields['Pastry Name']?.fields?.Title?.value,
      value: label?.fields['Pastry Name']?.fields?.Title?.value,
    });
  });
  var serversoptions = [];
  cakes.map((label) => {
    if (label?.fields['Pastry Name']?.fields?.Title?.value === flavours) {
      var servicelist = label?.fields.Services;
      servicelist.map((item) => {
        serversoptions.push({
          label:
            item.fields['Service To Persons'].fields.Title.value +
            ' - ' +
            item?.fields?.Price?.value +
            ' AED',
          value: item.fields['Service To Persons'].fields.Title.value,
        });
      });
    }
  });
  var addons = [];
  cakes.map((label) => {
    if (label?.fields['Pastry Name']?.fields?.Title?.value === flavours) {
      var addonlist = label?.fields?.Addons;
      addonlist.map((item) => {
        addons.push(item);
      });
    }
  });
  // items, serviceid, message, price, serves, addons, action
  const Additem = () => {
    if (flavours === false) {
      addToast('Please select a flavour...!', { appearance: 'error' });
      return false;
    } else if (getserves === false) {
      addToast('Please select a serve...!', { appearance: 'error' });
      return false;
    }
    var additems,
      serviceid,
      price,
      serves,
      addons,
      action = '';
    var message = '';
    addons = {
      addonName: addonName,
      Price: Number(addonPrice),
      Currency: 'AED',
    };
    cakes.filter((item) => {
      if (item?.fields['Pastry Name']?.fields?.Title?.value === flavours) {
        additems = item;
        item.fields.Services.map((serviceitem) => {
          if (serviceitem.fields['Service To Persons'].fields.Title.value === getserves) {
            serviceid = serviceitem.id;
            price = serviceitem.fields.Price.value;
            serves = getserves;
            action = 'plus';
          }
        });
      }
    });
    onCartFunction(additems, serviceid, message, price, serves, addons, action);
    // setFlavours(false);
    // setServes(false);
    // setaddonPrice(false);
    // setaddonName(false);
    addToast('Item added successfully...!', { appearance: 'success' });
  };

  useEffect(() => {
    if (existcartstatus === true) {
      setCartItems(existcartdata);
    }
  }, [existcartdata]);
  useEffect(() => {
    localStorage.setItem('cartproducts', JSON.stringify(cartItems));
    if (logoutstatus === true) {
      dispatch(RevertLoginStatus());
      localStorage.setItem('cartproducts', JSON.stringify([]));
      setCartItems([]);
    }
  });

  return (
    <Fragment>
      <div className="grid pb-5   md:p-18 lg:px-24 lg:gap-x-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-2 bg-black">
        <div className="mb-6 mt-6 px-5">
          <ul className="list-none mb-0">
            <li>
              <label htmlFor="typeofcake" className="form-label inline-block mb-2 text-white">
                {dic.t('Margaux-Flavor of Pastry*')}
              </label>
              <Select
                options={flavour}
                placeholder={`${dic.t('Margaux-Flavors') + ' *'}`}
                styles={colourStyles}
                onChange={(e) => {
                  setFlavours(e.label);
                }}
              />
            </li>
            <li>
              <label htmlFor="typeofcake" className="form-label inline-block mb-2 mt-5 text-white">
                {dic.t('Margaux-Serves')}
              </label>
              <Select
                options={serversoptions}
                placeholder={`${dic.t('Margaux-Serves') + ' *'}`}
                onChange={(e) => setServes(e.value)}
                styles={colourStyles}
              />
            </li>
          </ul>
        </div>
        <div className="px-5">
          <ul className="list-none mb-0">
            <li>
              <label
                htmlFor="typeofcake"
                className="form-label inline-block mb-2 sm:mt-5 text-white"
              >
                {dic.t('Margaux-Message')}
              </label>
              <input
                type="text"
                className="border text-white text-sm rounded-lg bg-black tracking-wider outline-none block w-full p-2.5"
                placeholder={dic.t('Margaux-Message')}
                autoComplete="off"
              />
            </li>
            <li className="py-5">
              {addons.map((items, key) => {
                const charges = items?.fields?.Charges;
                return (
                  <>
                    <p key={key} className="text-white">
                      {items.fields.Title.value} <GiBerriesBowl className="text-white inline" />
                    </p>
                    <div className="flex space-x-2 py-2">
                      {charges.map((addon, keys) => {
                        return (
                          <span
                            key={keys}
                            onClick={() =>
                              AddonDetail(addon?.fields?.Price?.value, items.fields.Title.value)
                            }
                            className={`${
                              addonPrice === addon?.fields?.Price?.value
                                ? 'bg-white text-black'
                                : 'bg-transparent text-white'
                            }   border cursor-pointer border-solid border-white font-bold uppercase text-sm py-2.5 rounded ease-linear transition-all duration-150 active show px-3`}
                          >
                            {addon?.fields?.Price?.value}{' '}
                            {addon?.fields?.Currency?.fields['Currency Code']?.value}
                          </span>
                        );
                      })}
                    </div>
                  </>
                );
              })}
            </li>
          </ul>
        </div>
        <div className="my-3 px-5">
          <button
            onClick={() => Additem()}
            className="w-full sm:w-56 text-white bg-gray-700 font-semibold rounded-lg text-sm px-10 py-2.5 mb-2 tracking-wider"
          >
            {dic.t('Margaux-Add Item')}
          </button>
          <div className="py-3"></div>
        </div>
      </div>
    </Fragment>
  );
}

export default CustomizePastry;
