import React, { Fragment, useState } from 'react';
import DeliveryTime from './DeliveryTime';
import DeliveryType from './DeliveryType';
import OrderType from './OrderType';
import PickUpType from './PickUpType';

const Delivery = ({
  deliverycharges,
  setdeliveryType,
  setdptime,
  dptime,
  deliveryType,
  deliveryTime,
  pickupTime,
}) => {
  return (
    <Fragment>
      <div className="relative overflow-x-auto border-2 border-gray-300 shadow-md rounded p-4 sm:col-span-8">
        <OrderType
          setdeliveryType={setdeliveryType}
          deliveryType={deliveryType}
          setdptime={setdptime}
          deliverycharges={deliverycharges}
        />
        {deliveryType === 'DELIVERY' && (
          <DeliveryType deliverycharges={deliverycharges} setdptime={setdptime} dptime={dptime} />
        )}
        {deliveryType === 'PICKUP' && (
          <>
            {' '}
            <DeliveryTime
              pickupTime={pickupTime}
              setdptime={setdptime}
              dptime={dptime}
              deliveryTime={deliveryTime}
            />
            <PickUpType />
          </>
        )}
      </div>
    </Fragment>
  );
};

export default Delivery;
