import React, { Fragment } from 'react';
import LeftMenu from '../LeftMenu';
import Main from './Main';

const AdminOrders = (props) => {
  const leftmenu = props?.fields?.['Left Menus'];
  return (
    <Fragment>
      <div className="flex w-full bg-[#1d1d1d]">
        <LeftMenu selectOption={2} leftmenu={leftmenu} />
        <Main />
      </div>
    </Fragment>
  );
};

export default AdminOrders;
