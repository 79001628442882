import React from 'react';
import rightarrow from '../../assets/icon/right-icon.png';

function RightArrow(item) {
  return (
    <div className="relative">
      <img
        src={rightarrow}
        onClick={item.onClick}
        className="absolute z-10 xssm:bottom-[10rem] xs:bottom-[10em] xsslarge:bottom-[12.6em] xslarge:bottom-[9.2rem] customsm:bottom-[188px]  cursor-pointer right-[-40px] w-8"
      />
    </div>
  );
}

export default RightArrow;
