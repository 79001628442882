export const ADMIN_ORDER_WITHOUTNI = 'ADMIN_ORDER_WITHOUTNI';
export const LOADING_ADMIN_ORDER_WITHOUTNI = 'LOADING_ADMIN_ORDER_WITHOUTNI';
import axios from 'axios';
import { MicroRoutes } from '../../constants';
import * as helpers from '../../helpers/config';

export const onAdminOrder = (data) => async (dispatch) => {
  const getPaymentObject = {'data':data,'isAdminScreen': true };
  let encryptPayload = helpers.encryptePayload(getPaymentObject);
  dispatch({
    type: LOADING_ADMIN_ORDER_WITHOUTNI,
    payload: true,
  });
  const islogin = localStorage.getItem('islogin');
  let tokenid = '';
  if (islogin === 'true') {
    tokenid = localStorage.getItem('tokenId');
    await axios
      .post(MicroRoutes.ordercreate + '?token=' + tokenid, encryptPayload)
      .then(async (response) => {
        dispatch({
          type: LOADING_ADMIN_ORDER_WITHOUTNI,
          payload: false,
        });
        dispatch({
          type: ADMIN_ORDER_WITHOUTNI,
          payload: {
            status: 200,
            OrderId: response.data.orderId,
            statusMessage: 'Order has been Created Successfully',
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: LOADING_ADMIN_ORDER_WITHOUTNI,
          payload: false,
        });
        console.log('error while order', error);
      });
  }
};
