import {
  PAYMENT_STATUS,
  INVOICE_CREATE_SUCCESS,
  INVOICE_CREATE_LOADING,
  INVOICE_CREATE_FAIL,
  NIPAYMENT_CREATE_SUCCESS,
  NIPAYMENT_CREATE_FAIL,
  NIPAYMENT_CREATE_LOADING,
} from '../actions/payment/payment.action';

const initailState = {
  payment_loading: false,
  payment_message: false,
  payment_error: false,
  invoice_loading: false,
  invoice_success_message: '',
  invoice_fail_message: '',
  invoice_order_ref: '',
  nipayment_loading: false,
  nipayment_sucess: '',
  nipayment_fail: false,
};

export const onPaymentReducer = (state = initailState, action) => {
  switch (action.type) {
    case PAYMENT_STATUS:
      return {
        ...state,
        payment_loading: action.loading,
        payment_message: action.message,
        payment_error: action.error,
      };
    case INVOICE_CREATE_LOADING:
      return {
        ...state,
        invoice_loading: action.payload,
      };
    case INVOICE_CREATE_SUCCESS:
      return {
        ...state,
        invoice_success_message: action.payload.message,
        invoice_order_ref: action.payload.orderReference,
      };
    case INVOICE_CREATE_FAIL:
      return {
        ...state,
        invoice_fail_message: action.payload,
      };
      case NIPAYMENT_CREATE_LOADING:
        return {
          ...state,
          nipayment_loading: action.payload,
        };
      case NIPAYMENT_CREATE_SUCCESS:
        return {
          ...state,
          nipayment_sucess: action.payload,
        };
      case NIPAYMENT_CREATE_FAIL:
        return {
          ...state,
          nipayment_fail: action.payload,
        };
    default:
      return state;
  }
};
