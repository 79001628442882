export const SET_CART_DATA = 'SET_CART_DATA';
export const REMOVE_CART_DATA = 'REMOVE_CART_DATA';

export const SET_CUSTOMER_DATA = 'SET_CUSTOMER_DATA';

export const SET_CUSTOMER_DELIVERY_DATA = 'SET_CUSTOMER_DELIVERY_DATA';

// order screen actions
export const setCenterCart = (data) => (dispatch) => {
  dispatch({ type: SET_CART_DATA, payload: data });
};

export const removeCenterCart = (data) => (dispatch) => {
  dispatch({ type: REMOVE_CART_DATA, payload: data });
};

// customer screen actions
export const setCustomerInfo = (data) => (dispatch) => {
  dispatch({ type: SET_CUSTOMER_DATA, payload: data });
};

// Delivery screen actions
export const setCustomerDeliveryInfo = (data) => (dispatch) => {
  dispatch({ type: SET_CUSTOMER_DELIVERY_DATA, payload: data });
};
