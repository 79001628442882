import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { HiBadgeCheck } from 'react-icons/hi';
import { BiEditAlt } from 'react-icons/bi';
import { MdEditLocationAlt } from 'react-icons/md';
import { onCheckoutRegion } from '../../actions/checkoutconfig.action';
import dic from 'i18next';
import { format, addMonths, addDays } from 'date-fns';
import * as Helpers from '../../helpers/AdminHelpers';
import 'react-day-picker/dist/style.css';
import { DayPicker } from 'react-day-picker';
import AddAddress from '../AddAddress';
import EditModalForm from '../AddAddress/EditModal';
import { HiClock } from 'react-icons/hi';

function GuestDeliveryForm({
  setDeliveryType,
  UpdateDeliveryCharge,
  setselectAddress,
  UpdateExtra,
  AddressList,
  SetEditModal,
  SetModalItem,
  setClickAddressKey,
  selectAddress,
  AddAddressModal,
  setdpdate,
  DeliveryDateUpdate,
  deliveryType,
  pickupTime,
  UpdatePickup,
  AddressModal,
  deliveryTime,
  EditModal,
  deliveryTimeRegion,
  CheckRegionListCharge,
  onDeliveryAddressSubmit,
  onDeliveryAddressUpdate,
  isMobile,
  dpdate,
  selectTime,
  EditModalItem,
  isFestival,
}) {
  const dispatch = useDispatch();
  return (
    <div>
      <div className="accord-description border  border-white">
        <div className="grid grid-cols-2 px-6 py-5">
          <div
            id="intial_click"
            onClick={() => {
              setDeliveryType('delivery');
              UpdateDeliveryCharge(AddressList.length > 0 ? AddressList[0]?.region : '');
              setselectAddress(0);
              localStorage.setItem('selectAddress', 0);
              localStorage.removeItem('NewDeliveryAddressExtra');
              UpdateExtra(
                AddressList[0]?.deliverytime !== undefined ? AddressList[0]?.deliverytime : false
              );
            }}
            className={` uppercase font-bold text-center border mr-2 py-3 cursor-pointer ${
              deliveryType === 'delivery' ? 'bg-white text-black' : 'text-white'
            }`}
          >
            Delivery
          </div>
          <div
            onClick={() => {
              localStorage.removeItem('NewDeliveryAddressExtra');
              setDeliveryType('pickup');
              dispatch(onCheckoutRegion(0, 'pickup'));
              setTimeout(() => {
                document.getElementById('defaulttime').click();
              }, 0);
            }}
            className={`uppercase font-bold text-center border ml-2 py-3 cursor-pointer ${
              deliveryType === 'pickup' ? 'bg-white text-black' : 'text-white'
            }`}
          >
            Pickup
          </div>
        </div>
        {deliveryType === 'delivery' && (
          <>
            <p className=" px-6 py-5 text-white text-center">Please Select Delivery Address</p>
            {AddressList.length > 0 ? (
              <div className={`${isMobile === true ? 'grid' : ''} grid-cols-1 sm:flex sm:flex-row`}>
                <div className={`${isMobile === true ? 'grid' : ''} grid-cols-1 sm:basis-8/12`}>
                  <div className="grid grid-cols-1 sm:grid-cols-2 px-5 mt-1 pb-5">
                    {AddressList.length > 0 ? (
                      AddressList.map((item, key) => {
                        return (
                          <div
                            key={key}
                            onClick={() => {
                              setselectAddress(key);
                              UpdateDeliveryCharge(item?.region);
                            }}
                            className="block p-4 rounded-lg cursor-pointer relative border mb-3 mx-2 shadow-lg bg-white max-w-sm"
                          >
                            {selectAddress === key && (
                              <HiBadgeCheck
                                className="absolute top-[2px] right-[2px] text-green-600"
                                size={23}
                              />
                            )}
                            <div className="text-black font-bold text-sm">
                              <p className="w-9/12" style={{ lineBreak: 'anywhere' }}>
                                {item?.address}
                              </p>
                              <p>{item?.region}</p>
                              <p>{item?.deliverytime}</p>
                              <p
                                onClick={() => {
                                  SetEditModal(true);
                                  SetModalItem(item);
                                  setClickAddressKey(key);
                                }}
                                className="underline text-sm pt-1 relative cursor-pointer inline-block underline-offset-3"
                              >
                                <BiEditAlt className="cursor-pointer inline" size={18} />
                                Edit Address
                              </p>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div
                  className={`${
                    isMobile === true ? 'grid' : ''
                  } grid-cols-1 sm:basis-4/12 px-2 sm:pr-5`}
                >
                  <div className="bg-white rounded-md sm:py-1 mb-4">
                    <DayPicker
                      mode="single"
                      required
                      fromDate={isFestival ? addDays(new Date(), 2) : Helpers.getNextday()}
                      toDate={addMonths(new Date(), 3)}
                      selected={dpdate}
                      onSelect={(e) => {
                        setdpdate(e);
                        DeliveryDateUpdate(e);
                      }}
                      styles={{
                        caption_label: { zIndex: '0' },
                        caption: { fontSize: '12px' },
                        nav_button_next: { height: '30px', width: '30px' },
                        nav_button_previous: { height: '30px', width: '30px' },
                        button_reset: { fontSize: '14px' },
                        cell: { height: '10px', width: '10px' },
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className={'grid grid-cols-1 px-5'}>
                  <div className="block p-4 rounded-lg relative  mb-3 mx-2 shadow-lg">
                    <div className="text-white text-center text-sm py-1 sm:py-10">
                      <MdEditLocationAlt className="mx-auto text-white" size={25} />
                      <span className="block sm:hidden">
                        <br />
                      </span>
                      <button
                        onClick={() => AddAddressModal(true)}
                        className=" text-white text-center bg-gray-700 font-bold rounded-lg sm:mt-4 text-sm px-5 py-2 tracking-wider	"
                      >
                        Add New Address
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
        {deliveryType === 'pickup' && (
          <>
            <div className={`${isMobile === true ? 'grid' : ''} grid-cols-1 sm:flex sm:flex-row`}>
              <div className={`${isMobile === true ? 'grid' : ''} grid-cols-1 sm:basis-8/12`}>
                <div className="grid grid-cols-1 sm:grid-cols-2 px-5 mt-1 pb-5">
                  {pickupTime.map((item, value) => {
                    return (
                      <div
                        id={value === 0 ? 'defaulttime' : 'np'}
                        key={value}
                        onClick={(e) => {
                          UpdatePickup(item?.fields?.Title?.value);
                          dispatch(onCheckoutRegion(0, 'pickup'));
                        }}
                        className="block p-3.5 rounded-lg cursor-pointer relative border mb-3 mx-2 shadow-lg bg-white max-w-sm"
                      >
                        {item?.fields?.Title?.value === selectTime && (
                          <HiBadgeCheck
                            className="absolute top-[2px] right-[2px] text-green-600"
                            size={23}
                          />
                        )}
                        <div className="text-black font-bold text-sm">
                          <p className="text-lg uppercase" style={{ lineBreak: 'anywhere' }}>
                            <HiClock size={22} className="inline-block mb-1" />{' '}
                            {item?.fields?.Title?.value}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div
                className={`${
                  isMobile === true ? 'grid' : ''
                } grid-cols-1 sm:basis-4/12 px-2 sm:pr-5`}
              >
                <div className="bg-white rounded-md sm:py-1 mb-4">
                  <DayPicker
                    mode="single"
                    required
                    fromDate={isFestival ? addDays(new Date(), 2) : Helpers.getNextday()}
                    toDate={addMonths(new Date(), 3)}
                    selected={dpdate}
                    onSelect={(e) => {
                      setdpdate(e);
                      DeliveryDateUpdate(e);
                    }}
                    styles={{
                      caption_label: { zIndex: '0' },
                      caption: { fontSize: '12px' },
                      nav_button_next: { height: '30px', width: '30px' },
                      nav_button_previous: { height: '30px', width: '30px' },
                      button_reset: { fontSize: '14px' },
                      cell: { height: '10px', width: '10px' },
                    }}
                  />
                </div>
              </div>
            </div>
          </>
        )}
        {AddressModal === true && (
          <AddAddress
            modalStatus={AddressModal}
            AddAddressModal={AddAddressModal}
            deliveryTimeRegion={deliveryTimeRegion}
            CheckRegionListCharge={CheckRegionListCharge}
            deliveryTime={deliveryTime}
            onDeliveryAddressSubmit={onDeliveryAddressSubmit}
          />
        )}
        {EditModal === true && (
          <EditModalForm
            modalStatus={EditModal}
            SetEditModal={SetEditModal}
            deliveryTimeRegion={deliveryTimeRegion}
            CheckRegionListCharge={CheckRegionListCharge}
            deliveryTime={deliveryTime}
            EditModalItem={EditModalItem}
            onDeliveryAddressUpdate={onDeliveryAddressUpdate}
          />
        )}
      </div>
    </div>
  );
}

export default GuestDeliveryForm;
