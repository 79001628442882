import React, { Fragment, useState } from 'react';
import { IconContext } from 'react-icons';
import { BiCycling } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import { RiShoppingBasketLine } from 'react-icons/ri';
import { setCustomerDeliveryInfo } from '../../../actions/admin/CallCenter.action';
import dic from 'i18next';

const OrderType = ({ setdeliveryType, deliveryType, setdptime, deliverycharges }) => {
  let label = '';
  let Timings = '';
  let DeliveryTime = '';
  const dispatch = useDispatch();
  const { deliveryAddress } = useSelector((state) => state.callcenter.customerDeliveryInfo);
  const setPickupdata = () => {
    setdeliveryType('PICKUP');
    setdptime('12:00pm - 03:00pm');
    dispatch(setCustomerDeliveryInfo({ deliveryAddress }));
  };
  const setDeliverydata = () => {
    setdeliveryType('DELIVERY');
    deliverycharges.map((item) => {
      label = item?.fields?.Region?.fields?.Region?.value;
      Timings = item?.fields?.Region?.fields?.Timings;
      DeliveryTime = Timings[0]?.fields?.Title?.value;
      if (label === deliveryAddress.region) {
        setdptime(DeliveryTime);
        return;
      }
    });
  };
  return (
    <Fragment>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
        <div
          className={`border-2 text-gray-400 rounded-md shadow-md p-2 ${
            deliveryType === 'DELIVERY' ? 'border-gray-500' : 'border-gray-200'
          } hover:cursor-pointer`}
          onClick={() => setDeliverydata()}
        >
          <div className="flex">
            <div className="flex items-center">
              <IconContext.Provider
                value={{
                  className: `inline-flex ${deliveryType === 'DELIVERY' && 'text-gray-800'}`,
                }}
              >
                <BiCycling size={30} />
              </IconContext.Provider>
            </div>
            <div className="ml-2 text-xl">
              <label htmlFor="helper-checkbox" className="font-medium text-gray-900">
                {dic.t('Margaux-Delivery')}
              </label>
              <p id="helper-checkbox-text" className="text-xs font-normal text-gray-500">
                {dic.t('Margaux-Delivery Charges Included')}
              </p>
            </div>
          </div>
        </div>
        <div
          className={`border-2 text-gray-400 rounded-md shadow-md p-2 ${
            deliveryType === 'PICKUP' ? 'border-gray-500' : 'border-gray-200'
          } hover:cursor-pointer`}
          onClick={() => setPickupdata()}
        >
          <div className="flex">
            <div className="flex items-center">
              <IconContext.Provider
                value={{
                  className: `inline-flex ${deliveryType === 'PICKUP' && 'text-gray-800'}`,
                }}
              >
                <RiShoppingBasketLine size={30} />
              </IconContext.Provider>
            </div>
            <div className="ml-2 text-xl">
              <label htmlFor="helper-checkbox" className="font-medium text-gray-900 ">
                {dic.t('Margaux-Pick Up')}
              </label>
              <p id="helper-checkbox-text" className="text-xs font-normal text-gray-500 ">
                {dic.t('Margaux-Delivery Charges Excluded')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default OrderType;
