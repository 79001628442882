import { React, useState, useEffect, useRef } from 'react';
import {
  AiOutlineEye,
  AiOutlineSearch,
  AiFillCloseCircle,
  AiOutlineDownload,
  AiFillFileExcel,
} from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import {
  MdOutlineDeliveryDining,
  MdOutlineDoneOutline,
  MdPhonelinkErase,
  MdMobileFriendly,
} from 'react-icons/md';
import { TbShoppingCartX } from 'react-icons/tb';
import { BsBasketFill } from 'react-icons/bs';
import { RiShoppingBasketLine } from 'react-icons/ri';
import { GiCampCookingPot } from 'react-icons/gi';
import ReactPaginate from 'react-paginate';
import Select from 'react-select';
import { filterStyles } from '../../helpers/Patterns';
import OrderModal from './Modal';
import moment from 'moment';
import { IoCloseCircle } from 'react-icons/io5';
import { getAllOrders } from '../../actions/admin/GetAllOrder.action';
import TableLoader from '../Admin/TableLoader';
import { filterOrders } from '../../actions/admin/FilterOrder.action';
import { removeError } from '../../actions/admin/RemoveError.action';
import { generateExcelReport } from '../../actions/report.action';
import * as helpers from '../../helpers/config';
import ConfirmationModal from './ConfirmationModal';
import CancelConfirmModal from './ConfirmationModal/CancelConfirmModal';
import dic from 'i18next';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const Main = () => {
  const {
    handleSubmit,
    register,
    setValue,
    reset,
    formState: { errors },
    control,
  } = useForm();

  const registerOptions = {
    // ...
    message: { required: 'Select from dropdown' },
  };

  // fetching orders from redux state
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.allorders);
  const orders = useSelector((state) => state.allorders.orders);
  const filterloading = useSelector((state) => state.filterorders.loading);
  const filteredorders = useSelector((state) => state.filterorders.orders);
  const error = useSelector((state) => state.filterorders.error);

  // taking logged user data from storage
  const loggeddetails = helpers.LoggedUserDetails();
  const usertype = loggeddetails?.accessDetails?.role;
  const userEmailId = loggeddetails?.userEmailId;
  // state & function for view & close view more modal
  const [showModal, setShowModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [confirmModalItem, setConfirmModalItem] = useState();
  const [cancelconfirmModalItem, setCancelConfirmModalItem] = useState();
  const [cancelConfirmModal, setCancelConfirmModal] = useState();
  const [searchPlaceholder, setSearchPlaceholder] = useState(false);
  const closeModal = () => {
    setShowModal(false);
  };
  const closeConfirmModal = () => {
    setConfirmModal(false);
  };
  const closeCancelConfirmModal = () => {
    setCancelConfirmModal(false);
  };
  // react pagination states
  const [items, setItems] = useState(orders);
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const today = new Date();
  const todayDate = new Date();
  const [date, setDateCurrent] = useState(todayDate.setDate(todayDate.getDate()));
  const itemsPerPage = 10;

  // filter data state
  const [selectData, setSelectData] = useState();
  const [formData, updateFormData] = useState({
    searchKey: '',
    searchValue: '',
  });
  const labelRef = useRef();
  const valueRef = useRef();
  // set intial items
  useEffect(() => {
    setItemOffset(0);
  }, [items]);

  // fetch all orders action call
  useEffect(() => {
    const userlistdata = {
      userEmailId: userEmailId,
      userType: usertype,
    };
    dispatch(getAllOrders(userlistdata));
  }, []);

  // updated each time the orders with new data
  useEffect(() => {
    setItems(orders);
  }, [orders]);

  // updated each time the orders with filterred data
  useEffect(() => {
    setItems(filteredorders);
  }, [filteredorders]);

  useEffect(() => {
    setErrorMessage(error);
  }, [error]);

  // update the page items
  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(items && items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items && items.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, items]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    setItemOffset(newOffset);
  };

  // filter handle change
  const FilterhandleChange = (e) => {
    if ((e && e.value === 'orderDate') || (e && e.value === 'deliveryDate')) {
      setSearchPlaceholder(true);
    } else {
      setSearchPlaceholder(false);
    }
    e &&
      updateFormData({
        ...formData,
        ['searchKey']: e.value,
      });
    if (e && e.value === 'filterAll') {
      handleClear();
    }
    dispatch(removeError());
    setErrorMessage('');
  };

  const handleClear = () => {
    setItems(orders);
    valueRef.current.value = '';
    labelRef.current.clearValue();
    dispatch(removeError());
    setErrorMessage('');
  };
  const searchHandleDatePicker = (e) => {
    updateFormData({
      ...formData,
      ['searchValue']:
        e.getFullYear() +
        '-' +
        ('0' + (e.getMonth() + 1)).slice(-2) +
        '-' +
        ('0' + e.getDate()).slice(-2),
    });
    dispatch(removeError());
    setErrorMessage('');
  };
  useEffect(() => {
    const searchData = () => {
      const e = new Date();
      updateFormData({
        ...formData,
        ['searchValue']:
          e.getFullYear() +
          '-' +
          ('0' + (e.getMonth() + 1)).slice(-2) +
          '-' +
          ('0' + e.getDate()).slice(-2),
      });
    };
    searchData();
  }, []);
  const SearchhandleChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value.trim(),
    });
    dispatch(removeError());
    setErrorMessage('');
  };
  // handle search & clear search
  const onSubmits = () => {
    dispatch(filterOrders(formData));
    console.log('formdata', formData);
  };

  const prepareReportData = () => {
    const date = new Date();
    let filterTerm = formData.searchKey;
    let filterQuery = formData.searchValue;
    let excelData = {
      generatedBy: loggeddetails?.firstName + ' ' + loggeddetails?.lastName,
      generatedDate:
        moment(date).format('DD\xa0MMM\xa0YYYY').toUpperCase() +
        ' ' +
        date.getHours() +
        ':' +
        ('0' + date.getMinutes()).slice(-2),
      // searchByValue: filterTerm,
      // searchString: filterQuery.length > 0 ? filterQuery : '',
      searchKey: formData.searchKey === 'filterAll' ? '' : formData.searchKey,
      searchValue:
        formData.searchKey === '' || formData.searchKey === 'filterAll' ? '' : formData.searchValue,
    };
    console.log('excelData', excelData);
    let reportName = `ORDER_REPORT_${moment(new Date()).format('DDMMMYYYY').toUpperCase()}`;
    dispatch(generateExcelReport(excelData, reportName, 'ORDERS'));
  };
  const oncancelclick = (item) => {
    setCancelConfirmModal(true);
    setCancelConfirmModalItem(item);
  };
  const onpaymentclick = (item) => {
    setConfirmModal(true);
    setConfirmModalItem(item);
  };
  const options = [
    { label: 'Delivery Date', value: 'deliveryDate' },
    { label: 'Order Date', value: 'orderDate' },
    { label: 'Order ID', value: 'order_id' },
    { label: 'Order Status', value: 'orderStatus' },
    { label: 'Customer Name', value: 'orderByName' },
    { label: 'Phone Number', value: 'phoneNumber' },
    { label: 'Show All', value: 'filterAll' },
  ];
  return (
    <>
      <main className="w-[inherit] mt-12 p-4 sm:px-8  bg-white">
        <div className="container grid mx-auto">
          <div className="block md:flex justify-between mb-4">
            <form onSubmit={handleSubmit(onSubmits)}>
              <div className="flex">
                <Controller
                  name="message"
                  control={control}
                  rules={registerOptions.message}
                  render={({ field: { onChange } }) => {
                    return (
                      <Select
                        options={options}
                        isClearable={false}
                        placeholder={`${dic.t('Margaux-Filter By') + ' *'}`}
                        onChange={(e) => {
                          FilterhandleChange(e);
                          onChange(e);
                        }}
                        styles={filterStyles}
                        ref={labelRef}
                        className="w-18 md:w-36 flex-shrink-0 z-10 inline-flex items-center text-sm font-medium text-left text-gray-900 bg-gray-100 border border-gray-300 rounded-l-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100"
                      />
                    );
                  }}
                />

                <div className="relative w-60">
                  {searchPlaceholder === true ? (
                    <ReactDatePicker
                      selected={date}
                      value={date}
                      id="searchValue"
                      name="searchValue"
                      dateFormat="yyyy-MM-dd"
                      className="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-r-lg border-l-gray-50 border-l-2 border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                      onChange={(date) => {
                        setDateCurrent(date);
                        searchHandleDatePicker(date);
                      }}
                      placeholderText="YYYY-MM-DD"
                      maxDate={today.setDate(today.getDate() + 180)}
                      autoComplete="off"
                      ref={valueRef}
                    />
                  ) : (
                    <input
                      type="text"
                      className="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-r-lg border-l-gray-50 border-l-2 border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                      placeholder={dic.t('Margaux-Search')}
                      name="searchValue"
                      required
                      onChange={SearchhandleChange}
                      ref={valueRef}
                      maxLength={20}
                      autoComplete="off"
                    />
                  )}
                  <button
                    type="submit"
                    disabled={formData.searchKey === 'filterAll'}
                    className="absolute top-0 right-0 p-2.5 text-sm font-medium text-white bg-gray-700 rounded-r-lg border border-gray-700 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300"
                  >
                    <AiOutlineSearch size="20" />
                  </button>
                </div>
              </div>
            </form>

            {/* <div className="hidden md:block ">
              <button type="submit" className="" onClick={handleClear}>
                {isClicked ? <AiFillCloseCircle size="20" /> : ''}
              </button>
            </div> */}
            <div className="mt-2 md:mt-0">
              <button
                type="submit"
                className="px-5 py-3 text-xs font-medium text-white bg-gray-600 rounded hover:bg-gray-800 focus:ring-2 focus:outline-none focus:ring-gray-300"
                onClick={prepareReportData}
              >
                {dic.t('Margaux-Download Report')}
              </button>
            </div>
          </div>

          {loading || filterloading ? (
            <>
              <TableLoader />
            </>
          ) : (
            <div className="w-full overflow-hidden rounded-lg shadow-md border-2 border-gray-300">
              <div className="w-full overflow-x-auto">
                <table className="w-full whitespace-no-wrap">
                  <thead className="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b bg-gray-50">
                    <tr>
                      <td className="px-4 py-3 text-center tracking-widest">
                        {dic.t('Margaux-Order ID')}
                      </td>
                      {/* <td className="px-4 py-3 text-center">{dic.t('Margaux-Customer Name')}</td> */}
                      <td className="px-4 py-3 text-center tracking-widest">
                        {dic.t('Margaux-Order Date')}
                      </td>
                      <td className="px-4 py-3 text-center tracking-widest">
                        {dic.t('Margaux-Delivery Date')}
                      </td>
                      {/* <td className="px-4 py-3 text-center">Total Price</td> */}
                      <td className="px-4 py-3 text-center tracking-widest">
                        {dic.t('Margaux-Status')}
                      </td>
                      <td className="px-4 py-3 text-center tracking-widest">
                        {dic.t('Margaux-Payment')}
                      </td>
                      <td className="px-4 py-3 text-center tracking-widest">Cancel</td>
                      <td className="px-4 py-3 text-center tracking-widest">
                        {dic.t('Margaux-View More')}
                      </td>
                    </tr>
                  </thead>

                  <tbody className="bg-white divide-y text-gray-700">
                    {currentItems.length > 0 && errorMessage === '' ? (
                      currentItems.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td className="px-4 py-3 text-center">
                              <span className="text-sm font-semibold">{item.orderId}</span>
                            </td>
                            {/* <td className="px-4 py-3 text-center">
                              <p className="text-sm font-semibold">{item.customerName}</p>
                            </td> */}
                            <td className="px-4 py-3 text-center">
                              <span className="text-sm font-semibold">
                                {moment(item.orderDate).format('YYYY-MM-DD')}
                              </span>
                            </td>
                            <td className="px-4 py-3 text-center">
                              <span className="text-sm font-semibold">
                                {moment(item.dpDate).format('YYYY-MM-DD')}
                              </span>
                            </td>
                            {/* <td className="px-4 py-3 text-center">
                            <span className="text-sm font-semibold">AED {item.totalPrice}</span>
                          </td> */}
                            <td className="px-4 py-3 text-center group">
                              <span className="font-semibold inline-flex py-1 px-1 uppercase rounded text-stone-600">
                                {item.orderStatus === 'PLACED' && (
                                  <>
                                    <BsBasketFill size="20" className="text-gray-500" />
                                    <span className='tooltip-text bg-gray-400 text-white p-2 -mt-10 -ml-6 rounded hidden group-hover:block absolute text-center text-sm z-50"'>
                                      PLACED
                                    </span>
                                  </>
                                )}
                                {item.orderStatus === 'COOKING' && (
                                  <>
                                    <GiCampCookingPot size="20" className=" text-amber-500" />
                                    <span className='tooltip-text bg-gray-400 text-white p-2 -mt-10 -ml-6 rounded hidden group-hover:block absolute text-center text-sm z-50"'>
                                      COOKING
                                    </span>
                                  </>
                                )}
                                {item.orderStatus === 'DISPATCH' && (
                                  <>
                                    <MdOutlineDeliveryDining size="20" className=" text-rose-500" />
                                    <span className='tooltip-text bg-gray-400 text-white p-2 -mt-10 -ml-6 rounded hidden group-hover:block absolute text-center text-sm z-50"'>
                                      DISPATCH
                                    </span>
                                  </>
                                )}
                                {item.orderStatus === 'DELIVERED' && (
                                  <>
                                    <MdOutlineDoneOutline size="20" className=" text-green-600" />
                                    <span className='tooltip-text bg-gray-400 text-white p-2 -mt-10 -ml-6 rounded hidden group-hover:block absolute text-center text-sm z-50"'>
                                      DELIVERED
                                    </span>
                                  </>
                                )}
                                {item.orderStatus === 'CANCELLED' && (
                                  <>
                                    <IoCloseCircle size="20" className=" text-red-600" />
                                    <span className='tooltip-text bg-gray-400 text-white p-2 -mt-10 -ml-6 rounded hidden group-hover:block absolute text-center text-sm z-50"'>
                                      CANCELLED
                                    </span>
                                  </>
                                )}
                              </span>
                            </td>
                            <td className="px-4 py-3 text-center">
                              <span className="font-semibold inline-flex py-1 px-1 uppercase rounded text-stone-600">
                                {item.paymentStatus === 'PAID' && (
                                  <MdMobileFriendly
                                    size="20"
                                    className="hover:cursor-pointer text-green-500"
                                  />
                                )}
                                {item.paymentStatus === 'UNPAID' && (
                                  <MdPhonelinkErase
                                    size="20"
                                    className="hover:cursor-pointer text-gray-500"
                                    onClick={
                                      item.orderStatus === 'DELIVERED' ||
                                      item.orderStatus === 'CANCELLED'
                                        ? null
                                        : () => {
                                            onpaymentclick(item);
                                          }
                                    }
                                  />
                                )}
                              </span>
                            </td>
                            <td className="px-4 py-3 text-center">
                              <span className="font-semibold inline-flex py-1 px-1 uppercase rounded text-stone-600">
                                <IoCloseCircle
                                  size="20"
                                  className={`text-gray-500 ${
                                    item.orderStatus === 'DELIVERED' ||
                                    item.orderStatus === 'CANCELLED' ? (
                                      <></>
                                    ) : (
                                      'hover:cursor-pointer'
                                    )
                                  }`}
                                  onClick={
                                    item.orderStatus === 'DELIVERED' ||
                                    item.orderStatus === 'CANCELLED'
                                      ? null
                                      : () => {
                                          oncancelclick(item);
                                        }
                                  }
                                />
                              </span>
                            </td>
                            <td className="px-4 py-3 text-center">
                              <button
                                className="text-sm font-semibold px-6 outline-none focus:outline-none ease-linear transition-all duration-150"
                                type="button"
                                onClick={() => {
                                  setShowModal(true);
                                  setSelectData(item);
                                }}
                              >
                                <AiOutlineEye size="18" />
                              </button>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={5}>
                          <div className="flex items-center justify-center text-2xl py-3">
                            <p className="text-lg text-gray-900 text-center">
                              {dic.t('Margaux-No Order History')}
                            </p>
                            <p className="text-lg text-gray-900 text-center px-4">
                              <TbShoppingCartX />
                            </p>
                          </div>
                        </td>
                      </tr>
                    )}
                    {showModal && <OrderModal closeModal={closeModal} data={selectData} />}
                    {confirmModal && (
                      <ConfirmationModal closeModal={closeConfirmModal} item={confirmModalItem} />
                    )}
                    {cancelConfirmModal && (
                      <CancelConfirmModal
                        closeModal={closeCancelConfirmModal}
                        item={cancelconfirmModalItem}
                      />
                    )}
                  </tbody>
                </table>
              </div>
              <div className="px-4 py-3 border-t bg-gray-50 text-gray-500">
                <div className="flex flex-col justify-end text-xs sm:flex-row text-gray-600">
                  {/* <span className="flex items-center tracking-wide">
                  {flag && <span>Total: {flag.length}</span>}
                </span> */}
                  <nav aria-label="Table navigation">
                    {items && items.length > itemsPerPage && errorMessage === '' && (
                      <ReactPaginate
                        containerClassName={'inline-flex items-center'}
                        pageClassName={'page-item'}
                        pageLinkClassName={
                          'align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-3 py-1 rounded-md text-xs text-gray-600  focus:outline-none border border-transparent active:text-white active:bg-gray-100 hover:bg-gray-100 focus:bg-gray-600 focus:text-white'
                        }
                        breakLabel="..."
                        nextLabel={'Next'}
                        previousClassName={
                          'align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-3 py-1 rounded-md text-xs text-gray-600  focus:outline-none border border-transparent active:text-white active:bg-gray-100 hover:bg-gray-100 focus:bg-gray-600 focus:text-white'
                        }
                        nextClassName={
                          'align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-3 py-1 rounded-md text-xs text-gray-600  focus:outline-none border border-transparent active:text-white active:bg-gray-100 hover:bg-gray-100 focus:bg-gray-600 focus:text-white'
                        }
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        pageCount={pageCount}
                        previousLabel={'Previous'}
                        renderOnZeroPageCount={null}
                        activeClassName={
                          'text-gray-700 bg-white border border-gray-400 rounded-md hover:bg-gray-700 hover:text-white'
                        }
                      />
                    )}
                  </nav>
                </div>
              </div>
            </div>
          )}
        </div>
      </main>
    </>
  );
};
export default Main;
