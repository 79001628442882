import axios from 'axios';
import { MicroRoutes } from '../constants';
import * as session from '../helpers/sessionHandler';

export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';
export const SET_LOGIN_LOADING = 'SET_LOGIN_LOADING';
export const CLEAR_LOGIN_STATE = 'CLEAR_LOGIN_STATE';
export const REVERT_LOGIN_STATE = 'REVERT_LOGIN_STATE';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const onUserLogin = (data) => async (dispatch) => {
  dispatch({ type: SET_LOGIN_LOADING, payload: true });
  localStorage.setItem('islogin', false);
  await axios
    .post(MicroRoutes.login, data)
    .then((response) => {
      if (response?.data?.errorStatus) {
        dispatch({
          type: LOGIN_USER_FAILURE,
          payload: response.data.message,
        });
      } else {
        if (response.data.token !== undefined) {
          session.GuestSessionRemove();
          localStorage.setItem('consent', response.data.consent);
          localStorage.setItem('tokenId', response.data.token);
          localStorage.setItem('LoggedUserDetails', JSON.stringify(response.data));
          localStorage.setItem('islogin', true);
          dispatch({
            type: LOGIN_USER_SUCCESS,
            payload: response?.status,
          });
        } else {
          dispatch({
            type: LOGIN_USER_FAILURE,
            payload: 'Server Down...! Please try again later',
          });
        }
      }
    })
    .catch((error) => {
      dispatch({
        type: LOGIN_USER_FAILURE,
        payload: error?.response?.data?.message,
      });
    });
};

export const onClearLoginState = () => (dispatch) => {
  dispatch({
    type: CLEAR_LOGIN_STATE,
    logoutstatus: true,
  });
};

export const RevertLoginStatus = () => (dispatch) => {
  dispatch({
    type: REVERT_LOGIN_STATE,
    logoutstatus: false,
  });
};

export const onErrorClear = () => (dispatch) => {
  dispatch({
    type: CLEAR_ERROR,
    error: '',
  });
};
