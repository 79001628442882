import React, { Fragment } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js/auto';

const SalesGraph = ({ dashBoardDetails }) => {
  console.log('dashBoardDetails', dashBoardDetails);
  let labels = [];
  let data = [];
  const totalordercount = dashBoardDetails?.[0]?.totalOrderCountForTheMonth;
  totalordercount &&
    Object.entries(totalordercount).forEach(([key, value]) => {
      labels.push(key);
      data.push(value);
    });
  const state = {
    labels: labels && labels,
    // [65, 59, 80, 81, 59, 75, 85]
    datasets: [
      {
        label: 'Sales',
        fill: false,
        lineTension: 0.4,
        backgroundColor: 'rgba(255, 255, 255, 1)',
        borderColor: 'rgb(100 116 139)',
        borderWidth: 2,
        data: data && data,
      },
    ],
  };
  return (
    <Fragment>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white border-2 border-gray-200">
        <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full max-w-full flex-grow flex-1">
              {/* <h6 className="uppercase text-slate-500 mb-1 text-xs font-semibold">
                        Overview
                      </h6>
                      <h2 className="text-slate-500 text-xl font-semibold">Sales Value</h2> */}
            </div>
            <Line
              data={state}
              options={{
                title: {
                  display: true,
                  text: 'Average Rainfall per month',
                  fontSize: 20,
                },
                // legend: {
                //   display: true,
                //   position: 'right',
                // },
              }}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SalesGraph;
