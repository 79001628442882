import React, { Fragment, useState } from 'react';
import { FiSettings } from 'react-icons/fi';
import { useForm } from 'react-hook-form';
import { emailPattern, letters, passwordPattern, phonepattern } from '../../helpers/Patterns';
import { onupdateuser } from '../../actions/admin/UpdateUser.action';
import { useSelector, useDispatch } from 'react-redux';
import * as helpers from '../../helpers/config';
import { useToasts } from 'react-toast-notifications';

function Consent() {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [status, SetStatus] = useState(false);
  const loggeddetails = helpers.LoggedUserDetails();
  const consentStatus = localStorage.getItem('consent');
  const marketingConsent = localStorage.getItem('marketingConsent');
  const [getConsent, setConsent] = useState(consentStatus);
  const [getOffer, setOffers] = useState(marketingConsent);
  const UpdateConsent = (status, action) => {
    console.log('status', status, action);
    addToast('Consent Settings Updated...!', {
      appearance: 'success',
    });
    let data = '';
    if (action === 'consent') {
      localStorage.setItem('consent', status);
      setConsent(status);
      data = {
        userId: loggeddetails?.userId,
        userEmailId: loggeddetails?.userEmailId,
        consent: status,
      };
    }
    if (action === 'offers') {
      localStorage.setItem('marketingConsent', status);
      setOffers(status);
      data = {
        userId: loggeddetails?.userId,
        userEmailId: loggeddetails?.userEmailId,
        marketingConsent: status,
      };
    }
    dispatch(onupdateuser(data));
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm();
  const { error, loadingstatus } = useSelector((state) => state.resetpassword);
  const onSubmits = (data) => {
    const SubmitData = {
      email: loggeddetails?.userEmailId,
      password: data.password,
      token: loggeddetails?.token,
    };
    dispatch(onUserResetPassword(SubmitData));
    SetStatus(true);
    reset();
  };
  return (
    <Fragment>
      <div className="bg-black">
        <div className="container px-5 sm:px-20 pt-20">
          <form className="pt-3 px-1 py-20" onSubmit={handleSubmit(onSubmits)}>
            <h3 className="text-lg inline-flex tracking-widest font-medium leading-6 mb-1 text-white">
              <FiSettings />
              &nbsp;Consent Settings
            </h3>
            <div className="grid grid-cols-8 gap-4">
              <div className="col-span-6 pt-3 text-white">
                I consent to Margaux Pastry retaining my personal data for future orders including
                any data about dietary preferences
              </div>
              <div className="col-span-2">
                <label className="relative inline-block h-8 w-16 top-6 md:top-3 lg:top-2">
                  {getConsent === 'true' || getConsent === true ? (
                    <>
                      <input
                        type="checkbox"
                        name="consent"
                        className="peer sr-only"
                        checked
                        onChange={() => UpdateConsent(false, 'consent')}
                      />
                    </>
                  ) : (
                    <>
                      <input
                        type="checkbox"
                        name="consent"
                        className="peer sr-only"
                        onChange={() => UpdateConsent(true, 'consent')}
                      />
                    </>
                  )}
                  <span className="absolute inset-0 cursor-pointer rounded-full bg-gray-200 transition duration-200 before:absolute before:bottom-1 before:left-1 before:h-6 before:w-6 before:rounded-full before:bg-white before:transition before:duration-200 before:shadow-sm peer-checked:bg-gray-400 peer-checked:before:translate-x-8 peer-focus:ring"></span>
                </label>
              </div>
            </div>
            <div className="grid grid-cols-8 gap-4 pt-3">
              <div className="col-span-6 pt-3 text-white">
                I would like to receive tailored offers from Margaux Pastry
              </div>
              <div className="col-span-2">
                <label className="relative inline-block h-8 w-16 top-6 md:top-3 lg:top-2">
                  {getOffer === 'true' || getOffer === true ? (
                    <>
                      <input
                        type="checkbox"
                        name="offers"
                        className="peer sr-only"
                        checked
                        onChange={() => UpdateConsent(false, 'offers')}
                      />
                    </>
                  ) : (
                    <>
                      <input
                        type="checkbox"
                        name="offers"
                        className="peer sr-only"
                        onChange={() => UpdateConsent(true, 'offers')}
                      />
                    </>
                  )}
                  <span className="absolute inset-0 cursor-pointer rounded-full bg-gray-200 transition duration-200 before:absolute before:bottom-1 before:left-1 before:h-6 before:w-6 before:rounded-full before:bg-white before:transition before:duration-200 before:shadow-sm peer-checked:bg-gray-400 peer-checked:before:translate-x-8 peer-focus:ring"></span>
                </label>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
}

export default Consent;
