import React, { Fragment, useEffect } from 'react';
import $ from 'jquery';
import './style.css';

const CookiePolicy = (props) => {
  useEffect(() => {
    $('.scrollup').click(function (e) {
      e.preventDefault();
      var target = $($(this).attr('href'));
      if (target.length) {
        var scrollTo = parseInt(target.offset().top) - parseInt(80);
        $('body, html').animate({ scrollTop: scrollTo + 'px' }, 800);
      }
    });
  });
  let policytext = props?.fields?.['Cookie Policy Description']?.value;
  return (
    <Fragment>
      <div className="bg-white text-[#0e2538] text-xs fonts py-16 sm:px-4">
        <div
          dangerouslySetInnerHTML={{
            __html: policytext,
          }}
        ></div>
      </div>
    </Fragment>
  );
};

export default CookiePolicy;
