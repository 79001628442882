import React, { Fragment, useState } from 'react';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { colourStyles } from '../../../helpers/Patterns';
import * as helpers from '../../../helpers/config';
import { useToasts } from 'react-toast-notifications';
import { setCenterCart } from '../../../actions/admin/CallCenter.action';
import { IoMdCloseCircle } from 'react-icons/io';
import dic from 'i18next';
import { HiBadgeCheck } from 'react-icons/hi';

const CakeForm = ({
  FlavourOptions,
  RegularList,
  IndiviualList,
  SignatureList,
  MacroonsList,
  Types,
  signatureName,
  macaroonName,
}) => {
  const Dispatch = useDispatch();
  const { addToast } = useToasts();
  let servingsOptions = [];
  const categories = [];
  Types &&
    Types.map((item) => {
      let Category = item?.fields?.Title?.value;
      categories.push({
        label: Category,
        value: Category,
      });
    });
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
  } = useForm();
  const [perItemPrice, setItemPrice] = useState(0);
  const [addonlist, setaddonlist] = useState([]);
  const [flavourOptions, setFlavourOptions] = useState([]);
  const [servingOption, setServingsOption] = useState([]);
  const [cakeimage, setCakeImage] = useState('');
  const [addonberryprice, setAddonberryprice] = useState(0);
  const [getFlavour, setFlavour] = useState('');
  const [signatureStatus, setsignatureStatus] = useState([]);
  const [macaroonStatus, setmacaroonStatus] = useState([]);
  const [getServiceCount, setServiceCount] = useState([]);
  const [cakeType, setCakeType] = useState();
  const [chefSelectionStatus, setchefSelectionStatus] = useState(false);
  const [showCount, setCount] = useState([]);
  const [showtempDetail, settempDetail] = useState([]);
  const CheckCountValidate = (e) => {
    const getCount = document.getElementsByClassName('checkCount');
    let tempCounts = [];
    let tempDetail = [];
    getCount.forEach((element) => {
      tempCounts.push(parseInt(element.value) || 0);
      tempDetail.push(element.id);
      document.getElementById(element.id).value = `${parseInt(element.value) || 0}`;
    });
    const currentTotal = tempCounts.reduce((partialSum, a) => partialSum + a, 0);
    if (getServiceCount < currentTotal) {
    } else {
      settempDetail(tempDetail);
      setCount(tempCounts);
    }
  };

  const onSubmits = (data) => {
    let tempCounts = [];
    var counterDetail = [];
    if (cakeType === 'SIGNATUREBOX' || cakeType === 'MACAROONBOX') {
      if (chefSelectionStatus === false) {
        const getCount = document.getElementsByClassName('checkCount');
        getCount.forEach((element) => {
          tempCounts.push(parseInt(element.value) || 0);
          if (element.value === '0' || element.value === 0 || element.value === '') {
          } else {
            counterDetail.push(element.id + ' x ' + element.value);
          }
        });
        const currentTotal = tempCounts.reduce((partialSum, a) => partialSum + a, 0);
        if (parseInt(getServiceCount) === parseInt(currentTotal)) {
        } else {
          addToast('You need to select minimum ' + getServiceCount + ' flavour', {
            appearance: 'info',
          });
          return false;
        }
      } else {
        var counterDetail = ['Chefs Selection'];
      }
    }

    const cakedata = {
      cakeImage: cakeimage,
      totalPrice: Number(perItemPrice) + Number(addonberryprice),
      cakePerItemPrice: Number(perItemPrice) + Number(addonberryprice),
      cakeQuantity: 1,
      cakeFlavour: data.flavour.label,
      cakeServings: data.servings.label,
      cakeMessage: data.message,
      cakeRemarks: data.remarks,
      cakeBerries: Number(addonberryprice),
      signatureBox: cakeType === 'SIGNATUREBOX' ? counterDetail : [],
      cakeType: cakeType,
      macaroonBox: cakeType === 'MACAROONBOX' ? counterDetail : [],
    };

    Dispatch(setCenterCart(cakedata));
    setFlavour('');
    // reset();
  };
  const onCategoryChange = (e) => {
    let flavourlist = [];
    setCount([]);
    settempDetail([]);
    setsignatureStatus([]);
    setmacaroonStatus([]);

    switch (e.label) {
      case 'Petites Patisserie':
        setCakeType('REGULAR');
        setFlavour('');
        IndiviualList.map((item) => {
          let cakename = item?.fields?.['Pastry Name']?.fields?.Title?.value;
          flavourlist.push({
            label: cakename,
            value: {
              services: item?.fields?.Services,
              addons: item?.fields?.Addons,
              cakeImages: item?.fields?.['Pastry Images'],
            },
          });
        });
        setFlavourOptions(flavourlist);
        break;
      case 'Grands Gateaux':
        setFlavour('');
        setCakeType('REGULAR');
        RegularList.map((item) => {
          let cakename = item?.fields?.['Pastry Name']?.fields?.Title?.value;
          flavourlist.push({
            label: cakename,
            value: {
              services: item?.fields?.Services,
              addons: item?.fields?.Addons,
              cakeImages: item?.fields?.['Pastry Images'],
            },
          });
        });
        setFlavourOptions(flavourlist);
        break;
      case 'Signature':
        setFlavour('Signature Box');
        setCakeType('SIGNATUREBOX');
        SignatureList.map((item) => {
          let cakename = item?.fields?.['Pastry Name']?.fields?.Title?.value;
          flavourlist.push({
            label: cakename,
            value: {
              services: item?.fields?.Services,
              addons: item?.fields?.Addons,
              cakeImages: item?.fields?.['Pastry Images'],
            },
          });
        });
        setFlavourOptions(flavourlist);
        break;
      case 'Macaroons':
        setFlavour('');
        setCakeType('MACAROONBOX');
        MacroonsList.map((item) => {
          let cakename = item?.fields?.['Pastry Name']?.fields?.Title?.value;
          flavourlist.push({
            label: cakename,
            value: {
              services: item?.fields?.Services,
              addons: item?.fields?.Addons,
              cakeImages: item?.fields?.['Pastry Images'],
            },
          });
        });
        setFlavourOptions(flavourlist);
        break;
      default:
        break;
    }
  };
  const onFlavourChange = (e) => {
    let services = e.value.services;
    let addons = e.value.addons;
    let image = e.value.cakeImages[0]?.fields?.Image?.value?.src;
    services.map((item) => {
      servingsOptions.push({
        label: item?.fields?.['Service To Persons']?.fields?.Title?.value,
        value: item?.fields?.Price?.value,
      });
    });
    const Selectpack = services[0]?.fields?.['Service To Persons']?.fields?.Code?.value;
    setServiceCount(Selectpack);
    addons.map((item) => {
      setaddonlist([...item?.fields?.Charges]);
    });
    setServingsOption(servingsOptions);
    setCakeImage(`${image}`);
  };
  const onServingChange = (e) => {
    setItemPrice(e.value);
  };
  return (
    <Fragment>
      <div className={'border-2 text-black shadow px-4 pt-4 border-gray-200 sm:h-auto'}>
        <form className="grid grid-cols-12 gap-2" onSubmit={handleSubmit(onSubmits)}>
          <div className="mb-2 col-span-12 ">
            <Controller
              name="type"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => {
                return (
                  <Select
                    options={categories}
                    value={value}
                    placeholder={`${'Category' + ' *'}`}
                    styles={colourStyles}
                    onChange={(e) => {
                      onCategoryChange(e);
                      onChange(e);
                      setValue('flavour', null);
                      setValue('servings', null);
                      setItemPrice(0);
                      setchefSelectionStatus(false);
                      setsignatureStatus([]);
                      setmacaroonStatus([]);
                    }}
                  />
                );
              }}
            />
          </div>
          <div className="mb-2 col-span-12 ">
            <Controller
              name="flavour"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => {
                return (
                  <Select
                    options={flavourOptions}
                    value={value}
                    maxlength="40"
                    placeholder={`${dic.t('Margaux-Flavors') + ' *'}`}
                    styles={colourStyles}
                    onChange={(e) => {
                      onFlavourChange(e);
                      onChange(e);
                      setValue('servings', null);
                      setsignatureStatus([]);
                      setmacaroonStatus([]);
                      setchefSelectionStatus(false);
                      setCount([]);
                      settempDetail([]);
                    }}
                  />
                );
              }}
            />
          </div>
          <div className=" col-span-12 ">
            <Controller
              name="servings"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <Select
                  options={servingOption}
                  value={value}
                  placeholder={`${dic.t('Margaux-Servings') + ' *'}`}
                  styles={colourStyles}
                  onChange={(e) => {
                    onServingChange(e);
                    setchefSelectionStatus(false);
                    setsignatureStatus([]);
                    setmacaroonStatus([]);
                    setCount([]);
                    settempDetail([]);
                    onChange(e);
                    if (e.label === '1 Piece') {
                      setCakeType('');
                    } else if (e.label === '7 Pieces') {
                      if (getValues('flavour').label === 'The WOW Factor') {
                        setCakeType('SIGNATUREBOX');
                      } else {
                        setCakeType('MACAROONBOX');
                      }
                    } else if (e.label === '14 Pieces') {
                      setCakeType('MACAROONBOX');
                    } else if (e.label === '22 Pieces') {
                      setCakeType('MACAROONBOX');
                    }
                  }}
                />
              )}
            />
          </div>
          {/* Signature Items Start */}
          {cakeType === 'SIGNATUREBOX' && perItemPrice !== 0 ? (
            <>
              <div className="text-sm col-span-12  px-1 scrollbar-thumb-gray-700 scrollbar-track-gray-100 scrollbar-thin  gap-2">
                {chefSelectionStatus === '' || chefSelectionStatus === true ? (
                  <p className="px-4 visible">You Have Selected Chef Selection</p>
                ) : (
                  <p className="px-4 text-sm">{`Please select the ${getServiceCount} flavours`}</p>
                )}

                <div className="grid grid-cols-2 mt-2 px-2">
                  <button
                    onClick={() => {
                      setchefSelectionStatus(false);
                      setsignatureStatus([]);
                    }}
                    className="text-white relative mx-1.5 bg-gray-700   tracking-wider  rounded-lg text-sm px-5 sm:px-6 py-2.5 text-center"
                  >
                    Customize
                    {chefSelectionStatus === false ? (
                      <HiBadgeCheck className="absolute mx-1 top-2 right-[2px]" size={20} />
                    ) : (
                      <></>
                    )}
                  </button>
                  <a
                    href="javascript:void(0);"
                    onClick={() => {
                      setchefSelectionStatus(true);
                      setsignatureStatus(['Chefs Selection']);
                    }}
                    className="text-white mx-1.5 relative bg-gray-700   tracking-wider  rounded-lg text-sm px-5 sm:px-6 py-2.5 text-center"
                  >
                    Chefs Selection
                    {chefSelectionStatus === true ? (
                      <HiBadgeCheck className="absolute mx-1 top-2 right-[2px]" size={20} />
                    ) : (
                      <></>
                    )}
                  </a>
                </div>
                {chefSelectionStatus === false ? (
                  <>
                    <div className="py-3 sm:overflow-x-auto  sm:max-h-full scrollbar-thumb-gray-700 scrollbar-track-gray-100 scrollbar-thin  overflow-x-auto h-[15rem]">
                      <div className="parent-select mx-3">
                        <div className="border cursor-pointer  border-gray-500 text-sm py-2 rounded-md  px-5">
                          {showCount.length > 0 &&
                          showCount.reduce((partialSum, a) => partialSum + a, 0) ? (
                            showtempDetail.map((item, key) => {
                              return (
                                <>
                                  {showCount[key] > 0 ? item + ' x ' + showCount[key] + ',' : <></>}
                                </>
                              );
                            })
                          ) : (
                            <>Select Flavors</>
                          )}
                        </div>
                        <div className="bg-gray-200  rounded-md pb-1">
                          {signatureName?.map((item, key) => {
                            let TempValue = item?.fields?.Title?.value.replace(/\'/g, '');
                            if (
                              item?.fields['Pastry Category']?.fields?.Title?.value ===
                                'Signature' &&
                              item?.fields?.Title?.value === TempValue
                            ) {
                              return (
                                <>
                                  <div
                                    key={`signature-${key}`}
                                    className="flex flex-row hover:bg-gray-300 cursor-pointer"
                                  >
                                    <div className="basis-9/12 px-1">
                                      <div className="text-md py-1 ">
                                        <p className="px-4 pt-1">{item?.fields?.Title?.value}</p>
                                      </div>
                                    </div>
                                    <div className="basis-3/12 text-right px-1">
                                      <input
                                        type={'number'}
                                        min={0}
                                        onChange={(e) => CheckCountValidate(e)}
                                        onInput={(e) => {
                                          e.target.value = e.target.value.slice(0, 2);
                                        }}
                                        value={parseInt(showCount[key]) || 0}
                                        id={item?.fields?.Title?.value}
                                        className="form-control checkCount block w-full px-3 py-1 my-1 "
                                      />
                                    </div>
                                  </div>
                                </>
                              );
                            }
                          })}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </>
          ) : (
            <></>
          )}
          {/* Signature Items End */}
          {/* Macaroons Items Start */}
          {cakeType === 'MACAROONBOX' && perItemPrice !== 0 ? (
            <>
              <div className="text-sm col-span-12  px-1 scrollbar-thumb-gray-700 scrollbar-track-gray-100 scrollbar-thin  gap-2">
                {chefSelectionStatus === '' || chefSelectionStatus === true ? (
                  <p className="px-2 visible">You Have Selected Chef Selection</p>
                ) : (
                  <p className="px-2 text-sm">{`Please select the ${getServiceCount} flavours`}:</p>
                )}

                <div className="grid grid-cols-2 mt-4 px-2">
                  <button
                    onClick={() => {
                      setchefSelectionStatus(false);
                      setmacaroonStatus([]);
                    }}
                    className="text-white relative mx-1.5 bg-gray-700   tracking-wider  rounded-lg text-sm px-5 sm:px-6 py-2.5 text-center"
                  >
                    Customize
                    {chefSelectionStatus === false ? (
                      <HiBadgeCheck className="absolute mx-1 top-2 right-[2px]" size={20} />
                    ) : (
                      <></>
                    )}
                  </button>
                  <a
                    href="javascript:void(0);"
                    onClick={() => {
                      setchefSelectionStatus(true);
                      setmacaroonStatus(['Chefs Selection']);
                    }}
                    className="text-white mx-1.5 relative bg-gray-700   tracking-wider  rounded-lg text-sm px-5 sm:px-6 py-2.5 text-center"
                  >
                    Chefs Selection
                    {chefSelectionStatus === true ? (
                      <HiBadgeCheck className="absolute mx-1 top-2 right-[2px]" size={20} />
                    ) : (
                      <></>
                    )}
                  </a>
                </div>
                {chefSelectionStatus === false ? (
                  <>
                    <div className="py-3 sm:overflow-x-auto  sm:max-h-full scrollbar-thumb-gray-700 scrollbar-track-gray-100 scrollbar-thin  overflow-x-auto h-[15rem]">
                      <div className="parent-select mx-3">
                        <div className="border cursor-pointer  border-gray-500 text-sm py-2 rounded-md  px-5">
                          {showCount.length > 0 &&
                          showCount.reduce((partialSum, a) => partialSum + a, 0) ? (
                            showtempDetail.map((item, key) => {
                              return (
                                <>
                                  {showCount[key] > 0 ? item + ' x ' + showCount[key] + ',' : <></>}
                                </>
                              );
                            })
                          ) : (
                            <>Select Flavors</>
                          )}
                        </div>
                        <div className="bg-gray-200  rounded-md pb-1">
                          {macaroonName?.map((item, key) => {
                            let TempValue = item?.fields?.Title?.value.replace(/\'/g, '');
                            if (
                              item?.fields['Pastry Category']?.fields?.Title?.value ===
                                'Macaroons' &&
                              item?.fields?.Title?.value === TempValue
                            ) {
                              return (
                                <>
                                  <div
                                    key={`macroons-${key}`}
                                    className="flex flex-row hover:bg-gray-300 cursor-pointer"
                                  >
                                    <div className="basis-9/12 px-1">
                                      <div className="text-md py-1 ">
                                        <p className="px-4 pt-1">{item?.fields?.Title?.value}</p>
                                      </div>
                                    </div>
                                    <div className="basis-3/12 text-right px-1">
                                      <input
                                        type={'number'}
                                        min={0}
                                        onChange={(e) => CheckCountValidate(e)}
                                        onInput={(e) => {
                                          e.target.value = e.target.value.slice(0, 2);
                                        }}
                                        value={parseInt(showCount[key]) || 0}
                                        id={item?.fields?.Title?.value}
                                        className="form-control checkCount block w-full px-3 py-1 my-1 "
                                      />
                                    </div>
                                  </div>
                                </>
                              );
                            }
                          })}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </>
          ) : (
            <></>
          )}

          {/* Macaroons Items End */}
          <div className="mb-2 col-span-12 ">
            <input
              type="text"
              maxLength="40"
              className="block w-full px-2 py-1 text-sm text-gray-900 tracking-wider bg-transparent rounded-md border border-gray-400 focus:outline-none focus:ring-0 focus:border-gray-600 peer"
              placeholder={dic.t('Margaux-Message To Put On The Pastry')}
              {...register('message')}
              autoComplete="off"
            />
          </div>
          <div className="mb-2 col-span-12 ">
            <input
              type="text"
              maxLength={40}
              className="block w-full px-2 py-1 text-sm text-gray-900 tracking-wider bg-transparent rounded-md border border-gray-400 focus:outline-none focus:ring-0 focus:border-gray-600 peer"
              placeholder={dic.t('Margaux-Remarks')}
              {...register('remarks')}
              autoComplete="off"
            />
          </div>
          {addonlist && addonlist.length > 0 && (
            <div className="mb-2 col-span-12 sm:col-span-12">
              <div className="flex justify-between mb-2">
                <h2>{dic.t('Margaux-Berries')}</h2>
                <strong
                  className="inline-flex items-center hover:cursor-pointer border border-red-500 text-red-500 border-current uppercase p-1.5 rounded-full text-[10px] tracking-widest"
                  onClick={() => {
                    setAddonberryprice(0);
                  }}
                >
                  {dic.t('Margaux-Remove')}
                </strong>
              </div>
              <div className="grid grid-cols-6 gap-2">
                {addonlist.length > 0 &&
                  addonlist.map((item, key) => {
                    let price = item?.fields?.Price?.value;
                    return (
                      <div
                        className="col-span-1"
                        key={key}
                        onClick={() => {
                          setAddonberryprice(price);
                        }}
                      >
                        <label
                          className={`block p-1 text-sm text-center font-medium transition-colors border border-gray-300 rounded-lg shadow-sm cursor-pointer ${
                            addonberryprice === price
                              ? 'border-gray-500 bg-gray-600 text-white'
                              : ''
                          }`}
                        >
                          <span>
                            {dic.t('Margaux-AED')} {price}{' '}
                          </span>
                        </label>
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
          <div className="mb-2 col-span-12 sm:col-span-12">
            <button
              type="submit"
              className="text-white bg-gray-700 col-span-6 hover:bg-gray-500 font-medium rounded-lg text-sm w-full px-2 py-1.5 text-center"
            >
              {dic.t('Margaux-Add')}
            </button>
          </div>
        </form>
      </div>
    </Fragment>
  );
};

export default CakeForm;
