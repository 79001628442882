import React, { useState, useEffect } from 'react';
import * as helpers from '../../helpers/config';
import { BsInfoCircle } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import Decrement from '../CommonIcon/Decrement';
import Increment from '../CommonIcon/Increment';
import dic from 'i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';

function Eclairs({
  EclairsItem,
  cartItems,
  modalUpdateStatus,
  customize_status,
  SorryCartStatusUpdate,
  ViewMoreUpdate,
}) {
  return (
    <>
      {EclairsItem && EclairsItem.length > 0 && (
        <h4 className="text-center pb-4 text-xl md:text-3xl uppercase tracking-wider">
          {dic.t('Margaux-Eclairs')}
        </h4>
      )}

      <div
        className={
          'grid  2xl:grid-cols-3 xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1'
        }
      >
        {EclairsItem && EclairsItem.length > 0 ? (
          EclairsItem.map((items, key) => {
            const exists = cartItems.filter((currentcart) => {
              return currentcart?.ItemDetails?.id === items?.id;
            });
            var counts = 0;
            exists.map((itemcheck) => {
              itemcheck.cartaddedservice.map((serviceitem) => {
                return (counts = counts + serviceitem?.quantity?.value);
              });
            });
            const pricelist = items.fields.Services;
            const checkCustomize =
              items?.fields?.Services[0]?.fields['Service To Persons']?.fields?.Code?.value;
            const findlowestprice = Math.min(...pricelist.map((item) => item.fields.Price.value));
            const description = items?.fields?.Description?.fields?.Description?.value;
            const descriptionplain = description.slice(0, 170) + '...';
            const images = items?.fields['Pastry Images'][0]?.fields?.Image?.value?.src;
            const cutimage = items?.fields['Pastry Images'][1]?.fields?.Image?.value?.src;
            const allimages = items?.fields?.['Pastry Images'];
            const addons = items?.fields?.Addons;
            const cakename = items?.fields['Pastry Name']?.fields?.Title?.value;
            return (
              <div className="flex justify-center md:px-6 py-8 px-0" key={key} id="Eclairs">
                <div className="flex justify-center overflow-hidden">
                  <div className="max-w-full bg-white rounded-lg shadow-md">
                    <img
                      className={`w-screen object-cover h-60 ${
                        allimages.length > 1 ? '' : 'hover:scale-125'
                      } ease-in duration-500  xl:h-64 2xl:h-72 rounded-t-lg`}
                      src={images}
                      alt="product image"
                      onMouseOver={(e) =>
                        (e.currentTarget.src = allimages.length > 1 ? cutimage : images)
                      }
                    />
                    <div className="px-5 pt-4 pb-2 border-t-2 border-gray-500 relative z-[9] bg-white	">
                      <h5 className="sm:text-xl text-lg font-semibold tracking-widest text-gray-900">
                        {cakename}
                      </h5>
                      <p className="text-gray-700 text-[0.68rem] py-2">
                        <div
                          style={{ display: 'inline' }}
                          dangerouslySetInnerHTML={{
                            __html: descriptionplain,
                          }}
                        />
                        <span
                          onClick={() =>
                            ViewMoreUpdate(
                              description,
                              allimages,
                              cakename,
                              items,
                              addons,
                              parseInt(checkCustomize) === 1 ? 'customize' : customize_status,
                              exists,
                              counts,
                              findlowestprice
                            )
                          }
                          className="text-gray-700 font-bold cursor-pointer underline underline-offset-1 hover:text-sky-800"
                        >
                          See More
                        </span>
                      </p>
                      <div className="flex justify-between">
                        <div className="relative font-bold text-gray-900 pr-2 py-2 sm:pr-2 lg:pr-5 sm:py-2.5">
                          {helpers.currency} {findlowestprice}
                        </div>
                        <div className="relative font-bold text-gray-900 py-2 sm:py-2.5">
                          {exists &&
                          exists[0] &&
                          exists[0].cartaddedservice &&
                          exists[0].cartaddedservice.length > 0 ? (
                            <div className="grid grid-cols-3 addtocartbox bg-gray-700 px-1 py-1 rounded-md">
                              <div
                                className="flex relative top-1	cursor-pointer"
                                onClick={SorryCartStatusUpdate}
                              >
                                <Decrement />
                              </div>
                              <div className="flex text-white px-1 justify-center text-lg">
                                {counts}
                              </div>
                              <div
                                className="flex relative top-[0.2em] cursor-pointer"
                                onClick={() => modalUpdateStatus(items, addons)}
                              >
                                <Increment />
                              </div>
                            </div>
                          ) : (
                            <div>
                              <Link
                                onClick={() => modalUpdateStatus(items, addons)}
                                className="text-white font-normal hover:bg-gray-700 bg-gray-600 uppercase rounded-lg text-xs lg:text-sm px-2 py-2 sm:px-2  sm:py-2.5 text-center   "
                              >
                                {dic.t('Margaux-Add To Cart')}
                              </Link>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <></>
        )}
      </div>
    </>
  );
}

export default Eclairs;
